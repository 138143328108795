import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LeftyFormat } from '@frontend2/core';
import { LeftyTooltipDirective } from '../../lefty-tooltip/lefty-tooltip.directive';
import { CpmCellRenderer } from './cpm-cell.helpers';

@Component({
  selector: 'cpm-cell',
  templateUrl: './cpm-cell.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyTooltipDirective],
})
export class CpmCellComponent extends CpmCellRenderer {
  value = '-';

  override render(newValue: number): void {
    this.value = LeftyFormat.impliedCPM(newValue, { showLowCpm: true });
  }

  cpeTooltip(): string {
    return LeftyFormat.impliedCPM(this.estimatedCpm, { showLowCpm: false });
  }

  hasTooltip(): boolean {
    return this.estimatedCpm > 0 && this.estimatedCpm < 0.01;
  }
}
