import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  model,
} from '@angular/core';

import { NgIf } from '@angular/common';
import { isEmptyArray, isNil } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  AgesReport,
  GeographyReports,
} from '@frontend2/proto/common/proto/user_pb';
import { GenerikAudienceData } from '@frontend2/proto/librarian/proto/audience_pb';
import { LeftyAgeRangeComponent } from '../lefty-age-range/lefty-age-range.component';
import { LeftyCardComponent } from '../lefty-card/lefty-card.component';
import { LeftySpinnerComponent } from '../loading.component';
import { NetworkFormSelectComponent } from '../selector/network-form-select/network-form-select.component';
import { LeftyComponent } from '../utils';
import { ReportAudienceAgeComponent } from './report-audience-age/report-audience-age.route.component';
import { ReportAudienceCountryComponent } from './report-audience-country/report-audience-country.route.component';

@Component({
  selector: 'report-audience',
  templateUrl: 'report-audience.route.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['report-audience.route.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    LeftyCardComponent,
    LeftySpinnerComponent,
    ReportAudienceCountryComponent,
    NetworkFormSelectComponent,
    LeftyAgeRangeComponent,
    ReportAudienceAgeComponent,
  ],
})
export class ReportAudienceComponent extends LeftyComponent {
  readonly report = input(new GenerikAudienceData());
  readonly isLoading = input(false);
  readonly selectedNetwork = model(Network.INSTA);
  readonly networks = input<Network[]>([]);
  readonly isMobileLayout = input(false);

  readonly geographyReports = computed(
    () => this.report().countries ?? new GeographyReports(),
  );

  readonly reportMale = computed(
    () => this.report().agesReportMale ?? new AgesReport(),
  );

  readonly reportFemale = computed(
    () => this.report().agesReportFemale ?? new AgesReport(),
  );

  readonly isEmptyState = computed(() => {
    return (
      !this.isLoading() &&
      (this.report().network === Network.NETWORK_UNKNOWN ||
        isNil(this.geographyReports().report) ||
        isEmptyArray(this.geographyReports().report) ||
        isNil(this.reportMale().ageBucket) ||
        isEmptyArray(this.reportMale().ageBucket) ||
        isNil(this.reportFemale().ageBucket) ||
        isEmptyArray(this.reportFemale().ageBucket))
    );
  });

  selectNetwork(network: Network): void {
    if (this.selectedNetwork() === network) {
      return;
    }
    this.selectedNetwork.update(() => network);
  }
}
