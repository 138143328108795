import { isNil } from '@frontend2/core';
import { RendersValue } from '../../dynamic-component.component';
import { LeftyComponent } from '../../utils';

export abstract class CpmCellRenderer
  extends LeftyComponent
  implements RendersValue<number>
{
  estimatedCpm = 0;
  isGhost = false;

  setValue(newValue?: number): void {
    this.estimatedCpm = newValue ?? 0;
    this.isGhost = isNil(newValue);

    this.render(this.estimatedCpm);
    this.changeDetection.markForCheck();
  }

  abstract render(newValue: number): void;
}
