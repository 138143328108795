import { Component } from '@angular/core';
import { posthogTrackPageviews } from '@frontend2/core';
import { injectRouter } from '@frontend2/ui';

@Component({
  selector: 'hitchcock-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor() {
    posthogTrackPageviews(injectRouter());
  }
}
