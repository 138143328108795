import { Injectable, inject } from '@angular/core';
import { CastingChangeStatusEvent, CastingNoteEvent } from './events.models';

import { LeftyEvents, LeftyEventsBus } from '@frontend2/ui';

type HitchcockEvents = LeftyEvents & {
  update_influencer_note: CastingNoteEvent;
  add_influencer_note: CastingNoteEvent;
  delete_influencer_note: CastingNoteEvent;
  update_influencer_status: CastingChangeStatusEvent;
};

@Injectable({ providedIn: 'root' })
export class HitchcockEventsBus extends LeftyEventsBus<HitchcockEvents> {}

export function injectHitchcockEventsBus(): HitchcockEventsBus {
  return inject(HitchcockEventsBus);
}
