import {
  CurrencyEnum,
  Network,
  PostType,
} from '@frontend2/proto/common/proto/common_pb';
import {
  GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer,
  GetForecastingStatsAndPerformancesResp_ForecastingStats,
  InfluencerExpectedPosts,
} from '@frontend2/proto/librarian/proto/casting_pb';
import { LeftyFormat } from '../format';
import { getCpmPostTypesOfNetwork } from '../proto-helpers/common/common_pb.helpers';

export function createStatsKeyValueData(
  args?: Partial<StatsKeyValueData>,
): StatsKeyValueData {
  return {
    key: args?.key ?? '-',
    isPrimary: args?.isPrimary ?? false,
    data: args?.data ?? '-',
    helpMessage: args?.helpMessage ?? '',
  };
}

export interface StatsKeyValueData {
  readonly key: string;
  readonly isPrimary: boolean;
  readonly data: string;
  readonly helpMessage: string;
}

export function generateMetricsStats(
  stats: GetForecastingStatsAndPerformancesResp_ForecastingStats,
  currency: CurrencyEnum,
): StatsKeyValueData[] {
  return [
    createStatsKeyValueData({
      key: $localize`Influencers`,
      data: LeftyFormat.profiles(stats?.influencersCount),
    }),
    createStatsKeyValueData({
      key: $localize`Expected Posts`,
      data: LeftyFormat.posts(stats?.expectedPostsCount),
    }),
    createStatsKeyValueData({
      key: $localize`Est. Impressions`,
      data: LeftyFormat.impressions(stats?.impressions ?? BigInt(0), {
        showZero: false,
      }),
    }),
    createStatsKeyValueData({
      key: $localize`Est. Engagements`,
      data: LeftyFormat.engagement(stats?.engagements ?? BigInt(0), {
        showZero: false,
      }),
    }),
    createStatsKeyValueData({
      key: $localize`Est. Reach`,
      data: LeftyFormat.reach(stats?.reach ?? BigInt(0), { showZero: false }),
    }),
    createStatsKeyValueData({
      key: $localize`Est. EMV`,
      isPrimary: true,
      data: LeftyFormat.emv(stats?.emv ?? BigInt(0), {
        showZero: false,
        currency,
      }),
    }),
    createStatsKeyValueData({
      key: $localize`Est. Costs`,
      data: LeftyFormat.cost(stats.costs, {
        currency,
      }),
    }),
    createStatsKeyValueData({
      key: $localize`Est. ROI`,
      data: LeftyFormat.roi(stats.roi ?? BigInt(0)),
      helpMessage: $localize`Return on investment estimated for this forecast. ROI = (EMV / Costs) - 1`,
    }),
    createStatsKeyValueData({
      key: $localize`Est. CPM`,
      data: LeftyFormat.impliedCPM(stats.calculatedCpm, {
        showLowCpm: true,
        currency,
      }),
      helpMessage: $localize`Cost per mille estimated for this forecast. CPM = (Costs / Impressions) x 1000`,
    }),
    createStatsKeyValueData({
      key: $localize`Est. CPE`,
      data: LeftyFormat.cpe(stats.cpe, {
        showLowCpe: true,
        currency,
      }),
      helpMessage: $localize`Cost per engagement estimated for this forecast. CPE = Costs / Engagements`,
    }),
  ];
}

export function getExpectedPostTypeByNetwork(
  data: GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer,
): Map<Network, InfluencerExpectedPosts[]> {
  const postValues = new Map<PostType, { value: number; cost: number }>(
    data?.expectedPostsByPostType
      .filter((post) => post.postTypesValues.case === 'postType')
      .map((post) => [
        post.postTypesValues.value as PostType,
        {
          value: post.value,
          cost: post.cost ?? 0,
        },
      ]),
  );

  return new Map<Network, InfluencerExpectedPosts[]>(
    data.expectedPostsByNetwork
      .filter((post) => post.postTypesValues.case === 'network')
      .flatMap((x) => x.postTypesValues.value as Network)
      .map((network) => [
        network,
        getCpmPostTypesOfNetwork(network).map((postType) => {
          const postValueData = postValues.get(postType);
          return new InfluencerExpectedPosts({
            postTypesValues: { case: 'postType', value: postType },
            value: postValueData?.value ?? 0,
            cost: postValueData?.cost ?? 0,
          });
        }),
      ]),
  );
}
