import { Network } from '@frontend2/proto/common/proto/common_pb';
import { RendersValue } from '../../dynamic-component.component';
import { LeftyComponent } from '../../utils';

export abstract class NetworkCellRenderer
  extends LeftyComponent
  implements RendersValue<Network>
{
  network = Network.NETWORK_UNKNOWN;
  isGhost = false;

  setValue(newValue?: Network): void {
    this.network = newValue ?? Network.NETWORK_UNKNOWN;
    this.isGhost = this.network === Network.NETWORK_UNKNOWN;

    this.render(this.network);
    this.changeDetection.markForCheck();
  }

  abstract render(newValue: Network): void;
}
