import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';

import { NgIf } from '@angular/common';
import { isNotEmptyArray, LeftyFormat } from '@frontend2/core';
import {
  GeographyReport,
  GeographyReports,
} from '@frontend2/proto/common/proto/user_pb';
import { CountriesCache } from '../../cache/countries.service';
import { LeftyCardComponent } from '../../lefty-card/lefty-card.component';
import { LeftyComponent } from '../../utils';

@Component({
  selector: 'report-audience-country',
  templateUrl: 'report-audience-country.route.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['report-audience-country.route.component.scss'],
  standalone: true,
  imports: [NgIf, LeftyCardComponent],
})
export class ReportAudienceCountryComponent extends LeftyComponent {
  countries: GeographyReport[] = [];
  MAX_PERCENTAGE = 0;
  LIMIT_COUNTRY = 6;
  readonly countriesCache = inject(CountriesCache);

  constructor() {
    super();
    this.countriesCache.load();
    this.countriesCache.dataChange$.subscribe(() => this.updateCountries());
  }

  private _report: GeographyReports = new GeographyReports();
  get report(): GeographyReports {
    return this._report;
  }

  @Input()
  set report(value: GeographyReports) {
    this._report = value;
    this.updateCountries();
  }

  private updateCountries(): void {
    const reports = [...this._report.report];
    reports.sort(this.countrySorter);

    this.countries = reports.slice(0, this.LIMIT_COUNTRY);
    this.MAX_PERCENTAGE = isNotEmptyArray(this.countries)
      ? Math.max(...this.countries.map((c) => c.percentage))
      : 0;
  }

  private countrySorter(a: GeographyReport, b: GeographyReport): number {
    return b.percentage - a.percentage;
  }

  flagClass(code: string): string {
    return `fi fi-${code.toLowerCase()}`;
  }

  countryName(code: string): string {
    return this.countriesCache.getCountryName(code);
  }

  formatValue(val: number): string {
    return LeftyFormat.percent(val, { maximumFractionDigits: 0 });
  }

  barWidth(country: GeographyReport): number {
    return (country.percentage / this.MAX_PERCENTAGE) * 100;
  }
}
