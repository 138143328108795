<lefty-card [withPadding]="true">
  <div>
    <h4 i18n>Country</h4>
  </div>

  <div class="country">
    @for (country of countries; track $index) {
      <div class="info">
        <div [class]="flagClass(country.name)"></div>
        <div class="name body-1">{{ countryName(country.name) }}</div>
        <div class="value body-1">{{ formatValue(country.percentage) }}</div>
      </div>
      <div class="bar-container">
        <div
          class="bar"
          [style.width.%]="barWidth(country)"
        ></div>
      </div>
    }
  </div>
</lefty-card>
