// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/casting.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { BoolValue, DoubleValue, FloatValue, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { BasicPagination, Network, PostType, SortDirection } from "../../common/proto/common_pb";
import { CastingInfluencerSort, CollaboratorCastingInfluencersFilter, FlexibleCreatorSelector, InfluencerCastingStatus, InfluencersCastingFilter } from "./common_pb";
import { NightwatchCtx } from "../../common/proto/nightwatch_pb";
import { CreatorCardSnippet, CreatorRegisteredDetails, GenerikInfoCard } from "./creators_pb";
import { CampaignKeyword, CampaignTrackingSettingsPb, FavoritesLabel } from "../../common/proto/campaign_pb";
import { EntityFieldValue } from "./entity_fields_pb";

/**
 * @generated from enum librarian.proto.CastingInfluencerCollaboratorSort
 */
export enum CastingInfluencerCollaboratorSort {
  /**
   * @generated from enum value: DATE_ADDED_INFLUENCER_CASTING = 0;
   */
  DATE_ADDED_INFLUENCER_CASTING = 0,

  /**
   * @generated from enum value: NAME_INFLUENCER_CASTING = 1;
   */
  NAME_INFLUENCER_CASTING = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(CastingInfluencerCollaboratorSort)
proto3.util.setEnumType(CastingInfluencerCollaboratorSort, "librarian.proto.CastingInfluencerCollaboratorSort", [
  { no: 0, name: "DATE_ADDED_INFLUENCER_CASTING" },
  { no: 1, name: "NAME_INFLUENCER_CASTING" },
]);

/**
 * @generated from enum librarian.proto.NoteCastingAuthorType
 */
export enum NoteCastingAuthorType {
  /**
   * @generated from enum value: NOTE_CASTING_AUTHOR_TYPE_UNKNOWN = 0;
   */
  NOTE_CASTING_AUTHOR_TYPE_UNKNOWN = 0,

  /**
   * @generated from enum value: COLLABORATOR = 1;
   */
  COLLABORATOR = 1,

  /**
   * @generated from enum value: USER = 2;
   */
  USER = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(NoteCastingAuthorType)
proto3.util.setEnumType(NoteCastingAuthorType, "librarian.proto.NoteCastingAuthorType", [
  { no: 0, name: "NOTE_CASTING_AUTHOR_TYPE_UNKNOWN" },
  { no: 1, name: "COLLABORATOR" },
  { no: 2, name: "USER" },
]);

/**
 * @generated from enum librarian.proto.InfluencerCastingCostType
 */
export enum InfluencerCastingCostType {
  /**
   * @generated from enum value: INFLUENCER_CASTING_COST_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: INFLUENCER_CASTING_COST_TYPE_FIXED_COST = 1;
   */
  FIXED_COST = 1,

  /**
   * @generated from enum value: INFLUENCER_CASTING_COST_TYPE_COST_PER_POST = 2;
   */
  COST_PER_POST = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(InfluencerCastingCostType)
proto3.util.setEnumType(InfluencerCastingCostType, "librarian.proto.InfluencerCastingCostType", [
  { no: 0, name: "INFLUENCER_CASTING_COST_TYPE_UNKNOWN" },
  { no: 1, name: "INFLUENCER_CASTING_COST_TYPE_FIXED_COST" },
  { no: 2, name: "INFLUENCER_CASTING_COST_TYPE_COST_PER_POST" },
]);

/**
 * @generated from enum librarian.proto.CastingAudienceMetricEnum
 */
export enum CastingAudienceMetricEnum {
  /**
   * @generated from enum value: CASTING_AUDIENCE_METRIC_UNKNOWN = 0;
   */
  CASTING_AUDIENCE_METRIC_UNKNOWN = 0,

  /**
   * @generated from enum value: CASTING_AUDIENCE_METRIC_GENDER = 1;
   */
  CASTING_AUDIENCE_METRIC_GENDER = 1,

  /**
   * @generated from enum value: CASTING_AUDIENCE_METRIC_GEO = 2;
   */
  CASTING_AUDIENCE_METRIC_GEO = 2,

  /**
   * @generated from enum value: CASTING_AUDIENCE_METRIC_AGE = 3;
   */
  CASTING_AUDIENCE_METRIC_AGE = 3,

  /**
   * @generated from enum value: CASTING_AUDIENCE_METRIC_SCORE = 4;
   */
  CASTING_AUDIENCE_METRIC_SCORE = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(CastingAudienceMetricEnum)
proto3.util.setEnumType(CastingAudienceMetricEnum, "librarian.proto.CastingAudienceMetricEnum", [
  { no: 0, name: "CASTING_AUDIENCE_METRIC_UNKNOWN" },
  { no: 1, name: "CASTING_AUDIENCE_METRIC_GENDER" },
  { no: 2, name: "CASTING_AUDIENCE_METRIC_GEO" },
  { no: 3, name: "CASTING_AUDIENCE_METRIC_AGE" },
  { no: 4, name: "CASTING_AUDIENCE_METRIC_SCORE" },
]);

/**
 * @generated from enum librarian.proto.CastingMetricType
 */
export enum CastingMetricType {
  /**
   * @generated from enum value: CASTING_METRIC_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: CASTING_METRIC_TYPE_AUDIENCE = 1;
   */
  AUDIENCE = 1,

  /**
   * @generated from enum value: CASTING_METRIC_TYPE_STATISTICS = 2;
   */
  STATISTICS = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(CastingMetricType)
proto3.util.setEnumType(CastingMetricType, "librarian.proto.CastingMetricType", [
  { no: 0, name: "CASTING_METRIC_TYPE_UNKNOWN" },
  { no: 1, name: "CASTING_METRIC_TYPE_AUDIENCE" },
  { no: 2, name: "CASTING_METRIC_TYPE_STATISTICS" },
]);

/**
 * @generated from enum librarian.proto.CastingMetricEnum
 */
export enum CastingMetricEnum {
  /**
   * @generated from enum value: CASTING_METRIC_AUDIENCE_UNKNOWN = 0;
   */
  CASTING_METRIC_AUDIENCE_UNKNOWN = 0,

  /**
   * @generated from enum value: CASTING_METRIC_AUDIENCE_GENDER = 1;
   */
  CASTING_METRIC_AUDIENCE_GENDER = 1,

  /**
   * @generated from enum value: CASTING_METRIC_AUDIENCE_GEO = 2;
   */
  CASTING_METRIC_AUDIENCE_GEO = 2,

  /**
   * @generated from enum value: CASTING_METRIC_AUDIENCE_AGE = 3;
   */
  CASTING_METRIC_AUDIENCE_AGE = 3,

  /**
   * @generated from enum value: CASTING_METRIC_AUDIENCE_SCORE = 4;
   */
  CASTING_METRIC_AUDIENCE_SCORE = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(CastingMetricEnum)
proto3.util.setEnumType(CastingMetricEnum, "librarian.proto.CastingMetricEnum", [
  { no: 0, name: "CASTING_METRIC_AUDIENCE_UNKNOWN" },
  { no: 1, name: "CASTING_METRIC_AUDIENCE_GENDER" },
  { no: 2, name: "CASTING_METRIC_AUDIENCE_GEO" },
  { no: 3, name: "CASTING_METRIC_AUDIENCE_AGE" },
  { no: 4, name: "CASTING_METRIC_AUDIENCE_SCORE" },
]);

/**
 * @generated from enum librarian.proto.CastingStatisticsMetricEnum
 */
export enum CastingStatisticsMetricEnum {
  /**
   * @generated from enum value: CASTING_STATISTICS_METRIC_UNKNOWN = 0;
   */
  CASTING_STATISTICS_METRIC_UNKNOWN = 0,

  /**
   * @generated from enum value: CASTING_STATISTICS_METRIC_FOLLOWERS = 1;
   */
  CASTING_STATISTICS_METRIC_FOLLOWERS = 1,

  /**
   * @generated from enum value: CASTING_STATISTICS_METRIC_AVERAGE_IMPRESSIONS = 2;
   */
  CASTING_STATISTICS_METRIC_AVERAGE_IMPRESSIONS = 2,

  /**
   * @generated from enum value: CASTING_STATISTICS_METRIC_ENGAGEMENT_RATE = 3;
   */
  CASTING_STATISTICS_METRIC_ENGAGEMENT_RATE = 3,

  /**
   * @generated from enum value: CASTING_STATISTICS_METRIC_AVERAGE_REACH = 4;
   */
  CASTING_STATISTICS_METRIC_AVERAGE_REACH = 4,

  /**
   * @generated from enum value: CASTING_STATISTICS_METRIC_AVERAGE_COVERAGE = 5;
   */
  CASTING_STATISTICS_METRIC_AVERAGE_COVERAGE = 5,

  /**
   * @generated from enum value: CASTING_STATISTICS_METRIC_SATURATION_RATE = 6;
   */
  CASTING_STATISTICS_METRIC_SATURATION_RATE = 6,

  /**
   * @generated from enum value: CASTING_STATISTICS_METRIC_AVERAGE_VIEWS = 7;
   */
  CASTING_STATISTICS_METRIC_AVERAGE_VIEWS = 7,

  /**
   * @generated from enum value: CASTING_STATISTICS_METRIC_AVERAGE_RESHARES = 8;
   */
  CASTING_STATISTICS_METRIC_AVERAGE_RESHARES = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(CastingStatisticsMetricEnum)
proto3.util.setEnumType(CastingStatisticsMetricEnum, "librarian.proto.CastingStatisticsMetricEnum", [
  { no: 0, name: "CASTING_STATISTICS_METRIC_UNKNOWN" },
  { no: 1, name: "CASTING_STATISTICS_METRIC_FOLLOWERS" },
  { no: 2, name: "CASTING_STATISTICS_METRIC_AVERAGE_IMPRESSIONS" },
  { no: 3, name: "CASTING_STATISTICS_METRIC_ENGAGEMENT_RATE" },
  { no: 4, name: "CASTING_STATISTICS_METRIC_AVERAGE_REACH" },
  { no: 5, name: "CASTING_STATISTICS_METRIC_AVERAGE_COVERAGE" },
  { no: 6, name: "CASTING_STATISTICS_METRIC_SATURATION_RATE" },
  { no: 7, name: "CASTING_STATISTICS_METRIC_AVERAGE_VIEWS" },
  { no: 8, name: "CASTING_STATISTICS_METRIC_AVERAGE_RESHARES" },
]);

/**
 * @generated from message librarian.proto.CastingRequest
 */
export class CastingRequest extends Message<CastingRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * It will be filled by backend
   *
   * @generated from field: int64 user_id = 3;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<CastingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CastingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastingRequest {
    return new CastingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastingRequest {
    return new CastingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastingRequest {
    return new CastingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CastingRequest | PlainMessage<CastingRequest> | undefined, b: CastingRequest | PlainMessage<CastingRequest> | undefined): boolean {
    return proto3.util.equals(CastingRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CastingOptionsPb
 */
export class CastingOptionsPb extends Message<CastingOptionsPb> {
  /**
   * @generated from field: int64 casting_options_id = 8;
   */
  castingOptionsId = protoInt64.zero;

  /**
   * @generated from field: repeated common.proto.Network networks = 1;
   */
  networks: Network[] = [];

  /**
   * What metrics to display or not. Absence means true by default.
   *
   * TODO delete when migrated
   *
   * @generated from field: repeated librarian.proto.CastingMetricPb metrics = 2 [deprecated = true];
   * @deprecated
   */
  metrics: CastingMetricPb[] = [];

  /**
   * @generated from field: librarian.proto.CastingAudienceMetricsPb audience = 3;
   */
  audience?: CastingAudienceMetricsPb;

  /**
   * @generated from field: librarian.proto.CastingStatisticsMetricsPb statistics = 4;
   */
  statistics?: CastingStatisticsMetricsPb;

  /**
   * @generated from field: google.protobuf.BoolValue with_forecast = 5;
   */
  withForecast?: boolean;

  /**
   * @generated from field: google.protobuf.BoolValue with_comments = 6;
   */
  withComments?: boolean;

  /**
   * @generated from field: google.protobuf.BoolValue with_posts = 7;
   */
  withPosts?: boolean;

  constructor(data?: PartialMessage<CastingOptionsPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CastingOptionsPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 8, name: "casting_options_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 1, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 2, name: "metrics", kind: "message", T: CastingMetricPb, repeated: true },
    { no: 3, name: "audience", kind: "message", T: CastingAudienceMetricsPb },
    { no: 4, name: "statistics", kind: "message", T: CastingStatisticsMetricsPb },
    { no: 5, name: "with_forecast", kind: "message", T: BoolValue },
    { no: 6, name: "with_comments", kind: "message", T: BoolValue },
    { no: 7, name: "with_posts", kind: "message", T: BoolValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastingOptionsPb {
    return new CastingOptionsPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastingOptionsPb {
    return new CastingOptionsPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastingOptionsPb {
    return new CastingOptionsPb().fromJsonString(jsonString, options);
  }

  static equals(a: CastingOptionsPb | PlainMessage<CastingOptionsPb> | undefined, b: CastingOptionsPb | PlainMessage<CastingOptionsPb> | undefined): boolean {
    return proto3.util.equals(CastingOptionsPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.Casting
 */
export class Casting extends Message<Casting> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 4;
   */
  created?: Timestamp;

  /**
   * @generated from field: bool archived = 5;
   */
  archived = false;

  /**
   * @generated from field: librarian.proto.Casting.CastingStats casting_stats = 6;
   */
  castingStats?: Casting_CastingStats;

  /**
   * What metrics to display or not. Absence means true by default.
   *
   * TODO delete when migrated
   *
   * @generated from field: repeated librarian.proto.CastingMetricPb metrics = 7 [deprecated = true];
   * @deprecated
   */
  metrics: CastingMetricPb[] = [];

  /**
   * @generated from field: librarian.proto.CastingOptionsPb options = 8;
   */
  options?: CastingOptionsPb;

  constructor(data?: PartialMessage<Casting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Casting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created", kind: "message", T: Timestamp },
    { no: 5, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "casting_stats", kind: "message", T: Casting_CastingStats },
    { no: 7, name: "metrics", kind: "message", T: CastingMetricPb, repeated: true },
    { no: 8, name: "options", kind: "message", T: CastingOptionsPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Casting {
    return new Casting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Casting {
    return new Casting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Casting {
    return new Casting().fromJsonString(jsonString, options);
  }

  static equals(a: Casting | PlainMessage<Casting> | undefined, b: Casting | PlainMessage<Casting> | undefined): boolean {
    return proto3.util.equals(Casting, a, b);
  }
}

/**
 * @generated from message librarian.proto.Casting.CastingStats
 */
export class Casting_CastingStats extends Message<Casting_CastingStats> {
  /**
   * @generated from field: int64 nb_influencers = 1;
   */
  nbInfluencers = protoInt64.zero;

  /**
   * @generated from field: int64 nb_collaborator = 2;
   */
  nbCollaborator = protoInt64.zero;

  /**
   * @generated from field: int64 nb_votes = 3;
   */
  nbVotes = protoInt64.zero;

  constructor(data?: PartialMessage<Casting_CastingStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Casting.CastingStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nb_influencers", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "nb_collaborator", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "nb_votes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Casting_CastingStats {
    return new Casting_CastingStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Casting_CastingStats {
    return new Casting_CastingStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Casting_CastingStats {
    return new Casting_CastingStats().fromJsonString(jsonString, options);
  }

  static equals(a: Casting_CastingStats | PlainMessage<Casting_CastingStats> | undefined, b: Casting_CastingStats | PlainMessage<Casting_CastingStats> | undefined): boolean {
    return proto3.util.equals(Casting_CastingStats, a, b);
  }
}

/**
 * @generated from message librarian.proto.Castings
 */
export class Castings extends Message<Castings> {
  /**
   * @generated from field: repeated librarian.proto.Casting castings = 1;
   */
  castings: Casting[] = [];

  constructor(data?: PartialMessage<Castings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Castings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "castings", kind: "message", T: Casting, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Castings {
    return new Castings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Castings {
    return new Castings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Castings {
    return new Castings().fromJsonString(jsonString, options);
  }

  static equals(a: Castings | PlainMessage<Castings> | undefined, b: Castings | PlainMessage<Castings> | undefined): boolean {
    return proto3.util.equals(Castings, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateCastingRequest
 */
export class UpdateCastingRequest extends Message<UpdateCastingRequest> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * @generated from oneof librarian.proto.UpdateCastingRequest.field_to_update
   */
  fieldToUpdate: {
    /**
     * @generated from field: google.protobuf.StringValue name = 2;
     */
    value: StringValue;
    case: "name";
  } | {
    /**
     * @generated from field: google.protobuf.StringValue description = 3;
     */
    value: StringValue;
    case: "description";
  } | {
    /**
     * @generated from field: librarian.proto.CastingOptionsPb options = 4;
     */
    value: CastingOptionsPb;
    case: "options";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UpdateCastingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateCastingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "message", T: StringValue, oneof: "field_to_update" },
    { no: 3, name: "description", kind: "message", T: StringValue, oneof: "field_to_update" },
    { no: 4, name: "options", kind: "message", T: CastingOptionsPb, oneof: "field_to_update" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCastingRequest {
    return new UpdateCastingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCastingRequest {
    return new UpdateCastingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCastingRequest {
    return new UpdateCastingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCastingRequest | PlainMessage<UpdateCastingRequest> | undefined, b: UpdateCastingRequest | PlainMessage<UpdateCastingRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCastingRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateCastingMetricsRequest
 */
export class UpdateCastingMetricsRequest extends Message<UpdateCastingMetricsRequest> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * TODO delete when migrated
   *
   * @generated from field: repeated librarian.proto.CastingMetricPb metric = 2 [deprecated = true];
   * @deprecated
   */
  metric: CastingMetricPb[] = [];

  /**
   * @generated from field: librarian.proto.CastingOptionsPb options = 3;
   */
  options?: CastingOptionsPb;

  constructor(data?: PartialMessage<UpdateCastingMetricsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateCastingMetricsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "metric", kind: "message", T: CastingMetricPb, repeated: true },
    { no: 3, name: "options", kind: "message", T: CastingOptionsPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCastingMetricsRequest {
    return new UpdateCastingMetricsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCastingMetricsRequest {
    return new UpdateCastingMetricsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCastingMetricsRequest {
    return new UpdateCastingMetricsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCastingMetricsRequest | PlainMessage<UpdateCastingMetricsRequest> | undefined, b: UpdateCastingMetricsRequest | PlainMessage<UpdateCastingMetricsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCastingMetricsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CastingAudienceMetricPb
 */
export class CastingAudienceMetricPb extends Message<CastingAudienceMetricPb> {
  /**
   * @generated from field: librarian.proto.CastingAudienceMetricEnum metric = 1;
   */
  metric = CastingAudienceMetricEnum.CASTING_AUDIENCE_METRIC_UNKNOWN;

  /**
   * @generated from field: bool display = 2;
   */
  display = false;

  constructor(data?: PartialMessage<CastingAudienceMetricPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CastingAudienceMetricPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metric", kind: "enum", T: proto3.getEnumType(CastingAudienceMetricEnum) },
    { no: 2, name: "display", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastingAudienceMetricPb {
    return new CastingAudienceMetricPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastingAudienceMetricPb {
    return new CastingAudienceMetricPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastingAudienceMetricPb {
    return new CastingAudienceMetricPb().fromJsonString(jsonString, options);
  }

  static equals(a: CastingAudienceMetricPb | PlainMessage<CastingAudienceMetricPb> | undefined, b: CastingAudienceMetricPb | PlainMessage<CastingAudienceMetricPb> | undefined): boolean {
    return proto3.util.equals(CastingAudienceMetricPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.CastingMetricPb
 */
export class CastingMetricPb extends Message<CastingMetricPb> {
  /**
   * @generated from field: librarian.proto.CastingMetricEnum metric = 1;
   */
  metric = CastingMetricEnum.CASTING_METRIC_AUDIENCE_UNKNOWN;

  /**
   * @generated from field: bool display = 2;
   */
  display = false;

  constructor(data?: PartialMessage<CastingMetricPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CastingMetricPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metric", kind: "enum", T: proto3.getEnumType(CastingMetricEnum) },
    { no: 2, name: "display", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastingMetricPb {
    return new CastingMetricPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastingMetricPb {
    return new CastingMetricPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastingMetricPb {
    return new CastingMetricPb().fromJsonString(jsonString, options);
  }

  static equals(a: CastingMetricPb | PlainMessage<CastingMetricPb> | undefined, b: CastingMetricPb | PlainMessage<CastingMetricPb> | undefined): boolean {
    return proto3.util.equals(CastingMetricPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.CastingAudienceMetricsPb
 */
export class CastingAudienceMetricsPb extends Message<CastingAudienceMetricsPb> {
  /**
   * @generated from field: repeated librarian.proto.CastingAudienceMetricPb audience_metrics = 1;
   */
  audienceMetrics: CastingAudienceMetricPb[] = [];

  /**
   * @generated from field: bool display_audience = 2;
   */
  displayAudience = false;

  constructor(data?: PartialMessage<CastingAudienceMetricsPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CastingAudienceMetricsPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "audience_metrics", kind: "message", T: CastingAudienceMetricPb, repeated: true },
    { no: 2, name: "display_audience", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastingAudienceMetricsPb {
    return new CastingAudienceMetricsPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastingAudienceMetricsPb {
    return new CastingAudienceMetricsPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastingAudienceMetricsPb {
    return new CastingAudienceMetricsPb().fromJsonString(jsonString, options);
  }

  static equals(a: CastingAudienceMetricsPb | PlainMessage<CastingAudienceMetricsPb> | undefined, b: CastingAudienceMetricsPb | PlainMessage<CastingAudienceMetricsPb> | undefined): boolean {
    return proto3.util.equals(CastingAudienceMetricsPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.CastingStatisticsMetricPb
 */
export class CastingStatisticsMetricPb extends Message<CastingStatisticsMetricPb> {
  /**
   * @generated from field: librarian.proto.CastingStatisticsMetricEnum metric = 1;
   */
  metric = CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_UNKNOWN;

  /**
   * @generated from field: bool display = 2;
   */
  display = false;

  constructor(data?: PartialMessage<CastingStatisticsMetricPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CastingStatisticsMetricPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metric", kind: "enum", T: proto3.getEnumType(CastingStatisticsMetricEnum) },
    { no: 2, name: "display", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastingStatisticsMetricPb {
    return new CastingStatisticsMetricPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastingStatisticsMetricPb {
    return new CastingStatisticsMetricPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastingStatisticsMetricPb {
    return new CastingStatisticsMetricPb().fromJsonString(jsonString, options);
  }

  static equals(a: CastingStatisticsMetricPb | PlainMessage<CastingStatisticsMetricPb> | undefined, b: CastingStatisticsMetricPb | PlainMessage<CastingStatisticsMetricPb> | undefined): boolean {
    return proto3.util.equals(CastingStatisticsMetricPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.CastingStatisticsMetricsPb
 */
export class CastingStatisticsMetricsPb extends Message<CastingStatisticsMetricsPb> {
  /**
   * @generated from field: repeated librarian.proto.CastingStatisticsMetricPb statistics_metrics = 1;
   */
  statisticsMetrics: CastingStatisticsMetricPb[] = [];

  /**
   * @generated from field: bool display_statistics = 2;
   */
  displayStatistics = false;

  constructor(data?: PartialMessage<CastingStatisticsMetricsPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CastingStatisticsMetricsPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "statistics_metrics", kind: "message", T: CastingStatisticsMetricPb, repeated: true },
    { no: 2, name: "display_statistics", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastingStatisticsMetricsPb {
    return new CastingStatisticsMetricsPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastingStatisticsMetricsPb {
    return new CastingStatisticsMetricsPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastingStatisticsMetricsPb {
    return new CastingStatisticsMetricsPb().fromJsonString(jsonString, options);
  }

  static equals(a: CastingStatisticsMetricsPb | PlainMessage<CastingStatisticsMetricsPb> | undefined, b: CastingStatisticsMetricsPb | PlainMessage<CastingStatisticsMetricsPb> | undefined): boolean {
    return proto3.util.equals(CastingStatisticsMetricsPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadInfluencersCastingRequest
 */
export class UploadInfluencersCastingRequest extends Message<UploadInfluencersCastingRequest> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * @generated from field: repeated string influencer_names = 2;
   */
  influencerNames: string[] = [];

  /**
   * For backwards compatibility and safety reasons, any illegal value (unset, 0, -1, etc)
   * will be treated as INSTA.
   *
   * @generated from field: common.proto.Network network = 4;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: repeated int64 tags_to_add = 5;
   */
  tagsToAdd: bigint[] = [];

  constructor(data?: PartialMessage<UploadInfluencersCastingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadInfluencersCastingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "influencer_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 5, name: "tags_to_add", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadInfluencersCastingRequest {
    return new UploadInfluencersCastingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadInfluencersCastingRequest {
    return new UploadInfluencersCastingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadInfluencersCastingRequest {
    return new UploadInfluencersCastingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadInfluencersCastingRequest | PlainMessage<UploadInfluencersCastingRequest> | undefined, b: UploadInfluencersCastingRequest | PlainMessage<UploadInfluencersCastingRequest> | undefined): boolean {
    return proto3.util.equals(UploadInfluencersCastingRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.AddingInfluencersCastingRequest
 */
export class AddingInfluencersCastingRequest extends Message<AddingInfluencersCastingRequest> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.FlexibleCreatorSelector selector = 8;
   */
  selector?: FlexibleCreatorSelector;

  /**
   * @generated from field: repeated int64 tags_to_add = 9;
   */
  tagsToAdd: bigint[] = [];

  /**
   * @generated from field: common.proto.NightwatchCtx nightwatch_ctx = 5;
   */
  nightwatchCtx?: NightwatchCtx;

  constructor(data?: PartialMessage<AddingInfluencersCastingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AddingInfluencersCastingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "selector", kind: "message", T: FlexibleCreatorSelector },
    { no: 9, name: "tags_to_add", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 5, name: "nightwatch_ctx", kind: "message", T: NightwatchCtx },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddingInfluencersCastingRequest {
    return new AddingInfluencersCastingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddingInfluencersCastingRequest {
    return new AddingInfluencersCastingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddingInfluencersCastingRequest {
    return new AddingInfluencersCastingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddingInfluencersCastingRequest | PlainMessage<AddingInfluencersCastingRequest> | undefined, b: AddingInfluencersCastingRequest | PlainMessage<AddingInfluencersCastingRequest> | undefined): boolean {
    return proto3.util.equals(AddingInfluencersCastingRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.AddingCollaboratorCastingRequest
 */
export class AddingCollaboratorCastingRequest extends Message<AddingCollaboratorCastingRequest> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * @generated from field: repeated string collaborator_emails = 2;
   */
  collaboratorEmails: string[] = [];

  constructor(data?: PartialMessage<AddingCollaboratorCastingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AddingCollaboratorCastingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "collaborator_emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddingCollaboratorCastingRequest {
    return new AddingCollaboratorCastingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddingCollaboratorCastingRequest {
    return new AddingCollaboratorCastingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddingCollaboratorCastingRequest {
    return new AddingCollaboratorCastingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddingCollaboratorCastingRequest | PlainMessage<AddingCollaboratorCastingRequest> | undefined, b: AddingCollaboratorCastingRequest | PlainMessage<AddingCollaboratorCastingRequest> | undefined): boolean {
    return proto3.util.equals(AddingCollaboratorCastingRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CollaboratorCastingResponse
 */
export class CollaboratorCastingResponse extends Message<CollaboratorCastingResponse> {
  /**
   * @generated from field: int64 collaborator_casting_id = 1;
   */
  collaboratorCastingId = protoInt64.zero;

  /**
   * @generated from field: string casting_name = 2;
   */
  castingName = "";

  /**
   * @generated from field: string casting_description = 3;
   */
  castingDescription = "";

  /**
   * @generated from field: string collaborator_email = 4;
   */
  collaboratorEmail = "";

  /**
   * @generated from field: google.protobuf.Timestamp casting_created = 5;
   */
  castingCreated?: Timestamp;

  /**
   * What metrics to display or not. Absence means true by default.
   *
   * TODO delete when migrated
   *
   * @generated from field: repeated librarian.proto.CastingMetricPb metrics = 6 [deprecated = true];
   * @deprecated
   */
  metrics: CastingMetricPb[] = [];

  /**
   * @generated from field: librarian.proto.CastingOptionsPb options = 7;
   */
  options?: CastingOptionsPb;

  constructor(data?: PartialMessage<CollaboratorCastingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CollaboratorCastingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "collaborator_casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "casting_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "casting_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "collaborator_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "casting_created", kind: "message", T: Timestamp },
    { no: 6, name: "metrics", kind: "message", T: CastingMetricPb, repeated: true },
    { no: 7, name: "options", kind: "message", T: CastingOptionsPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CollaboratorCastingResponse {
    return new CollaboratorCastingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CollaboratorCastingResponse {
    return new CollaboratorCastingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CollaboratorCastingResponse {
    return new CollaboratorCastingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CollaboratorCastingResponse | PlainMessage<CollaboratorCastingResponse> | undefined, b: CollaboratorCastingResponse | PlainMessage<CollaboratorCastingResponse> | undefined): boolean {
    return proto3.util.equals(CollaboratorCastingResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.NoteCastingProto
 */
export class NoteCastingProto extends Message<NoteCastingProto> {
  /**
   * @generated from field: int64 note_id = 1;
   */
  noteId = protoInt64.zero;

  /**
   * @generated from field: string note_content = 2;
   */
  noteContent = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 10;
   */
  created?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated = 3;
   */
  updated?: Timestamp;

  /**
   * @generated from field: int64 influencer_casting_id = 4;
   */
  influencerCastingId = protoInt64.zero;

  /**
   * @generated from field: int64 collaborator_casting_id = 5;
   */
  collaboratorCastingId = protoInt64.zero;

  /**
   * @generated from oneof librarian.proto.NoteCastingProto.email_author
   */
  emailAuthor: {
    /**
     * @generated from field: string email_collaborator = 6;
     */
    value: string;
    case: "emailCollaborator";
  } | {
    /**
     * @generated from field: string email_user = 9;
     */
    value: string;
    case: "emailUser";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: bool editable = 7;
   */
  editable = false;

  /**
   * @generated from field: bool from_workspace_user = 8;
   */
  fromWorkspaceUser = false;

  constructor(data?: PartialMessage<NoteCastingProto>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.NoteCastingProto";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "note_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "note_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "created", kind: "message", T: Timestamp },
    { no: 3, name: "updated", kind: "message", T: Timestamp },
    { no: 4, name: "influencer_casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "collaborator_casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "email_collaborator", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "email_author" },
    { no: 9, name: "email_user", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "email_author" },
    { no: 7, name: "editable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "from_workspace_user", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NoteCastingProto {
    return new NoteCastingProto().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NoteCastingProto {
    return new NoteCastingProto().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NoteCastingProto {
    return new NoteCastingProto().fromJsonString(jsonString, options);
  }

  static equals(a: NoteCastingProto | PlainMessage<NoteCastingProto> | undefined, b: NoteCastingProto | PlainMessage<NoteCastingProto> | undefined): boolean {
    return proto3.util.equals(NoteCastingProto, a, b);
  }
}

/**
 * @generated from message librarian.proto.AddNoteRequest
 */
export class AddNoteRequest extends Message<AddNoteRequest> {
  /**
   * @generated from field: int64 casting_influencer_id = 1;
   */
  castingInfluencerId = protoInt64.zero;

  /**
   * @generated from field: string note_content = 2;
   */
  noteContent = "";

  constructor(data?: PartialMessage<AddNoteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AddNoteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_influencer_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "note_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddNoteRequest {
    return new AddNoteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddNoteRequest {
    return new AddNoteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddNoteRequest {
    return new AddNoteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddNoteRequest | PlainMessage<AddNoteRequest> | undefined, b: AddNoteRequest | PlainMessage<AddNoteRequest> | undefined): boolean {
    return proto3.util.equals(AddNoteRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateNoteRequest
 */
export class UpdateNoteRequest extends Message<UpdateNoteRequest> {
  /**
   * @generated from field: int64 note_id = 1;
   */
  noteId = protoInt64.zero;

  /**
   * @generated from field: string note_content = 3;
   */
  noteContent = "";

  constructor(data?: PartialMessage<UpdateNoteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateNoteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "note_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "note_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateNoteRequest {
    return new UpdateNoteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateNoteRequest {
    return new UpdateNoteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateNoteRequest {
    return new UpdateNoteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateNoteRequest | PlainMessage<UpdateNoteRequest> | undefined, b: UpdateNoteRequest | PlainMessage<UpdateNoteRequest> | undefined): boolean {
    return proto3.util.equals(UpdateNoteRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CollaboratorCastingInfluencersRequest
 */
export class CollaboratorCastingInfluencersRequest extends Message<CollaboratorCastingInfluencersRequest> {
  /**
   * @generated from field: librarian.proto.CastingInfluencerCollaboratorSort sort = 1;
   */
  sort = CastingInfluencerCollaboratorSort.DATE_ADDED_INFLUENCER_CASTING;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 2;
   */
  sortDirection = SortDirection.DESC;

  /**
   * @generated from field: librarian.proto.CollaboratorCastingInfluencersFilter filters = 3;
   */
  filters?: CollaboratorCastingInfluencersFilter;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<CollaboratorCastingInfluencersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CollaboratorCastingInfluencersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sort", kind: "enum", T: proto3.getEnumType(CastingInfluencerCollaboratorSort) },
    { no: 2, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 3, name: "filters", kind: "message", T: CollaboratorCastingInfluencersFilter },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CollaboratorCastingInfluencersRequest {
    return new CollaboratorCastingInfluencersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CollaboratorCastingInfluencersRequest {
    return new CollaboratorCastingInfluencersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CollaboratorCastingInfluencersRequest {
    return new CollaboratorCastingInfluencersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CollaboratorCastingInfluencersRequest | PlainMessage<CollaboratorCastingInfluencersRequest> | undefined, b: CollaboratorCastingInfluencersRequest | PlainMessage<CollaboratorCastingInfluencersRequest> | undefined): boolean {
    return proto3.util.equals(CollaboratorCastingInfluencersRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CollaboratorCastingInfluencersResponse
 */
export class CollaboratorCastingInfluencersResponse extends Message<CollaboratorCastingInfluencersResponse> {
  /**
   * @generated from field: repeated librarian.proto.CollaboratorCastingInfluencerItem influencers = 1;
   */
  influencers: CollaboratorCastingInfluencerItem[] = [];

  /**
   * @generated from field: int64 total_result = 2;
   */
  totalResult = protoInt64.zero;

  /**
   * @generated from field: int64 total_approved = 3;
   */
  totalApproved = protoInt64.zero;

  /**
   * @generated from field: int64 total_rejected = 4;
   */
  totalRejected = protoInt64.zero;

  /**
   * @generated from field: int64 total_pending = 5;
   */
  totalPending = protoInt64.zero;

  constructor(data?: PartialMessage<CollaboratorCastingInfluencersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CollaboratorCastingInfluencersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencers", kind: "message", T: CollaboratorCastingInfluencerItem, repeated: true },
    { no: 2, name: "total_result", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "total_approved", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "total_rejected", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "total_pending", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CollaboratorCastingInfluencersResponse {
    return new CollaboratorCastingInfluencersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CollaboratorCastingInfluencersResponse {
    return new CollaboratorCastingInfluencersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CollaboratorCastingInfluencersResponse {
    return new CollaboratorCastingInfluencersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CollaboratorCastingInfluencersResponse | PlainMessage<CollaboratorCastingInfluencersResponse> | undefined, b: CollaboratorCastingInfluencersResponse | PlainMessage<CollaboratorCastingInfluencersResponse> | undefined): boolean {
    return proto3.util.equals(CollaboratorCastingInfluencersResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.CollaboratorCastingInfluencerItem
 */
export class CollaboratorCastingInfluencerItem extends Message<CollaboratorCastingInfluencerItem> {
  /**
   * @generated from field: librarian.proto.CreatorCardSnippet base_snippet = 1;
   */
  baseSnippet?: CreatorCardSnippet;

  /**
   * @generated from field: repeated librarian.proto.GenerikInfoCard network_info = 2;
   */
  networkInfo: GenerikInfoCard[] = [];

  /**
   * @generated from field: librarian.proto.InfluencerCastingStatus influencer_status = 3;
   */
  influencerStatus = InfluencerCastingStatus.INFLUENCER_CASTING_PENDING;

  /**
   * @generated from field: int64 casting_influencer_id = 5;
   */
  castingInfluencerId = protoInt64.zero;

  /**
   * @generated from field: repeated librarian.proto.NoteCastingProto notes = 6;
   */
  notes: NoteCastingProto[] = [];

  constructor(data?: PartialMessage<CollaboratorCastingInfluencerItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CollaboratorCastingInfluencerItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_snippet", kind: "message", T: CreatorCardSnippet },
    { no: 2, name: "network_info", kind: "message", T: GenerikInfoCard, repeated: true },
    { no: 3, name: "influencer_status", kind: "enum", T: proto3.getEnumType(InfluencerCastingStatus) },
    { no: 5, name: "casting_influencer_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "notes", kind: "message", T: NoteCastingProto, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CollaboratorCastingInfluencerItem {
    return new CollaboratorCastingInfluencerItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CollaboratorCastingInfluencerItem {
    return new CollaboratorCastingInfluencerItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CollaboratorCastingInfluencerItem {
    return new CollaboratorCastingInfluencerItem().fromJsonString(jsonString, options);
  }

  static equals(a: CollaboratorCastingInfluencerItem | PlainMessage<CollaboratorCastingInfluencerItem> | undefined, b: CollaboratorCastingInfluencerItem | PlainMessage<CollaboratorCastingInfluencerItem> | undefined): boolean {
    return proto3.util.equals(CollaboratorCastingInfluencerItem, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetCollaboratorForecastingExpectedPostsRequest
 */
export class GetCollaboratorForecastingExpectedPostsRequest extends Message<GetCollaboratorForecastingExpectedPostsRequest> {
  /**
   * @generated from field: common.proto.SortDirection sort_direction = 1;
   */
  sortDirection = SortDirection.DESC;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 2;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<GetCollaboratorForecastingExpectedPostsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetCollaboratorForecastingExpectedPostsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 2, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCollaboratorForecastingExpectedPostsRequest {
    return new GetCollaboratorForecastingExpectedPostsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCollaboratorForecastingExpectedPostsRequest {
    return new GetCollaboratorForecastingExpectedPostsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCollaboratorForecastingExpectedPostsRequest {
    return new GetCollaboratorForecastingExpectedPostsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCollaboratorForecastingExpectedPostsRequest | PlainMessage<GetCollaboratorForecastingExpectedPostsRequest> | undefined, b: GetCollaboratorForecastingExpectedPostsRequest | PlainMessage<GetCollaboratorForecastingExpectedPostsRequest> | undefined): boolean {
    return proto3.util.equals(GetCollaboratorForecastingExpectedPostsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetCollaboratorForecastingExpectedPostsResponse
 */
export class GetCollaboratorForecastingExpectedPostsResponse extends Message<GetCollaboratorForecastingExpectedPostsResponse> {
  /**
   * @generated from field: repeated librarian.proto.GetCollaboratorForecastingExpectedPostsResponse.CollaboratorForecastingExpectedPostsInfluencer influencers = 1;
   */
  influencers: GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer[] = [];

  /**
   * @generated from field: repeated librarian.proto.GetCollaboratorForecastingExpectedPostsResponse.CollaboratorForecastingExpectedPostsNetworkTotal networks_total = 2;
   */
  networksTotal: GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal[] = [];

  /**
   * @generated from field: double estimated_costs_total = 3;
   */
  estimatedCostsTotal = 0;

  /**
   * @generated from field: int32 total_hits = 4;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<GetCollaboratorForecastingExpectedPostsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetCollaboratorForecastingExpectedPostsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencers", kind: "message", T: GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer, repeated: true },
    { no: 2, name: "networks_total", kind: "message", T: GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal, repeated: true },
    { no: 3, name: "estimated_costs_total", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCollaboratorForecastingExpectedPostsResponse {
    return new GetCollaboratorForecastingExpectedPostsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCollaboratorForecastingExpectedPostsResponse {
    return new GetCollaboratorForecastingExpectedPostsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCollaboratorForecastingExpectedPostsResponse {
    return new GetCollaboratorForecastingExpectedPostsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCollaboratorForecastingExpectedPostsResponse | PlainMessage<GetCollaboratorForecastingExpectedPostsResponse> | undefined, b: GetCollaboratorForecastingExpectedPostsResponse | PlainMessage<GetCollaboratorForecastingExpectedPostsResponse> | undefined): boolean {
    return proto3.util.equals(GetCollaboratorForecastingExpectedPostsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetCollaboratorForecastingExpectedPostsResponse.CollaboratorForecastingExpectedPostsInfluencer
 */
export class GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer extends Message<GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer> {
  /**
   * @generated from field: librarian.proto.CreatorCardSnippet base_snippet = 1;
   */
  baseSnippet?: CreatorCardSnippet;

  /**
   * @generated from field: double estimated_costs = 2;
   */
  estimatedCosts = 0;

  /**
   * @generated from field: repeated librarian.proto.InfluencerExpectedPosts expected_posts_by_network = 3;
   */
  expectedPostsByNetwork: InfluencerExpectedPosts[] = [];

  /**
   * @generated from field: repeated librarian.proto.InfluencerExpectedPosts expected_posts_by_post_type = 4;
   */
  expectedPostsByPostType: InfluencerExpectedPosts[] = [];

  constructor(data?: PartialMessage<GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetCollaboratorForecastingExpectedPostsResponse.CollaboratorForecastingExpectedPostsInfluencer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_snippet", kind: "message", T: CreatorCardSnippet },
    { no: 2, name: "estimated_costs", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "expected_posts_by_network", kind: "message", T: InfluencerExpectedPosts, repeated: true },
    { no: 4, name: "expected_posts_by_post_type", kind: "message", T: InfluencerExpectedPosts, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer {
    return new GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer {
    return new GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer {
    return new GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer().fromJsonString(jsonString, options);
  }

  static equals(a: GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer | PlainMessage<GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer> | undefined, b: GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer | PlainMessage<GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer> | undefined): boolean {
    return proto3.util.equals(GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetCollaboratorForecastingExpectedPostsResponse.CollaboratorForecastingExpectedPostsNetworkTotal
 */
export class GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal extends Message<GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: int32 expected_posts_count_total = 2;
   */
  expectedPostsCountTotal = 0;

  constructor(data?: PartialMessage<GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetCollaboratorForecastingExpectedPostsResponse.CollaboratorForecastingExpectedPostsNetworkTotal";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "expected_posts_count_total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal {
    return new GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal {
    return new GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal {
    return new GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal().fromJsonString(jsonString, options);
  }

  static equals(a: GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal | PlainMessage<GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal> | undefined, b: GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal | PlainMessage<GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal> | undefined): boolean {
    return proto3.util.equals(GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsNetworkTotal, a, b);
  }
}

/**
 * @generated from message librarian.proto.CollaboratorItems
 */
export class CollaboratorItems extends Message<CollaboratorItems> {
  /**
   * @generated from field: repeated librarian.proto.CollaboratorItem collaborator_item = 1;
   */
  collaboratorItem: CollaboratorItem[] = [];

  constructor(data?: PartialMessage<CollaboratorItems>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CollaboratorItems";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "collaborator_item", kind: "message", T: CollaboratorItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CollaboratorItems {
    return new CollaboratorItems().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CollaboratorItems {
    return new CollaboratorItems().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CollaboratorItems {
    return new CollaboratorItems().fromJsonString(jsonString, options);
  }

  static equals(a: CollaboratorItems | PlainMessage<CollaboratorItems> | undefined, b: CollaboratorItems | PlainMessage<CollaboratorItems> | undefined): boolean {
    return proto3.util.equals(CollaboratorItems, a, b);
  }
}

/**
 * @generated from message librarian.proto.CollaboratorItem
 */
export class CollaboratorItem extends Message<CollaboratorItem> {
  /**
   * @generated from field: int64 collaborator_id = 1;
   */
  collaboratorId = protoInt64.zero;

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 3;
   */
  created?: Timestamp;

  /**
   * @generated from field: bool access = 4;
   */
  access = false;

  /**
   * @generated from field: string link = 5;
   */
  link = "";

  /**
   * @generated from field: librarian.proto.CollaboratorItem.CollaboratorStats collaborator_stats = 6;
   */
  collaboratorStats?: CollaboratorItem_CollaboratorStats;

  /**
   * @generated from field: int64 collaborator_casting_id = 7;
   */
  collaboratorCastingId = protoInt64.zero;

  constructor(data?: PartialMessage<CollaboratorItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CollaboratorItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "collaborator_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created", kind: "message", T: Timestamp },
    { no: 4, name: "access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "collaborator_stats", kind: "message", T: CollaboratorItem_CollaboratorStats },
    { no: 7, name: "collaborator_casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CollaboratorItem {
    return new CollaboratorItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CollaboratorItem {
    return new CollaboratorItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CollaboratorItem {
    return new CollaboratorItem().fromJsonString(jsonString, options);
  }

  static equals(a: CollaboratorItem | PlainMessage<CollaboratorItem> | undefined, b: CollaboratorItem | PlainMessage<CollaboratorItem> | undefined): boolean {
    return proto3.util.equals(CollaboratorItem, a, b);
  }
}

/**
 * @generated from message librarian.proto.CollaboratorItem.CollaboratorStats
 */
export class CollaboratorItem_CollaboratorStats extends Message<CollaboratorItem_CollaboratorStats> {
  /**
   * @generated from field: int32 nb_vote = 1;
   */
  nbVote = 0;

  /**
   * @generated from field: int32 nb_note = 2;
   */
  nbNote = 0;

  /**
   * @generated from field: int32 nb_approved = 3;
   */
  nbApproved = 0;

  constructor(data?: PartialMessage<CollaboratorItem_CollaboratorStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CollaboratorItem.CollaboratorStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nb_vote", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "nb_note", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "nb_approved", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CollaboratorItem_CollaboratorStats {
    return new CollaboratorItem_CollaboratorStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CollaboratorItem_CollaboratorStats {
    return new CollaboratorItem_CollaboratorStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CollaboratorItem_CollaboratorStats {
    return new CollaboratorItem_CollaboratorStats().fromJsonString(jsonString, options);
  }

  static equals(a: CollaboratorItem_CollaboratorStats | PlainMessage<CollaboratorItem_CollaboratorStats> | undefined, b: CollaboratorItem_CollaboratorStats | PlainMessage<CollaboratorItem_CollaboratorStats> | undefined): boolean {
    return proto3.util.equals(CollaboratorItem_CollaboratorStats, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateAccessCollaboratorToCastingRequest
 */
export class UpdateAccessCollaboratorToCastingRequest extends Message<UpdateAccessCollaboratorToCastingRequest> {
  /**
   * @generated from field: int64 collaborator_casting_id = 1;
   */
  collaboratorCastingId = protoInt64.zero;

  /**
   * @generated from field: bool enable_access_to_casting = 2;
   */
  enableAccessToCasting = false;

  constructor(data?: PartialMessage<UpdateAccessCollaboratorToCastingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateAccessCollaboratorToCastingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "collaborator_casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "enable_access_to_casting", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAccessCollaboratorToCastingRequest {
    return new UpdateAccessCollaboratorToCastingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAccessCollaboratorToCastingRequest {
    return new UpdateAccessCollaboratorToCastingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAccessCollaboratorToCastingRequest {
    return new UpdateAccessCollaboratorToCastingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAccessCollaboratorToCastingRequest | PlainMessage<UpdateAccessCollaboratorToCastingRequest> | undefined, b: UpdateAccessCollaboratorToCastingRequest | PlainMessage<UpdateAccessCollaboratorToCastingRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAccessCollaboratorToCastingRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetInfluencersCastingRequest
 */
export class GetInfluencersCastingRequest extends Message<GetInfluencersCastingRequest> {
  /**
   * @generated from field: librarian.proto.InfluencersCastingFilter casting_filter = 6;
   */
  castingFilter?: InfluencersCastingFilter;

  /**
   * @generated from field: int64 casting_id = 1 [deprecated = true];
   * @deprecated
   */
  castingId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.CastingInfluencerSort sort = 2;
   */
  sort = CastingInfluencerSort.NAME_INFLUENCER;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 3;
   */
  sortDirection = SortDirection.DESC;

  /**
   * @generated from field: librarian.proto.CollaboratorCastingInfluencersFilter filters = 4 [deprecated = true];
   * @deprecated
   */
  filters?: CollaboratorCastingInfluencersFilter;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 5;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<GetInfluencersCastingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetInfluencersCastingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 6, name: "casting_filter", kind: "message", T: InfluencersCastingFilter },
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "sort", kind: "enum", T: proto3.getEnumType(CastingInfluencerSort) },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 4, name: "filters", kind: "message", T: CollaboratorCastingInfluencersFilter },
    { no: 5, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInfluencersCastingRequest {
    return new GetInfluencersCastingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInfluencersCastingRequest {
    return new GetInfluencersCastingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInfluencersCastingRequest {
    return new GetInfluencersCastingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetInfluencersCastingRequest | PlainMessage<GetInfluencersCastingRequest> | undefined, b: GetInfluencersCastingRequest | PlainMessage<GetInfluencersCastingRequest> | undefined): boolean {
    return proto3.util.equals(GetInfluencersCastingRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.InfluencerCastingItems
 */
export class InfluencerCastingItems extends Message<InfluencerCastingItems> {
  /**
   * @generated from field: repeated librarian.proto.InfluencerCastingItem influencer_casting_list = 1;
   */
  influencerCastingList: InfluencerCastingItem[] = [];

  /**
   * @generated from field: int64 total_result = 2;
   */
  totalResult = protoInt64.zero;

  /**
   * @generated from field: int64 total_approved = 3;
   */
  totalApproved = protoInt64.zero;

  /**
   * @generated from field: int64 total_rejected = 4;
   */
  totalRejected = protoInt64.zero;

  /**
   * @generated from field: int64 total_pending = 5;
   */
  totalPending = protoInt64.zero;

  constructor(data?: PartialMessage<InfluencerCastingItems>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InfluencerCastingItems";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_casting_list", kind: "message", T: InfluencerCastingItem, repeated: true },
    { no: 2, name: "total_result", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "total_approved", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "total_rejected", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "total_pending", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerCastingItems {
    return new InfluencerCastingItems().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerCastingItems {
    return new InfluencerCastingItems().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerCastingItems {
    return new InfluencerCastingItems().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerCastingItems | PlainMessage<InfluencerCastingItems> | undefined, b: InfluencerCastingItems | PlainMessage<InfluencerCastingItems> | undefined): boolean {
    return proto3.util.equals(InfluencerCastingItems, a, b);
  }
}

/**
 * @generated from message librarian.proto.InfluencerCastingItem
 */
export class InfluencerCastingItem extends Message<InfluencerCastingItem> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.InfluencerCastingStatus status = 2;
   */
  status = InfluencerCastingStatus.INFLUENCER_CASTING_PENDING;

  /**
   * @generated from field: librarian.proto.InfluencerCastingItem.CollaboratorVote collaborator_vote_approved = 4;
   */
  collaboratorVoteApproved?: InfluencerCastingItem_CollaboratorVote;

  /**
   * @generated from field: librarian.proto.InfluencerCastingItem.CollaboratorVote collaborator_vote_rejected = 5;
   */
  collaboratorVoteRejected?: InfluencerCastingItem_CollaboratorVote;

  /**
   * @generated from field: librarian.proto.InfluencerCastingItem.CollaboratorVote collaborator_vote_pending = 6;
   */
  collaboratorVotePending?: InfluencerCastingItem_CollaboratorVote;

  /**
   * @generated from field: google.protobuf.Timestamp created = 8;
   */
  created?: Timestamp;

  /**
   * @generated from field: librarian.proto.InfluencerCastingItem.InfluencerInfo influencer_info = 9;
   */
  influencerInfo?: InfluencerCastingItem_InfluencerInfo;

  /**
   * @generated from field: repeated librarian.proto.NoteCastingProto list_notes_v2 = 10;
   */
  listNotesV2: NoteCastingProto[] = [];

  /**
   * @generated from field: repeated librarian.proto.InfluencerExpectedPosts expected_posts = 11;
   */
  expectedPosts: InfluencerExpectedPosts[] = [];

  /**
   * If cost_type = INFLUENCER_CASTING_COST_TYPE_FIXED_COST then get the cost from field fixed_cost
   * If cost_type = INFLUENCER_CASTING_COST_TYPE_COST_PER_POST then get the costs from expected_posts
   *
   * @generated from field: librarian.proto.InfluencerCastingCostType cost_type = 12;
   */
  costType = InfluencerCastingCostType.UNKNOWN;

  /**
   * @generated from field: google.protobuf.DoubleValue fixed_cost = 13;
   */
  fixedCost?: number;

  constructor(data?: PartialMessage<InfluencerCastingItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InfluencerCastingItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(InfluencerCastingStatus) },
    { no: 4, name: "collaborator_vote_approved", kind: "message", T: InfluencerCastingItem_CollaboratorVote },
    { no: 5, name: "collaborator_vote_rejected", kind: "message", T: InfluencerCastingItem_CollaboratorVote },
    { no: 6, name: "collaborator_vote_pending", kind: "message", T: InfluencerCastingItem_CollaboratorVote },
    { no: 8, name: "created", kind: "message", T: Timestamp },
    { no: 9, name: "influencer_info", kind: "message", T: InfluencerCastingItem_InfluencerInfo },
    { no: 10, name: "list_notes_v2", kind: "message", T: NoteCastingProto, repeated: true },
    { no: 11, name: "expected_posts", kind: "message", T: InfluencerExpectedPosts, repeated: true },
    { no: 12, name: "cost_type", kind: "enum", T: proto3.getEnumType(InfluencerCastingCostType) },
    { no: 13, name: "fixed_cost", kind: "message", T: DoubleValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerCastingItem {
    return new InfluencerCastingItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerCastingItem {
    return new InfluencerCastingItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerCastingItem {
    return new InfluencerCastingItem().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerCastingItem | PlainMessage<InfluencerCastingItem> | undefined, b: InfluencerCastingItem | PlainMessage<InfluencerCastingItem> | undefined): boolean {
    return proto3.util.equals(InfluencerCastingItem, a, b);
  }
}

/**
 * @generated from message librarian.proto.InfluencerCastingItem.CollaboratorVote
 */
export class InfluencerCastingItem_CollaboratorVote extends Message<InfluencerCastingItem_CollaboratorVote> {
  /**
   * @generated from field: repeated string emails = 2;
   */
  emails: string[] = [];

  constructor(data?: PartialMessage<InfluencerCastingItem_CollaboratorVote>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InfluencerCastingItem.CollaboratorVote";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerCastingItem_CollaboratorVote {
    return new InfluencerCastingItem_CollaboratorVote().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerCastingItem_CollaboratorVote {
    return new InfluencerCastingItem_CollaboratorVote().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerCastingItem_CollaboratorVote {
    return new InfluencerCastingItem_CollaboratorVote().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerCastingItem_CollaboratorVote | PlainMessage<InfluencerCastingItem_CollaboratorVote> | undefined, b: InfluencerCastingItem_CollaboratorVote | PlainMessage<InfluencerCastingItem_CollaboratorVote> | undefined): boolean {
    return proto3.util.equals(InfluencerCastingItem_CollaboratorVote, a, b);
  }
}

/**
 * @generated from message librarian.proto.InfluencerCastingItem.InfluencerInfo
 */
export class InfluencerCastingItem_InfluencerInfo extends Message<InfluencerCastingItem_InfluencerInfo> {
  /**
   * @generated from field: librarian.proto.CreatorCardSnippet base_snippet = 1;
   */
  baseSnippet?: CreatorCardSnippet;

  /**
   * @generated from field: repeated common.proto.Network networks = 2;
   */
  networks: Network[] = [];

  /**
   * @generated from field: librarian.proto.CreatorRegisteredDetails registered_details = 3;
   */
  registeredDetails?: CreatorRegisteredDetails;

  /**
   * @generated from field: repeated common.proto.FavoritesLabel labels = 4;
   */
  labels: FavoritesLabel[] = [];

  constructor(data?: PartialMessage<InfluencerCastingItem_InfluencerInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InfluencerCastingItem.InfluencerInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_snippet", kind: "message", T: CreatorCardSnippet },
    { no: 2, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 3, name: "registered_details", kind: "message", T: CreatorRegisteredDetails },
    { no: 4, name: "labels", kind: "message", T: FavoritesLabel, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerCastingItem_InfluencerInfo {
    return new InfluencerCastingItem_InfluencerInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerCastingItem_InfluencerInfo {
    return new InfluencerCastingItem_InfluencerInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerCastingItem_InfluencerInfo {
    return new InfluencerCastingItem_InfluencerInfo().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerCastingItem_InfluencerInfo | PlainMessage<InfluencerCastingItem_InfluencerInfo> | undefined, b: InfluencerCastingItem_InfluencerInfo | PlainMessage<InfluencerCastingItem_InfluencerInfo> | undefined): boolean {
    return proto3.util.equals(InfluencerCastingItem_InfluencerInfo, a, b);
  }
}

/**
 * @generated from message librarian.proto.NoteInfluencerCasting
 */
export class NoteInfluencerCasting extends Message<NoteInfluencerCasting> {
  /**
   * @generated from field: int64 note_id = 1;
   */
  noteId = protoInt64.zero;

  /**
   * @generated from field: string content = 2;
   */
  content = "";

  /**
   * @generated from field: string collaborator_email = 3;
   */
  collaboratorEmail = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 4;
   */
  created?: Timestamp;

  /**
   * @generated from field: int64 collaborator_casting_id = 5;
   */
  collaboratorCastingId = protoInt64.zero;

  /**
   * @generated from field: bool editable = 6;
   */
  editable = false;

  /**
   * @generated from field: bool from_workspace_user = 7;
   */
  fromWorkspaceUser = false;

  constructor(data?: PartialMessage<NoteInfluencerCasting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.NoteInfluencerCasting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "note_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "collaborator_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created", kind: "message", T: Timestamp },
    { no: 5, name: "collaborator_casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "editable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "from_workspace_user", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NoteInfluencerCasting {
    return new NoteInfluencerCasting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NoteInfluencerCasting {
    return new NoteInfluencerCasting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NoteInfluencerCasting {
    return new NoteInfluencerCasting().fromJsonString(jsonString, options);
  }

  static equals(a: NoteInfluencerCasting | PlainMessage<NoteInfluencerCasting> | undefined, b: NoteInfluencerCasting | PlainMessage<NoteInfluencerCasting> | undefined): boolean {
    return proto3.util.equals(NoteInfluencerCasting, a, b);
  }
}

/**
 * @generated from message librarian.proto.CastingVoteRequest
 */
export class CastingVoteRequest extends Message<CastingVoteRequest> {
  /**
   * @generated from field: int64 casting_influencer_id = 1;
   */
  castingInfluencerId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.InfluencerCastingStatus influencer_status = 2;
   */
  influencerStatus = InfluencerCastingStatus.INFLUENCER_CASTING_PENDING;

  constructor(data?: PartialMessage<CastingVoteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CastingVoteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_influencer_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "influencer_status", kind: "enum", T: proto3.getEnumType(InfluencerCastingStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastingVoteRequest {
    return new CastingVoteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastingVoteRequest {
    return new CastingVoteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastingVoteRequest {
    return new CastingVoteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CastingVoteRequest | PlainMessage<CastingVoteRequest> | undefined, b: CastingVoteRequest | PlainMessage<CastingVoteRequest> | undefined): boolean {
    return proto3.util.equals(CastingVoteRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.BulkVoteUserOnInfluencerRequest
 */
export class BulkVoteUserOnInfluencerRequest extends Message<BulkVoteUserOnInfluencerRequest> {
  /**
   * @generated from field: librarian.proto.FlexibleCreatorSelector selector = 3;
   */
  selector?: FlexibleCreatorSelector;

  /**
   * @generated from field: librarian.proto.InfluencerCastingStatus influencer_status = 2;
   */
  influencerStatus = InfluencerCastingStatus.INFLUENCER_CASTING_PENDING;

  constructor(data?: PartialMessage<BulkVoteUserOnInfluencerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.BulkVoteUserOnInfluencerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "selector", kind: "message", T: FlexibleCreatorSelector },
    { no: 2, name: "influencer_status", kind: "enum", T: proto3.getEnumType(InfluencerCastingStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BulkVoteUserOnInfluencerRequest {
    return new BulkVoteUserOnInfluencerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BulkVoteUserOnInfluencerRequest {
    return new BulkVoteUserOnInfluencerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BulkVoteUserOnInfluencerRequest {
    return new BulkVoteUserOnInfluencerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BulkVoteUserOnInfluencerRequest | PlainMessage<BulkVoteUserOnInfluencerRequest> | undefined, b: BulkVoteUserOnInfluencerRequest | PlainMessage<BulkVoteUserOnInfluencerRequest> | undefined): boolean {
    return proto3.util.equals(BulkVoteUserOnInfluencerRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.DeleteInfluencersCastingRequest
 */
export class DeleteInfluencersCastingRequest extends Message<DeleteInfluencersCastingRequest> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.FlexibleCreatorSelector selector = 3;
   */
  selector?: FlexibleCreatorSelector;

  constructor(data?: PartialMessage<DeleteInfluencersCastingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DeleteInfluencersCastingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "selector", kind: "message", T: FlexibleCreatorSelector },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteInfluencersCastingRequest {
    return new DeleteInfluencersCastingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteInfluencersCastingRequest {
    return new DeleteInfluencersCastingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteInfluencersCastingRequest {
    return new DeleteInfluencersCastingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteInfluencersCastingRequest | PlainMessage<DeleteInfluencersCastingRequest> | undefined, b: DeleteInfluencersCastingRequest | PlainMessage<DeleteInfluencersCastingRequest> | undefined): boolean {
    return proto3.util.equals(DeleteInfluencersCastingRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ConvertCastingToCampaignRequest
 */
export class ConvertCastingToCampaignRequest extends Message<ConvertCastingToCampaignRequest> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.ConvertCastingToCampaignRequest.CampaignInputs campaign_info = 2;
   */
  campaignInfo?: ConvertCastingToCampaignRequest_CampaignInputs;

  constructor(data?: PartialMessage<ConvertCastingToCampaignRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ConvertCastingToCampaignRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "campaign_info", kind: "message", T: ConvertCastingToCampaignRequest_CampaignInputs },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConvertCastingToCampaignRequest {
    return new ConvertCastingToCampaignRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConvertCastingToCampaignRequest {
    return new ConvertCastingToCampaignRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConvertCastingToCampaignRequest {
    return new ConvertCastingToCampaignRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ConvertCastingToCampaignRequest | PlainMessage<ConvertCastingToCampaignRequest> | undefined, b: ConvertCastingToCampaignRequest | PlainMessage<ConvertCastingToCampaignRequest> | undefined): boolean {
    return proto3.util.equals(ConvertCastingToCampaignRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ConvertCastingToCampaignRequest.CampaignInputs
 */
export class ConvertCastingToCampaignRequest_CampaignInputs extends Message<ConvertCastingToCampaignRequest_CampaignInputs> {
  /**
   * @generated from field: google.protobuf.StringValue name = 1;
   */
  name?: string;

  /**
   * @generated from field: google.protobuf.Timestamp start = 2;
   */
  start?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end = 3;
   */
  end?: Timestamp;

  /**
   * @generated from field: repeated librarian.proto.EntityFieldValue campaign_field_values = 6;
   */
  campaignFieldValues: EntityFieldValue[] = [];

  /**
   * @generated from field: repeated common.proto.CampaignKeyword keywords = 4;
   */
  keywords: CampaignKeyword[] = [];

  /**
   * @generated from field: repeated common.proto.CampaignKeyword exclude_keywords = 8;
   */
  excludeKeywords: CampaignKeyword[] = [];

  /**
   * @generated from field: google.protobuf.BoolValue exclude_performance = 5;
   */
  excludePerformance?: boolean;

  /**
   * @generated from field: repeated common.proto.CampaignTrackingSettingsPb tracking_settings = 7;
   */
  trackingSettings: CampaignTrackingSettingsPb[] = [];

  constructor(data?: PartialMessage<ConvertCastingToCampaignRequest_CampaignInputs>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ConvertCastingToCampaignRequest.CampaignInputs";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "message", T: StringValue },
    { no: 2, name: "start", kind: "message", T: Timestamp },
    { no: 3, name: "end", kind: "message", T: Timestamp },
    { no: 6, name: "campaign_field_values", kind: "message", T: EntityFieldValue, repeated: true },
    { no: 4, name: "keywords", kind: "message", T: CampaignKeyword, repeated: true },
    { no: 8, name: "exclude_keywords", kind: "message", T: CampaignKeyword, repeated: true },
    { no: 5, name: "exclude_performance", kind: "message", T: BoolValue },
    { no: 7, name: "tracking_settings", kind: "message", T: CampaignTrackingSettingsPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConvertCastingToCampaignRequest_CampaignInputs {
    return new ConvertCastingToCampaignRequest_CampaignInputs().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConvertCastingToCampaignRequest_CampaignInputs {
    return new ConvertCastingToCampaignRequest_CampaignInputs().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConvertCastingToCampaignRequest_CampaignInputs {
    return new ConvertCastingToCampaignRequest_CampaignInputs().fromJsonString(jsonString, options);
  }

  static equals(a: ConvertCastingToCampaignRequest_CampaignInputs | PlainMessage<ConvertCastingToCampaignRequest_CampaignInputs> | undefined, b: ConvertCastingToCampaignRequest_CampaignInputs | PlainMessage<ConvertCastingToCampaignRequest_CampaignInputs> | undefined): boolean {
    return proto3.util.equals(ConvertCastingToCampaignRequest_CampaignInputs, a, b);
  }
}

/**
 * @generated from message librarian.proto.ConvertCastingToCampaignTask
 */
export class ConvertCastingToCampaignTask extends Message<ConvertCastingToCampaignTask> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * @generated from field: int64 campaign_id = 2;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: int64 user_id = 3;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 influencer_casting_ids = 4;
   */
  influencerCastingIds: bigint[] = [];

  /**
   * @generated from field: bool is_last_in_batch = 5;
   */
  isLastInBatch = false;

  constructor(data?: PartialMessage<ConvertCastingToCampaignTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ConvertCastingToCampaignTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "influencer_casting_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 5, name: "is_last_in_batch", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConvertCastingToCampaignTask {
    return new ConvertCastingToCampaignTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConvertCastingToCampaignTask {
    return new ConvertCastingToCampaignTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConvertCastingToCampaignTask {
    return new ConvertCastingToCampaignTask().fromJsonString(jsonString, options);
  }

  static equals(a: ConvertCastingToCampaignTask | PlainMessage<ConvertCastingToCampaignTask> | undefined, b: ConvertCastingToCampaignTask | PlainMessage<ConvertCastingToCampaignTask> | undefined): boolean {
    return proto3.util.equals(ConvertCastingToCampaignTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.ConvertCastingToCampaignResponse
 */
export class ConvertCastingToCampaignResponse extends Message<ConvertCastingToCampaignResponse> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: int64 campaign_id = 2;
   */
  campaignId = protoInt64.zero;

  constructor(data?: PartialMessage<ConvertCastingToCampaignResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ConvertCastingToCampaignResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConvertCastingToCampaignResponse {
    return new ConvertCastingToCampaignResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConvertCastingToCampaignResponse {
    return new ConvertCastingToCampaignResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConvertCastingToCampaignResponse {
    return new ConvertCastingToCampaignResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ConvertCastingToCampaignResponse | PlainMessage<ConvertCastingToCampaignResponse> | undefined, b: ConvertCastingToCampaignResponse | PlainMessage<ConvertCastingToCampaignResponse> | undefined): boolean {
    return proto3.util.equals(ConvertCastingToCampaignResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.DeleteCollaboratorCastingRequest
 */
export class DeleteCollaboratorCastingRequest extends Message<DeleteCollaboratorCastingRequest> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 collaborator_casting_ids = 2;
   */
  collaboratorCastingIds: bigint[] = [];

  constructor(data?: PartialMessage<DeleteCollaboratorCastingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DeleteCollaboratorCastingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "collaborator_casting_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteCollaboratorCastingRequest {
    return new DeleteCollaboratorCastingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteCollaboratorCastingRequest {
    return new DeleteCollaboratorCastingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteCollaboratorCastingRequest {
    return new DeleteCollaboratorCastingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteCollaboratorCastingRequest | PlainMessage<DeleteCollaboratorCastingRequest> | undefined, b: DeleteCollaboratorCastingRequest | PlainMessage<DeleteCollaboratorCastingRequest> | undefined): boolean {
    return proto3.util.equals(DeleteCollaboratorCastingRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ResendEmailToCollaboratorsRequest
 */
export class ResendEmailToCollaboratorsRequest extends Message<ResendEmailToCollaboratorsRequest> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 collaborator_casting_id = 2;
   */
  collaboratorCastingId: bigint[] = [];

  constructor(data?: PartialMessage<ResendEmailToCollaboratorsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ResendEmailToCollaboratorsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "collaborator_casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResendEmailToCollaboratorsRequest {
    return new ResendEmailToCollaboratorsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResendEmailToCollaboratorsRequest {
    return new ResendEmailToCollaboratorsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResendEmailToCollaboratorsRequest {
    return new ResendEmailToCollaboratorsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResendEmailToCollaboratorsRequest | PlainMessage<ResendEmailToCollaboratorsRequest> | undefined, b: ResendEmailToCollaboratorsRequest | PlainMessage<ResendEmailToCollaboratorsRequest> | undefined): boolean {
    return proto3.util.equals(ResendEmailToCollaboratorsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CastingExportReq
 */
export class CastingExportReq extends Message<CastingExportReq> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * Filled by backend
   *
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<CastingExportReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CastingExportReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CastingExportReq {
    return new CastingExportReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CastingExportReq {
    return new CastingExportReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CastingExportReq {
    return new CastingExportReq().fromJsonString(jsonString, options);
  }

  static equals(a: CastingExportReq | PlainMessage<CastingExportReq> | undefined, b: CastingExportReq | PlainMessage<CastingExportReq> | undefined): boolean {
    return proto3.util.equals(CastingExportReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.InfluencerExpectedPosts
 */
export class InfluencerExpectedPosts extends Message<InfluencerExpectedPosts> {
  /**
   * @generated from oneof librarian.proto.InfluencerExpectedPosts.post_types_values
   */
  postTypesValues: {
    /**
     * If network is folded
     *
     * @generated from field: common.proto.Network network = 1;
     */
    value: Network;
    case: "network";
  } | {
    /**
     * @generated from field: common.proto.PostType post_type = 2;
     */
    value: PostType;
    case: "postType";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: int32 value = 4;
   */
  value = 0;

  /**
   * @generated from field: google.protobuf.DoubleValue cost = 5;
   */
  cost?: number;

  constructor(data?: PartialMessage<InfluencerExpectedPosts>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InfluencerExpectedPosts";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network), oneof: "post_types_values" },
    { no: 2, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType), oneof: "post_types_values" },
    { no: 4, name: "value", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "cost", kind: "message", T: DoubleValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerExpectedPosts {
    return new InfluencerExpectedPosts().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerExpectedPosts {
    return new InfluencerExpectedPosts().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerExpectedPosts {
    return new InfluencerExpectedPosts().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerExpectedPosts | PlainMessage<InfluencerExpectedPosts> | undefined, b: InfluencerExpectedPosts | PlainMessage<InfluencerExpectedPosts> | undefined): boolean {
    return proto3.util.equals(InfluencerExpectedPosts, a, b);
  }
}

/**
 * Forecasting feature
 *
 * @generated from message librarian.proto.UpdateInfluencersExpectedPostsReq
 */
export class UpdateInfluencersExpectedPostsReq extends Message<UpdateInfluencersExpectedPostsReq> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.FlexibleCreatorSelector selector = 5;
   */
  selector?: FlexibleCreatorSelector;

  /**
   * @generated from field: repeated librarian.proto.InfluencerExpectedPosts expected_posts = 3;
   */
  expectedPosts: InfluencerExpectedPosts[] = [];

  /**
   * If cost_type = INFLUENCER_CASTING_COST_TYPE_FIXED_COST then set the fixed cost in fixed_cost field
   * If cost_type = INFLUENCER_CASTING_COST_TYPE_COST_PER_POST then set the costs of each post from expected_posts
   *
   * @generated from field: librarian.proto.InfluencerCastingCostType cost_type = 6;
   */
  costType = InfluencerCastingCostType.UNKNOWN;

  /**
   * @generated from field: google.protobuf.DoubleValue fixed_cost = 7;
   */
  fixedCost?: number;

  constructor(data?: PartialMessage<UpdateInfluencersExpectedPostsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateInfluencersExpectedPostsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "selector", kind: "message", T: FlexibleCreatorSelector },
    { no: 3, name: "expected_posts", kind: "message", T: InfluencerExpectedPosts, repeated: true },
    { no: 6, name: "cost_type", kind: "enum", T: proto3.getEnumType(InfluencerCastingCostType) },
    { no: 7, name: "fixed_cost", kind: "message", T: DoubleValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateInfluencersExpectedPostsReq {
    return new UpdateInfluencersExpectedPostsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateInfluencersExpectedPostsReq {
    return new UpdateInfluencersExpectedPostsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateInfluencersExpectedPostsReq {
    return new UpdateInfluencersExpectedPostsReq().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateInfluencersExpectedPostsReq | PlainMessage<UpdateInfluencersExpectedPostsReq> | undefined, b: UpdateInfluencersExpectedPostsReq | PlainMessage<UpdateInfluencersExpectedPostsReq> | undefined): boolean {
    return proto3.util.equals(UpdateInfluencersExpectedPostsReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetForecastingStatsAndPerformancesResp
 */
export class GetForecastingStatsAndPerformancesResp extends Message<GetForecastingStatsAndPerformancesResp> {
  /**
   * @generated from field: librarian.proto.GetForecastingStatsAndPerformancesResp.ForecastingStats stats = 1;
   */
  stats?: GetForecastingStatsAndPerformancesResp_ForecastingStats;

  /**
   * @generated from field: repeated librarian.proto.GetForecastingStatsAndPerformancesResp.ForecastingNetworkEstimatedPerformances networks_estimated_performances = 2;
   */
  networksEstimatedPerformances: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances[] = [];

  constructor(data?: PartialMessage<GetForecastingStatsAndPerformancesResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetForecastingStatsAndPerformancesResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stats", kind: "message", T: GetForecastingStatsAndPerformancesResp_ForecastingStats },
    { no: 2, name: "networks_estimated_performances", kind: "message", T: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetForecastingStatsAndPerformancesResp {
    return new GetForecastingStatsAndPerformancesResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetForecastingStatsAndPerformancesResp {
    return new GetForecastingStatsAndPerformancesResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetForecastingStatsAndPerformancesResp {
    return new GetForecastingStatsAndPerformancesResp().fromJsonString(jsonString, options);
  }

  static equals(a: GetForecastingStatsAndPerformancesResp | PlainMessage<GetForecastingStatsAndPerformancesResp> | undefined, b: GetForecastingStatsAndPerformancesResp | PlainMessage<GetForecastingStatsAndPerformancesResp> | undefined): boolean {
    return proto3.util.equals(GetForecastingStatsAndPerformancesResp, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetForecastingStatsAndPerformancesResp.ForecastingStats
 */
export class GetForecastingStatsAndPerformancesResp_ForecastingStats extends Message<GetForecastingStatsAndPerformancesResp_ForecastingStats> {
  /**
   * @generated from field: int32 influencers_count = 1;
   */
  influencersCount = 0;

  /**
   * @generated from field: int32 expected_posts_count = 2;
   */
  expectedPostsCount = 0;

  /**
   * @generated from field: int64 impressions = 3;
   */
  impressions = protoInt64.zero;

  /**
   * @generated from field: int64 engagements = 4;
   */
  engagements = protoInt64.zero;

  /**
   * @generated from field: int64 reach = 5;
   */
  reach = protoInt64.zero;

  /**
   * @generated from field: int64 emv = 6;
   */
  emv = protoInt64.zero;

  /**
   * @generated from field: int64 costs = 7;
   */
  costs = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.FloatValue roi = 8;
   */
  roi?: number;

  /**
   * @generated from field: google.protobuf.FloatValue calculated_cpm = 9;
   */
  calculatedCpm?: number;

  /**
   * @generated from field: google.protobuf.FloatValue cpe = 10;
   */
  cpe?: number;

  constructor(data?: PartialMessage<GetForecastingStatsAndPerformancesResp_ForecastingStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetForecastingStatsAndPerformancesResp.ForecastingStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencers_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "expected_posts_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "impressions", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "engagements", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "reach", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "emv", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "costs", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "roi", kind: "message", T: FloatValue },
    { no: 9, name: "calculated_cpm", kind: "message", T: FloatValue },
    { no: 10, name: "cpe", kind: "message", T: FloatValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetForecastingStatsAndPerformancesResp_ForecastingStats {
    return new GetForecastingStatsAndPerformancesResp_ForecastingStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetForecastingStatsAndPerformancesResp_ForecastingStats {
    return new GetForecastingStatsAndPerformancesResp_ForecastingStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetForecastingStatsAndPerformancesResp_ForecastingStats {
    return new GetForecastingStatsAndPerformancesResp_ForecastingStats().fromJsonString(jsonString, options);
  }

  static equals(a: GetForecastingStatsAndPerformancesResp_ForecastingStats | PlainMessage<GetForecastingStatsAndPerformancesResp_ForecastingStats> | undefined, b: GetForecastingStatsAndPerformancesResp_ForecastingStats | PlainMessage<GetForecastingStatsAndPerformancesResp_ForecastingStats> | undefined): boolean {
    return proto3.util.equals(GetForecastingStatsAndPerformancesResp_ForecastingStats, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetForecastingStatsAndPerformancesResp.ForecastingNetworkEstimatedPerformances
 */
export class GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances extends Message<GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: int64 likes = 2;
   */
  likes = protoInt64.zero;

  /**
   * @generated from field: int64 comments = 3;
   */
  comments = protoInt64.zero;

  /**
   * @generated from field: int64 impressions = 4;
   */
  impressions = protoInt64.zero;

  /**
   * @generated from field: int64 emv = 5;
   */
  emv = protoInt64.zero;

  /**
   * @generated from field: float engagement_rate = 6;
   */
  engagementRate = 0;

  /**
   * @generated from field: int64 costs = 7;
   */
  costs = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.FloatValue roi = 8;
   */
  roi?: number;

  /**
   * @generated from field: google.protobuf.FloatValue calculated_cpm = 9;
   */
  calculatedCpm?: number;

  /**
   * @generated from field: google.protobuf.FloatValue calculated_cpe = 10;
   */
  calculatedCpe?: number;

  constructor(data?: PartialMessage<GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetForecastingStatsAndPerformancesResp.ForecastingNetworkEstimatedPerformances";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "likes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "comments", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "impressions", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "emv", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "engagement_rate", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "costs", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "roi", kind: "message", T: FloatValue },
    { no: 9, name: "calculated_cpm", kind: "message", T: FloatValue },
    { no: 10, name: "calculated_cpe", kind: "message", T: FloatValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances {
    return new GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances {
    return new GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances {
    return new GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances().fromJsonString(jsonString, options);
  }

  static equals(a: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances | PlainMessage<GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances> | undefined, b: GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances | PlainMessage<GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances> | undefined): boolean {
    return proto3.util.equals(GetForecastingStatsAndPerformancesResp_ForecastingNetworkEstimatedPerformances, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetEstimatedAudienceReq
 */
export class GetEstimatedAudienceReq extends Message<GetEstimatedAudienceReq> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * @generated from field: common.proto.Network network = 2;
   */
  network = Network.NETWORK_UNKNOWN;

  constructor(data?: PartialMessage<GetEstimatedAudienceReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetEstimatedAudienceReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEstimatedAudienceReq {
    return new GetEstimatedAudienceReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEstimatedAudienceReq {
    return new GetEstimatedAudienceReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEstimatedAudienceReq {
    return new GetEstimatedAudienceReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetEstimatedAudienceReq | PlainMessage<GetEstimatedAudienceReq> | undefined, b: GetEstimatedAudienceReq | PlainMessage<GetEstimatedAudienceReq> | undefined): boolean {
    return proto3.util.equals(GetEstimatedAudienceReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.ForecastingExportReq
 */
export class ForecastingExportReq extends Message<ForecastingExportReq> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * Filled by backend for RPC calls between librarian and archivist
   *
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<ForecastingExportReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ForecastingExportReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ForecastingExportReq {
    return new ForecastingExportReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ForecastingExportReq {
    return new ForecastingExportReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ForecastingExportReq {
    return new ForecastingExportReq().fromJsonString(jsonString, options);
  }

  static equals(a: ForecastingExportReq | PlainMessage<ForecastingExportReq> | undefined, b: ForecastingExportReq | PlainMessage<ForecastingExportReq> | undefined): boolean {
    return proto3.util.equals(ForecastingExportReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.GenerateCastingPDFReq
 */
export class GenerateCastingPDFReq extends Message<GenerateCastingPDFReq> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.CollaboratorCastingInfluencersFilter filters = 2;
   */
  filters?: CollaboratorCastingInfluencersFilter;

  constructor(data?: PartialMessage<GenerateCastingPDFReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GenerateCastingPDFReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "filters", kind: "message", T: CollaboratorCastingInfluencersFilter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateCastingPDFReq {
    return new GenerateCastingPDFReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateCastingPDFReq {
    return new GenerateCastingPDFReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateCastingPDFReq {
    return new GenerateCastingPDFReq().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateCastingPDFReq | PlainMessage<GenerateCastingPDFReq> | undefined, b: GenerateCastingPDFReq | PlainMessage<GenerateCastingPDFReq> | undefined): boolean {
    return proto3.util.equals(GenerateCastingPDFReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.GenerateCastingPPTReq
 */
export class GenerateCastingPPTReq extends Message<GenerateCastingPPTReq> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.CollaboratorCastingInfluencersFilter filters = 2;
   */
  filters?: CollaboratorCastingInfluencersFilter;

  constructor(data?: PartialMessage<GenerateCastingPPTReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GenerateCastingPPTReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "filters", kind: "message", T: CollaboratorCastingInfluencersFilter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateCastingPPTReq {
    return new GenerateCastingPPTReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateCastingPPTReq {
    return new GenerateCastingPPTReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateCastingPPTReq {
    return new GenerateCastingPPTReq().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateCastingPPTReq | PlainMessage<GenerateCastingPPTReq> | undefined, b: GenerateCastingPPTReq | PlainMessage<GenerateCastingPPTReq> | undefined): boolean {
    return proto3.util.equals(GenerateCastingPPTReq, a, b);
  }
}

