<lefty-card [withBorder]="true">
  <div class="header">
    <h3 i18n>Estimated Audience</h3>

    <network-form-select
      [(value)]="selectedNetwork"
      [networks]="networks()"
      label=""
    ></network-form-select>
  </div>
  @if (isLoading()) {
    <div class="spinner-container">
      <lefty-spinner></lefty-spinner>
    </div>
  } @else if (isEmptyState()) {
    <div
      class="empty-state"
      i18n
    >
      No data available
    </div>
  } @else {
    <div class="content">
      <report-audience-age
        class="col-left"
        [report]="report()"
        [isMobileLayout]="isMobileLayout()"
      ></report-audience-age>
      <report-audience-country
        class="col-right"
        [report]="geographyReports()"
      ></report-audience-country>
    </div>
  }
</lefty-card>
