// This file defines common protobufs that are useful to more than one component
// in librarian.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/common.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { BoolValue, DoubleValue, Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { AuthorPermission, BasicPagination, DoubleRange, GenerikKeyword, InfluencerKind, Int64List, Int64Range, Interval, LogicOperator, Network, NetworkEngagementRangeV2, NetworkFollowersRange, PostType, SortDirection, StringList, UTMParameterList } from "../../common/proto/common_pb";
import { GoogleLocation } from "./local_pb";
import { CampaignKeyword, UserStatus } from "../../common/proto/campaign_pb";

/**
 * @generated from enum librarian.proto.SortType
 */
export enum SortType {
  /**
   * show more recent images first.
   *
   * @generated from enum value: RECENT = 0;
   */
  RECENT = 0,

  /**
   * show more popular images first.
   *
   * @generated from enum value: POPULAR = 1;
   */
  POPULAR = 1,

  /**
   * sort images from influent authors first.
   *
   * @generated from enum value: INFLUENCERS = 3;
   */
  INFLUENCERS = 3,

  /**
   * sort by relevance
   *
   * @generated from enum value: RELEVANCE = 4;
   */
  RELEVANCE = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(SortType)
proto3.util.setEnumType(SortType, "librarian.proto.SortType", [
  { no: 0, name: "RECENT" },
  { no: 1, name: "POPULAR" },
  { no: 3, name: "INFLUENCERS" },
  { no: 4, name: "RELEVANCE" },
]);

/**
 * @generated from enum librarian.proto.Spam
 */
export enum Spam {
  /**
   * @generated from enum value: DISPLAY = 0;
   */
  DISPLAY = 0,

  /**
   * @generated from enum value: HIDE = 1;
   */
  HIDE = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(Spam)
proto3.util.setEnumType(Spam, "librarian.proto.Spam", [
  { no: 0, name: "DISPLAY" },
  { no: 1, name: "HIDE" },
]);

/**
 * @generated from enum librarian.proto.UserSort
 */
export enum UserSort {
  /**
   * @generated from enum value: DEFAULT = 0;
   */
  DEFAULT = 0,

  /**
   * @generated from enum value: FOLLOWERS = 1;
   */
  FOLLOWERS = 1,

  /**
   * @generated from enum value: RELEVANT = 2;
   */
  RELEVANT = 2,

  /**
   * @generated from enum value: ENGAGEMENT = 4;
   */
  ENGAGEMENT = 4,

  /**
   * @generated from enum value: PAGERANK = 5;
   */
  PAGERANK = 5,

  /**
   * @generated from enum value: RANDOM = 6;
   */
  RANDOM = 6,

  /**
   * @generated from enum value: EMV = 7;
   */
  EMV = 7,

  /**
   * @generated from enum value: DATE_ADDED = 8;
   */
  DATE_ADDED = 8,

  /**
   * @generated from enum value: POST_COUNT = 9;
   */
  POST_COUNT = 9,

  /**
   * @generated from enum value: REACH = 10;
   */
  REACH = 10,

  /**
   * @generated from enum value: IMPRESSIONS = 11;
   */
  IMPRESSIONS = 11,

  /**
   * @generated from enum value: USERNAME = 12;
   */
  USERNAME = 12,

  /**
   * @generated from enum value: RFM_SCORE = 13;
   */
  RFM_SCORE = 13,
}
// Retrieve enum metadata with: proto3.getEnumType(UserSort)
proto3.util.setEnumType(UserSort, "librarian.proto.UserSort", [
  { no: 0, name: "DEFAULT" },
  { no: 1, name: "FOLLOWERS" },
  { no: 2, name: "RELEVANT" },
  { no: 4, name: "ENGAGEMENT" },
  { no: 5, name: "PAGERANK" },
  { no: 6, name: "RANDOM" },
  { no: 7, name: "EMV" },
  { no: 8, name: "DATE_ADDED" },
  { no: 9, name: "POST_COUNT" },
  { no: 10, name: "REACH" },
  { no: 11, name: "IMPRESSIONS" },
  { no: 12, name: "USERNAME" },
  { no: 13, name: "RFM_SCORE" },
]);

/**
 * @generated from enum librarian.proto.ForgotPasswordAuthorType
 */
export enum ForgotPasswordAuthorType {
  /**
   * @generated from enum value: LEFTY_USER = 0;
   */
  LEFTY_USER = 0,

  /**
   * @generated from enum value: INFLUENCER = 1;
   */
  INFLUENCER = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ForgotPasswordAuthorType)
proto3.util.setEnumType(ForgotPasswordAuthorType, "librarian.proto.ForgotPasswordAuthorType", [
  { no: 0, name: "LEFTY_USER" },
  { no: 1, name: "INFLUENCER" },
]);

/**
 * @generated from enum librarian.proto.NativeFieldFilter
 */
export enum NativeFieldFilter {
  /**
   * here we have the common filters in all views
   *
   * @generated from enum value: FILTER_NONE = 0;
   */
  FILTER_NONE = 0,

  /**
   * @generated from enum value: FOLLOWERS_FILTER = 1;
   */
  FOLLOWERS_FILTER = 1,

  /**
   * @generated from enum value: ENGAGEMENT_FILTER = 2;
   */
  ENGAGEMENT_FILTER = 2,

  /**
   * @generated from enum value: CAMPAIGNS_FILTER = 3;
   */
  CAMPAIGNS_FILTER = 3,

  /**
   * @generated from enum value: LABELS_FILTER = 4;
   */
  LABELS_FILTER = 4,

  /**
   * @generated from enum value: CAMPAIGNS_LABELS_FILTER = 25;
   */
  CAMPAIGNS_LABELS_FILTER = 25,

  /**
   * @generated from enum value: VISUALS_LABELS_FILTER = 29;
   */
  VISUALS_LABELS_FILTER = 29,

  /**
   * @generated from enum value: CREATORS_FILTER = 8;
   */
  CREATORS_FILTER = 8,

  /**
   * @generated from enum value: SEARCH_TEXT_FILTER = 9;
   */
  SEARCH_TEXT_FILTER = 9,

  /**
   * @generated from enum value: POST_TYPE_FILTER = 11;
   */
  POST_TYPE_FILTER = 11,

  /**
   * @generated from enum value: DATE_FILTER = 12;
   */
  DATE_FILTER = 12,

  /**
   * these two filters below are dedicated to campaign influencers view
   *
   * @generated from enum value: INFLUENCER_STATE_FILTER = 13;
   */
  INFLUENCER_STATE_FILTER = 13,

  /**
   * @generated from enum value: INFLUENCER_STATUS_FILTER = 14;
   */
  INFLUENCER_STATUS_FILTER = 14,

  /**
   * @generated from enum value: INFLUENCER_KEYWORDS_FILTER = 15;
   */
  INFLUENCER_KEYWORDS_FILTER = 15,

  /**
   * @generated from enum value: INFLUENCER_HASHTAGS_FILTER = 16;
   */
  INFLUENCER_HASHTAGS_FILTER = 16,

  /**
   * @generated from enum value: INFLUENCER_MENTIONS_FILTER = 17;
   */
  INFLUENCER_MENTIONS_FILTER = 17,

  /**
   * @generated from enum value: VISUAL_HASHTAGS_FILTER = 18;
   */
  VISUAL_HASHTAGS_FILTER = 18,

  /**
   * @generated from enum value: VISUAL_MENTIONS_FILTER = 19;
   */
  VISUAL_MENTIONS_FILTER = 19,

  /**
   * @generated from enum value: VISUAL_KEYWORDS_FILTER = 20;
   */
  VISUAL_KEYWORDS_FILTER = 20,

  /**
   * @generated from enum value: VISUAL_LOCATIONS_FILTER = 26;
   */
  VISUAL_LOCATIONS_FILTER = 26,

  /**
   * @generated from enum value: INFLUENCER_NETWORKS_FILTER = 21;
   */
  INFLUENCER_NETWORKS_FILTER = 21,

  /**
   * @generated from enum value: VISUAL_NETWORKS_FILTER = 22;
   */
  VISUAL_NETWORKS_FILTER = 22,

  /**
   * team owner id
   *
   * @generated from enum value: MEDIA_USERS_WITH_PERMISSIONS_FILTER = 23;
   */
  MEDIA_USERS_WITH_PERMISSIONS_FILTER = 23,

  /**
   * @generated from enum value: INFLUENCER_RFM_SCORE_FILTER = 24;
   */
  INFLUENCER_RFM_SCORE_FILTER = 24,

  /**
   * @generated from enum value: INFLUENCER_TRACKED_FILTER = 27;
   */
  INFLUENCER_TRACKED_FILTER = 27,

  /**
   * @generated from enum value: INSTAGRAM_INSIGHTS_FILTER = 28;
   */
  INSTAGRAM_INSIGHTS_FILTER = 28,
}
// Retrieve enum metadata with: proto3.getEnumType(NativeFieldFilter)
proto3.util.setEnumType(NativeFieldFilter, "librarian.proto.NativeFieldFilter", [
  { no: 0, name: "FILTER_NONE" },
  { no: 1, name: "FOLLOWERS_FILTER" },
  { no: 2, name: "ENGAGEMENT_FILTER" },
  { no: 3, name: "CAMPAIGNS_FILTER" },
  { no: 4, name: "LABELS_FILTER" },
  { no: 25, name: "CAMPAIGNS_LABELS_FILTER" },
  { no: 29, name: "VISUALS_LABELS_FILTER" },
  { no: 8, name: "CREATORS_FILTER" },
  { no: 9, name: "SEARCH_TEXT_FILTER" },
  { no: 11, name: "POST_TYPE_FILTER" },
  { no: 12, name: "DATE_FILTER" },
  { no: 13, name: "INFLUENCER_STATE_FILTER" },
  { no: 14, name: "INFLUENCER_STATUS_FILTER" },
  { no: 15, name: "INFLUENCER_KEYWORDS_FILTER" },
  { no: 16, name: "INFLUENCER_HASHTAGS_FILTER" },
  { no: 17, name: "INFLUENCER_MENTIONS_FILTER" },
  { no: 18, name: "VISUAL_HASHTAGS_FILTER" },
  { no: 19, name: "VISUAL_MENTIONS_FILTER" },
  { no: 20, name: "VISUAL_KEYWORDS_FILTER" },
  { no: 26, name: "VISUAL_LOCATIONS_FILTER" },
  { no: 21, name: "INFLUENCER_NETWORKS_FILTER" },
  { no: 22, name: "VISUAL_NETWORKS_FILTER" },
  { no: 23, name: "MEDIA_USERS_WITH_PERMISSIONS_FILTER" },
  { no: 24, name: "INFLUENCER_RFM_SCORE_FILTER" },
  { no: 27, name: "INFLUENCER_TRACKED_FILTER" },
  { no: 28, name: "INSTAGRAM_INSIGHTS_FILTER" },
]);

/**
 * @generated from enum librarian.proto.BaseEntityType
 */
export enum BaseEntityType {
  /**
   * @generated from enum value: BASE_ENTITY_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: BASE_ENTITY_TYPE_CREATOR = 1;
   */
  CREATOR = 1,

  /**
   * @generated from enum value: BASE_ENTITY_TYPE_CAMPAIGN = 2;
   */
  CAMPAIGN = 2,

  /**
   * @generated from enum value: BASE_ENTITY_TYPE_VISUAL = 3;
   */
  VISUAL = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(BaseEntityType)
proto3.util.setEnumType(BaseEntityType, "librarian.proto.BaseEntityType", [
  { no: 0, name: "BASE_ENTITY_TYPE_UNKNOWN" },
  { no: 1, name: "BASE_ENTITY_TYPE_CREATOR" },
  { no: 2, name: "BASE_ENTITY_TYPE_CAMPAIGN" },
  { no: 3, name: "BASE_ENTITY_TYPE_VISUAL" },
]);

/**
 * @generated from enum librarian.proto.CampaignFieldType
 */
export enum CampaignFieldType {
  /**
   * @generated from enum value: CAMPAIGN_FIELD_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: CAMPAIGN_FIELD_TYPE_TEXT = 1;
   */
  TEXT = 1,

  /**
   * @generated from enum value: CAMPAIGN_FIELD_TYPE_LIST = 2;
   */
  LIST = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(CampaignFieldType)
proto3.util.setEnumType(CampaignFieldType, "librarian.proto.CampaignFieldType", [
  { no: 0, name: "CAMPAIGN_FIELD_TYPE_UNKNOWN" },
  { no: 1, name: "CAMPAIGN_FIELD_TYPE_TEXT" },
  { no: 2, name: "CAMPAIGN_FIELD_TYPE_LIST" },
]);

/**
 * These fields match the mapping of pool.json and may NOT be renamed.
 *
 * @generated from enum librarian.proto.CreatorFieldType
 */
export enum CreatorFieldType {
  /**
   * @generated from enum value: CREATOR_FIELD_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: CREATOR_FIELD_TYPE_TEXT = 1;
   */
  TEXT = 1,

  /**
   * @generated from enum value: CREATOR_FIELD_TYPE_NUMBER = 2;
   */
  NUMBER = 2,

  /**
   * @generated from enum value: CREATOR_FIELD_TYPE_DECIMAL_NUMBER = 3;
   */
  DECIMAL_NUMBER = 3,

  /**
   * @generated from enum value: CREATOR_FIELD_TYPE_BOOLEAN = 4;
   */
  BOOLEAN = 4,

  /**
   * @generated from enum value: CREATOR_FIELD_TYPE_DATE = 5;
   */
  DATE = 5,

  /**
   * @generated from enum value: CREATOR_FIELD_TYPE_LOCATION = 6;
   */
  LOCATION = 6,

  /**
   * @generated from enum value: CREATOR_FIELD_TYPE_ADDRESS = 10;
   */
  ADDRESS = 10,

  /**
   * @generated from enum value: CREATOR_FIELD_TYPE_LIST = 8;
   */
  LIST = 8,

  /**
   * used for the influencers notes
   *
   * @generated from enum value: CREATOR_FIELD_TYPE_STRING_LIST = 9;
   */
  STRING_LIST = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(CreatorFieldType)
proto3.util.setEnumType(CreatorFieldType, "librarian.proto.CreatorFieldType", [
  { no: 0, name: "CREATOR_FIELD_TYPE_UNKNOWN" },
  { no: 1, name: "CREATOR_FIELD_TYPE_TEXT" },
  { no: 2, name: "CREATOR_FIELD_TYPE_NUMBER" },
  { no: 3, name: "CREATOR_FIELD_TYPE_DECIMAL_NUMBER" },
  { no: 4, name: "CREATOR_FIELD_TYPE_BOOLEAN" },
  { no: 5, name: "CREATOR_FIELD_TYPE_DATE" },
  { no: 6, name: "CREATOR_FIELD_TYPE_LOCATION" },
  { no: 10, name: "CREATOR_FIELD_TYPE_ADDRESS" },
  { no: 8, name: "CREATOR_FIELD_TYPE_LIST" },
  { no: 9, name: "CREATOR_FIELD_TYPE_STRING_LIST" },
]);

/**
 * @generated from enum librarian.proto.CastingInfluencerSort
 */
export enum CastingInfluencerSort {
  /**
   * @generated from enum value: NAME_INFLUENCER = 0;
   */
  NAME_INFLUENCER = 0,

  /**
   * @generated from enum value: VOTES_COUNT = 2;
   */
  VOTES_COUNT = 2,

  /**
   * @generated from enum value: APPROVED_VOTE_COUNT = 3;
   */
  APPROVED_VOTE_COUNT = 3,

  /**
   * @generated from enum value: REJECTED_VOTE_COUNT = 4;
   */
  REJECTED_VOTE_COUNT = 4,

  /**
   * @generated from enum value: PENDING_VOTE_COUNT = 5;
   */
  PENDING_VOTE_COUNT = 5,

  /**
   * @generated from enum value: NOTE_COUNT = 6;
   */
  NOTE_COUNT = 6,

  /**
   * @generated from enum value: ADDED_AT = 7;
   */
  ADDED_AT = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(CastingInfluencerSort)
proto3.util.setEnumType(CastingInfluencerSort, "librarian.proto.CastingInfluencerSort", [
  { no: 0, name: "NAME_INFLUENCER" },
  { no: 2, name: "VOTES_COUNT" },
  { no: 3, name: "APPROVED_VOTE_COUNT" },
  { no: 4, name: "REJECTED_VOTE_COUNT" },
  { no: 5, name: "PENDING_VOTE_COUNT" },
  { no: 6, name: "NOTE_COUNT" },
  { no: 7, name: "ADDED_AT" },
]);

/**
 * @generated from enum librarian.proto.InfluencerCastingStatus
 */
export enum InfluencerCastingStatus {
  /**
   * @generated from enum value: INFLUENCER_CASTING_PENDING = 0;
   */
  INFLUENCER_CASTING_PENDING = 0,

  /**
   * @generated from enum value: INFLUENCER_CASTING_APPROVED = 1;
   */
  INFLUENCER_CASTING_APPROVED = 1,

  /**
   * @generated from enum value: INFLUENCER_CASTING_REJECTED = 2;
   */
  INFLUENCER_CASTING_REJECTED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(InfluencerCastingStatus)
proto3.util.setEnumType(InfluencerCastingStatus, "librarian.proto.InfluencerCastingStatus", [
  { no: 0, name: "INFLUENCER_CASTING_PENDING" },
  { no: 1, name: "INFLUENCER_CASTING_APPROVED" },
  { no: 2, name: "INFLUENCER_CASTING_REJECTED" },
]);

/**
 * @generated from enum librarian.proto.VisualSort
 */
export enum VisualSort {
  /**
   * @generated from enum value: NO_SORT = 0;
   */
  NO_SORT = 0,

  /**
   * Sort by creation date
   *
   * @generated from enum value: DATE = 1;
   */
  DATE = 1,

  /**
   * Sort by likes
   *
   * @generated from enum value: LIKES = 2;
   */
  LIKES = 2,

  /**
   * TODO(mateo) delete it, or wire it to engagements sum (that we don't have so far)
   *
   * @generated from enum value: ENGMNT = 3;
   */
  ENGMNT = 3,

  /**
   * @generated from enum value: VISUAL_ENGAGEMENT_RATE_SORT = 8;
   */
  VISUAL_ENGAGEMENT_RATE_SORT = 8,

  /**
   * Sort by video views (only youtube).
   *
   * @generated from enum value: VIEWS = 4;
   */
  VIEWS = 4,

  /**
   * Sort by retweets (only twitter).
   *
   * @generated from enum value: RETWEET = 5;
   */
  RETWEET = 5,

  /**
   * @generated from enum value: VISUAL_EMV_SORT = 6;
   */
  VISUAL_EMV_SORT = 6,

  /**
   * @generated from enum value: VISUAL_IMPRESSIONS_SORT = 7;
   */
  VISUAL_IMPRESSIONS_SORT = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(VisualSort)
proto3.util.setEnumType(VisualSort, "librarian.proto.VisualSort", [
  { no: 0, name: "NO_SORT" },
  { no: 1, name: "DATE" },
  { no: 2, name: "LIKES" },
  { no: 3, name: "ENGMNT" },
  { no: 8, name: "VISUAL_ENGAGEMENT_RATE_SORT" },
  { no: 4, name: "VIEWS" },
  { no: 5, name: "RETWEET" },
  { no: 6, name: "VISUAL_EMV_SORT" },
  { no: 7, name: "VISUAL_IMPRESSIONS_SORT" },
]);

/**
 * @generated from enum librarian.proto.Feature
 */
export enum Feature {
  /**
   * @generated from enum value: UNKNOWN_FEATURE = 0;
   */
  UNKNOWN_FEATURE = 0,

  /**
   * @generated from enum value: DIRECTORY_FEATURE = 1;
   */
  DIRECTORY_FEATURE = 1,

  /**
   * @generated from enum value: CAMPAIGNS_FEATURE = 2;
   */
  CAMPAIGNS_FEATURE = 2,

  /**
   * @generated from enum value: AFFILIATION_FEATURE = 3;
   */
  AFFILIATION_FEATURE = 3,

  /**
   * @generated from enum value: ANALYTICS_FEATURE = 4;
   */
  ANALYTICS_FEATURE = 4,

  /**
   * @generated from enum value: DISCOVER_FEATURE = 5;
   */
  DISCOVER_FEATURE = 5,

  /**
   * @generated from enum value: LANDING_PAGE_FEATURE = 6;
   */
  LANDING_PAGE_FEATURE = 6,

  /**
   * @generated from enum value: CASTING_FEATURE = 7;
   */
  CASTING_FEATURE = 7,

  /**
   * @generated from enum value: MESSAGES_FEATURE = 8;
   */
  MESSAGES_FEATURE = 8,

  /**
   * @generated from enum value: PAYMENTS_FEATURE = 9;
   */
  PAYMENTS_FEATURE = 9,

  /**
   * @generated from enum value: PRODUCTS_SEEDING_FEATURE = 10;
   */
  PRODUCTS_SEEDING_FEATURE = 10,

  /**
   * @generated from enum value: HOME_DASHBOARD_FEATURE = 11;
   */
  HOME_DASHBOARD_FEATURE = 11,

  /**
   * @generated from enum value: BRAND_SAFETY_FEATURE = 12;
   */
  BRAND_SAFETY_FEATURE = 12,

  /**
   * @generated from enum value: PROMO_CODE_FEATURE = 13;
   */
  PROMO_CODE_FEATURE = 13,
}
// Retrieve enum metadata with: proto3.getEnumType(Feature)
proto3.util.setEnumType(Feature, "librarian.proto.Feature", [
  { no: 0, name: "UNKNOWN_FEATURE" },
  { no: 1, name: "DIRECTORY_FEATURE" },
  { no: 2, name: "CAMPAIGNS_FEATURE" },
  { no: 3, name: "AFFILIATION_FEATURE" },
  { no: 4, name: "ANALYTICS_FEATURE" },
  { no: 5, name: "DISCOVER_FEATURE" },
  { no: 6, name: "LANDING_PAGE_FEATURE" },
  { no: 7, name: "CASTING_FEATURE" },
  { no: 8, name: "MESSAGES_FEATURE" },
  { no: 9, name: "PAYMENTS_FEATURE" },
  { no: 10, name: "PRODUCTS_SEEDING_FEATURE" },
  { no: 11, name: "HOME_DASHBOARD_FEATURE" },
  { no: 12, name: "BRAND_SAFETY_FEATURE" },
  { no: 13, name: "PROMO_CODE_FEATURE" },
]);

/**
 * @generated from enum librarian.proto.UserSettingKind
 */
export enum UserSettingKind {
  /**
   * @generated from enum value: UNKNOWN_SETTING = 0;
   */
  UNKNOWN_SETTING = 0,

  /**
   * @generated from enum value: CAMPAIGN_CREATION_WORKFLOW_SETTING = 1;
   */
  CAMPAIGN_CREATION_WORKFLOW_SETTING = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(UserSettingKind)
proto3.util.setEnumType(UserSettingKind, "librarian.proto.UserSettingKind", [
  { no: 0, name: "UNKNOWN_SETTING" },
  { no: 1, name: "CAMPAIGN_CREATION_WORKFLOW_SETTING" },
]);

/**
 * @generated from message librarian.proto.Note
 */
export class Note extends Message<Note> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: repeated string labels = 2;
   */
  labels: string[] = [];

  /**
   * @generated from field: string content = 3;
   */
  content = "";

  /**
   * This field shall not be set during an update (it comes from the logged in user).
   *
   * @generated from field: librarian.proto.Note.NoteAuthor user = 4;
   */
  user?: Note_NoteAuthor;

  /**
   * @generated from field: google.protobuf.Timestamp updated = 5;
   */
  updated?: Timestamp;

  constructor(data?: PartialMessage<Note>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Note";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "labels", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "user", kind: "message", T: Note_NoteAuthor },
    { no: 5, name: "updated", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Note {
    return new Note().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Note {
    return new Note().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Note {
    return new Note().fromJsonString(jsonString, options);
  }

  static equals(a: Note | PlainMessage<Note> | undefined, b: Note | PlainMessage<Note> | undefined): boolean {
    return proto3.util.equals(Note, a, b);
  }
}

/**
 * @generated from message librarian.proto.Note.NoteAuthor
 */
export class Note_NoteAuthor extends Message<Note_NoteAuthor> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 2;
   */
  lastName = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  constructor(data?: PartialMessage<Note_NoteAuthor>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Note.NoteAuthor";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Note_NoteAuthor {
    return new Note_NoteAuthor().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Note_NoteAuthor {
    return new Note_NoteAuthor().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Note_NoteAuthor {
    return new Note_NoteAuthor().fromJsonString(jsonString, options);
  }

  static equals(a: Note_NoteAuthor | PlainMessage<Note_NoteAuthor> | undefined, b: Note_NoteAuthor | PlainMessage<Note_NoteAuthor> | undefined): boolean {
    return proto3.util.equals(Note_NoteAuthor, a, b);
  }
}

/**
 * @generated from message librarian.proto.Notes
 */
export class Notes extends Message<Notes> {
  /**
   * @generated from field: repeated librarian.proto.Note notes = 1;
   */
  notes: Note[] = [];

  constructor(data?: PartialMessage<Notes>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Notes";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "notes", kind: "message", T: Note, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Notes {
    return new Notes().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Notes {
    return new Notes().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Notes {
    return new Notes().fromJsonString(jsonString, options);
  }

  static equals(a: Notes | PlainMessage<Notes> | undefined, b: Notes | PlainMessage<Notes> | undefined): boolean {
    return proto3.util.equals(Notes, a, b);
  }
}

/**
 * Message set to restrict a search between 2 points in time.
 * If both 'start' and 'end' are set, the search is meant to return only results
 * within that timestamp.
 * If 'start' is not set we search all assets until 'end'
 * If 'end' is not set we search all assets after 'start' and until now.
 *
 * @generated from message librarian.proto.TimeRestriction
 */
export class TimeRestriction extends Message<TimeRestriction> {
  /**
   * @generated from field: google.protobuf.Timestamp start = 1;
   */
  start?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end = 2;
   */
  end?: Timestamp;

  constructor(data?: PartialMessage<TimeRestriction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.TimeRestriction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start", kind: "message", T: Timestamp },
    { no: 2, name: "end", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TimeRestriction {
    return new TimeRestriction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TimeRestriction {
    return new TimeRestriction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TimeRestriction {
    return new TimeRestriction().fromJsonString(jsonString, options);
  }

  static equals(a: TimeRestriction | PlainMessage<TimeRestriction> | undefined, b: TimeRestriction | PlainMessage<TimeRestriction> | undefined): boolean {
    return proto3.util.equals(TimeRestriction, a, b);
  }
}

/**
 * @generated from message librarian.proto.TimeRestrictions
 */
export class TimeRestrictions extends Message<TimeRestrictions> {
  /**
   * @generated from field: librarian.proto.TimeRestriction current = 1;
   */
  current?: TimeRestriction;

  /**
   * @generated from field: librarian.proto.TimeRestriction previous = 2;
   */
  previous?: TimeRestriction;

  constructor(data?: PartialMessage<TimeRestrictions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.TimeRestrictions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "current", kind: "message", T: TimeRestriction },
    { no: 2, name: "previous", kind: "message", T: TimeRestriction },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TimeRestrictions {
    return new TimeRestrictions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TimeRestrictions {
    return new TimeRestrictions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TimeRestrictions {
    return new TimeRestrictions().fromJsonString(jsonString, options);
  }

  static equals(a: TimeRestrictions | PlainMessage<TimeRestrictions> | undefined, b: TimeRestrictions | PlainMessage<TimeRestrictions> | undefined): boolean {
    return proto3.util.equals(TimeRestrictions, a, b);
  }
}

/**
 * @generated from message librarian.proto.DataPeriodEvolution
 */
export class DataPeriodEvolution extends Message<DataPeriodEvolution> {
  /**
   * current period data value
   *
   * @generated from field: float value = 1;
   */
  value = 0;

  /**
   * evolution between current and previous period, percentage from 0 to 1
   *
   * @generated from field: google.protobuf.DoubleValue evolution_percentage = 2;
   */
  evolutionPercentage?: number;

  constructor(data?: PartialMessage<DataPeriodEvolution>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DataPeriodEvolution";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "evolution_percentage", kind: "message", T: DoubleValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DataPeriodEvolution {
    return new DataPeriodEvolution().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DataPeriodEvolution {
    return new DataPeriodEvolution().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DataPeriodEvolution {
    return new DataPeriodEvolution().fromJsonString(jsonString, options);
  }

  static equals(a: DataPeriodEvolution | PlainMessage<DataPeriodEvolution> | undefined, b: DataPeriodEvolution | PlainMessage<DataPeriodEvolution> | undefined): boolean {
    return proto3.util.equals(DataPeriodEvolution, a, b);
  }
}

/**
 * @generated from message librarian.proto.Timeseries
 */
export class Timeseries extends Message<Timeseries> {
  /**
   * @generated from field: repeated librarian.proto.Timeseries.TimePoint time_point = 1;
   */
  timePoint: Timeseries_TimePoint[] = [];

  constructor(data?: PartialMessage<Timeseries>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Timeseries";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time_point", kind: "message", T: Timeseries_TimePoint, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Timeseries {
    return new Timeseries().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Timeseries {
    return new Timeseries().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Timeseries {
    return new Timeseries().fromJsonString(jsonString, options);
  }

  static equals(a: Timeseries | PlainMessage<Timeseries> | undefined, b: Timeseries | PlainMessage<Timeseries> | undefined): boolean {
    return proto3.util.equals(Timeseries, a, b);
  }
}

/**
 * @generated from message librarian.proto.Timeseries.TimePoint
 */
export class Timeseries_TimePoint extends Message<Timeseries_TimePoint> {
  /**
   * @generated from field: google.protobuf.Timestamp time = 1;
   */
  time?: Timestamp;

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0;

  constructor(data?: PartialMessage<Timeseries_TimePoint>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Timeseries.TimePoint";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time", kind: "message", T: Timestamp },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Timeseries_TimePoint {
    return new Timeseries_TimePoint().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Timeseries_TimePoint {
    return new Timeseries_TimePoint().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Timeseries_TimePoint {
    return new Timeseries_TimePoint().fromJsonString(jsonString, options);
  }

  static equals(a: Timeseries_TimePoint | PlainMessage<Timeseries_TimePoint> | undefined, b: Timeseries_TimePoint | PlainMessage<Timeseries_TimePoint> | undefined): boolean {
    return proto3.util.equals(Timeseries_TimePoint, a, b);
  }
}

/**
 * @generated from message librarian.proto.PostsPerNetworkCount
 */
export class PostsPerNetworkCount extends Message<PostsPerNetworkCount> {
  /**
   * @generated from field: repeated librarian.proto.PostsPerNetworkCount.NetworkPostsCount network_posts_count = 1;
   */
  networkPostsCount: PostsPerNetworkCount_NetworkPostsCount[] = [];

  constructor(data?: PartialMessage<PostsPerNetworkCount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PostsPerNetworkCount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network_posts_count", kind: "message", T: PostsPerNetworkCount_NetworkPostsCount, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostsPerNetworkCount {
    return new PostsPerNetworkCount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostsPerNetworkCount {
    return new PostsPerNetworkCount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostsPerNetworkCount {
    return new PostsPerNetworkCount().fromJsonString(jsonString, options);
  }

  static equals(a: PostsPerNetworkCount | PlainMessage<PostsPerNetworkCount> | undefined, b: PostsPerNetworkCount | PlainMessage<PostsPerNetworkCount> | undefined): boolean {
    return proto3.util.equals(PostsPerNetworkCount, a, b);
  }
}

/**
 * @generated from message librarian.proto.PostsPerNetworkCount.NetworkPostsCount
 */
export class PostsPerNetworkCount_NetworkPostsCount extends Message<PostsPerNetworkCount_NetworkPostsCount> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: int32 posts_count = 2;
   */
  postsCount = 0;

  constructor(data?: PartialMessage<PostsPerNetworkCount_NetworkPostsCount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PostsPerNetworkCount.NetworkPostsCount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "posts_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostsPerNetworkCount_NetworkPostsCount {
    return new PostsPerNetworkCount_NetworkPostsCount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostsPerNetworkCount_NetworkPostsCount {
    return new PostsPerNetworkCount_NetworkPostsCount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostsPerNetworkCount_NetworkPostsCount {
    return new PostsPerNetworkCount_NetworkPostsCount().fromJsonString(jsonString, options);
  }

  static equals(a: PostsPerNetworkCount_NetworkPostsCount | PlainMessage<PostsPerNetworkCount_NetworkPostsCount> | undefined, b: PostsPerNetworkCount_NetworkPostsCount | PlainMessage<PostsPerNetworkCount_NetworkPostsCount> | undefined): boolean {
    return proto3.util.equals(PostsPerNetworkCount_NetworkPostsCount, a, b);
  }
}

/**
 * @generated from message librarian.proto.TextFilter
 */
export class TextFilter extends Message<TextFilter> {
  /**
   * @generated from oneof librarian.proto.TextFilter.value
   */
  value: {
    /**
     * @generated from field: string contains = 3;
     */
    value: string;
    case: "contains";
  } | {
    /**
     * @generated from field: string not_contains = 4;
     */
    value: string;
    case: "notContains";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<TextFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.TextFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "contains", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
    { no: 4, name: "not_contains", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextFilter {
    return new TextFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextFilter {
    return new TextFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextFilter {
    return new TextFilter().fromJsonString(jsonString, options);
  }

  static equals(a: TextFilter | PlainMessage<TextFilter> | undefined, b: TextFilter | PlainMessage<TextFilter> | undefined): boolean {
    return proto3.util.equals(TextFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.TimestampFilter
 */
export class TimestampFilter extends Message<TimestampFilter> {
  /**
   * this field will cover the range and the unique date value cases
   * for the unique value : the start will be the 00:00:00 and the end 23:59:59
   *
   * @generated from field: librarian.proto.TimeRestriction is = 1;
   */
  is?: TimeRestriction;

  constructor(data?: PartialMessage<TimestampFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.TimestampFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is", kind: "message", T: TimeRestriction },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TimestampFilter {
    return new TimestampFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TimestampFilter {
    return new TimestampFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TimestampFilter {
    return new TimestampFilter().fromJsonString(jsonString, options);
  }

  static equals(a: TimestampFilter | PlainMessage<TimestampFilter> | undefined, b: TimestampFilter | PlainMessage<TimestampFilter> | undefined): boolean {
    return proto3.util.equals(TimestampFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.ListFilter
 */
export class ListFilter extends Message<ListFilter> {
  /**
   * @generated from oneof librarian.proto.ListFilter.value
   */
  value: {
    /**
     * @generated from field: common.proto.StringList any_of = 3;
     */
    value: StringList;
    case: "anyOf";
  } | {
    /**
     * @generated from field: common.proto.StringList none_of = 4;
     */
    value: StringList;
    case: "noneOf";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ListFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ListFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "any_of", kind: "message", T: StringList, oneof: "value" },
    { no: 4, name: "none_of", kind: "message", T: StringList, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFilter {
    return new ListFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFilter {
    return new ListFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListFilter {
    return new ListFilter().fromJsonString(jsonString, options);
  }

  static equals(a: ListFilter | PlainMessage<ListFilter> | undefined, b: ListFilter | PlainMessage<ListFilter> | undefined): boolean {
    return proto3.util.equals(ListFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.NumberFilter
 */
export class NumberFilter extends Message<NumberFilter> {
  /**
   * @generated from oneof librarian.proto.NumberFilter.value
   */
  value: {
    /**
     * @generated from field: int64 is = 1;
     */
    value: bigint;
    case: "is";
  } | {
    /**
     * @generated from field: int64 is_not = 2;
     */
    value: bigint;
    case: "isNot";
  } | {
    /**
     * @generated from field: common.proto.Int64Range range = 3;
     */
    value: Int64Range;
    case: "range";
  } | {
    /**
     * @generated from field: common.proto.NetworkFollowersRange followers_range = 4;
     */
    value: NetworkFollowersRange;
    case: "followersRange";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<NumberFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.NumberFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "value" },
    { no: 2, name: "is_not", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "value" },
    { no: 3, name: "range", kind: "message", T: Int64Range, oneof: "value" },
    { no: 4, name: "followers_range", kind: "message", T: NetworkFollowersRange, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NumberFilter {
    return new NumberFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NumberFilter {
    return new NumberFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NumberFilter {
    return new NumberFilter().fromJsonString(jsonString, options);
  }

  static equals(a: NumberFilter | PlainMessage<NumberFilter> | undefined, b: NumberFilter | PlainMessage<NumberFilter> | undefined): boolean {
    return proto3.util.equals(NumberFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.DecimalNumberFilter
 */
export class DecimalNumberFilter extends Message<DecimalNumberFilter> {
  /**
   * @generated from oneof librarian.proto.DecimalNumberFilter.value
   */
  value: {
    /**
     * @generated from field: double is = 1;
     */
    value: number;
    case: "is";
  } | {
    /**
     * @generated from field: double is_not = 2;
     */
    value: number;
    case: "isNot";
  } | {
    /**
     * @generated from field: common.proto.DoubleRange range = 3;
     */
    value: DoubleRange;
    case: "range";
  } | {
    /**
     * @generated from field: common.proto.NetworkEngagementRangeV2 engagement_range = 4;
     */
    value: NetworkEngagementRangeV2;
    case: "engagementRange";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<DecimalNumberFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DecimalNumberFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "value" },
    { no: 2, name: "is_not", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "value" },
    { no: 3, name: "range", kind: "message", T: DoubleRange, oneof: "value" },
    { no: 4, name: "engagement_range", kind: "message", T: NetworkEngagementRangeV2, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DecimalNumberFilter {
    return new DecimalNumberFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DecimalNumberFilter {
    return new DecimalNumberFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DecimalNumberFilter {
    return new DecimalNumberFilter().fromJsonString(jsonString, options);
  }

  static equals(a: DecimalNumberFilter | PlainMessage<DecimalNumberFilter> | undefined, b: DecimalNumberFilter | PlainMessage<DecimalNumberFilter> | undefined): boolean {
    return proto3.util.equals(DecimalNumberFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.CommonFilter
 */
export class CommonFilter extends Message<CommonFilter> {
  /**
   * @generated from oneof librarian.proto.CommonFilter.field
   */
  field: {
    /**
     * @generated from field: librarian.proto.EntityField entity_field = 11;
     */
    value: EntityField;
    case: "entityField";
  } | {
    /**
     * @generated from field: librarian.proto.NativeFieldFilter native_field_filter = 8;
     */
    value: NativeFieldFilter;
    case: "nativeFieldFilter";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from oneof librarian.proto.CommonFilter.filter
   */
  filter: {
    /**
     * @generated from field: librarian.proto.TextFilter text_filter = 2;
     */
    value: TextFilter;
    case: "textFilter";
  } | {
    /**
     * @generated from field: librarian.proto.TimestampFilter date_filter = 3;
     */
    value: TimestampFilter;
    case: "dateFilter";
  } | {
    /**
     * @generated from field: librarian.proto.ListFilter list_filter = 4;
     */
    value: ListFilter;
    case: "listFilter";
  } | {
    /**
     * @generated from field: librarian.proto.NumberFilter number_filter = 5;
     */
    value: NumberFilter;
    case: "numberFilter";
  } | {
    /**
     * @generated from field: librarian.proto.DecimalNumberFilter decimal_filter = 6;
     */
    value: DecimalNumberFilter;
    case: "decimalFilter";
  } | {
    /**
     * @generated from field: bool bool_filter = 9;
     */
    value: boolean;
    case: "boolFilter";
  } | {
    /**
     * @generated from field: librarian.proto.GoogleLocation location_filter = 10;
     */
    value: GoogleLocation;
    case: "locationFilter";
  } | {
    /**
     * @generated from field: common.proto.AuthorPermission author_permission_filter = 12;
     */
    value: AuthorPermission;
    case: "authorPermissionFilter";
  } | {
    /**
     * @generated from field: bool is_empty = 7;
     */
    value: boolean;
    case: "isEmpty";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CommonFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CommonFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 11, name: "entity_field", kind: "message", T: EntityField, oneof: "field" },
    { no: 8, name: "native_field_filter", kind: "enum", T: proto3.getEnumType(NativeFieldFilter), oneof: "field" },
    { no: 2, name: "text_filter", kind: "message", T: TextFilter, oneof: "filter" },
    { no: 3, name: "date_filter", kind: "message", T: TimestampFilter, oneof: "filter" },
    { no: 4, name: "list_filter", kind: "message", T: ListFilter, oneof: "filter" },
    { no: 5, name: "number_filter", kind: "message", T: NumberFilter, oneof: "filter" },
    { no: 6, name: "decimal_filter", kind: "message", T: DecimalNumberFilter, oneof: "filter" },
    { no: 9, name: "bool_filter", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "filter" },
    { no: 10, name: "location_filter", kind: "message", T: GoogleLocation, oneof: "filter" },
    { no: 12, name: "author_permission_filter", kind: "enum", T: proto3.getEnumType(AuthorPermission), oneof: "filter" },
    { no: 7, name: "is_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "filter" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CommonFilter {
    return new CommonFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CommonFilter {
    return new CommonFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CommonFilter {
    return new CommonFilter().fromJsonString(jsonString, options);
  }

  static equals(a: CommonFilter | PlainMessage<CommonFilter> | undefined, b: CommonFilter | PlainMessage<CommonFilter> | undefined): boolean {
    return proto3.util.equals(CommonFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.DynamicFilterGroup
 */
export class DynamicFilterGroup extends Message<DynamicFilterGroup> {
  /**
   * here we can have one or many filters on one or many fields
   * only one operator is allowed in each group
   * example : (A is "toto") AND (B is "Jhon Snow") AND (C is_not "Elon Musk")
   *
   * @generated from field: repeated librarian.proto.CommonFilter filters = 1;
   */
  filters: CommonFilter[] = [];

  /**
   * @generated from field: common.proto.LogicOperator operator = 2;
   */
  operator = LogicOperator.UNKNOWN_OPERATOR;

  /**
   * this boolean informs us if we should apply an AND operator above
   * example 1 : in pool visuals view, we should filter by postTypes with an AND operator
   * example 2 : in campaign visuals view, we should filter by campaign and postTypes with an AND operator
   *
   * @generated from field: bool is_default = 3;
   */
  isDefault = false;

  constructor(data?: PartialMessage<DynamicFilterGroup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DynamicFilterGroup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filters", kind: "message", T: CommonFilter, repeated: true },
    { no: 2, name: "operator", kind: "enum", T: proto3.getEnumType(LogicOperator) },
    { no: 3, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DynamicFilterGroup {
    return new DynamicFilterGroup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DynamicFilterGroup {
    return new DynamicFilterGroup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DynamicFilterGroup {
    return new DynamicFilterGroup().fromJsonString(jsonString, options);
  }

  static equals(a: DynamicFilterGroup | PlainMessage<DynamicFilterGroup> | undefined, b: DynamicFilterGroup | PlainMessage<DynamicFilterGroup> | undefined): boolean {
    return proto3.util.equals(DynamicFilterGroup, a, b);
  }
}

/**
 * @generated from message librarian.proto.DynamicFilter
 */
export class DynamicFilter extends Message<DynamicFilter> {
  /**
   * here we can have one or many groups containing one or many fields filters
   * only one operator is allowed between groups
   * example : (group_a) OR (group_b) OR (group_c)
   *
   * @generated from field: repeated librarian.proto.DynamicFilterGroup filter_group = 1;
   */
  filterGroup: DynamicFilterGroup[] = [];

  /**
   * @generated from field: common.proto.LogicOperator operator = 2;
   */
  operator = LogicOperator.UNKNOWN_OPERATOR;

  constructor(data?: PartialMessage<DynamicFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DynamicFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter_group", kind: "message", T: DynamicFilterGroup, repeated: true },
    { no: 2, name: "operator", kind: "enum", T: proto3.getEnumType(LogicOperator) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DynamicFilter {
    return new DynamicFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DynamicFilter {
    return new DynamicFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DynamicFilter {
    return new DynamicFilter().fromJsonString(jsonString, options);
  }

  static equals(a: DynamicFilter | PlainMessage<DynamicFilter> | undefined, b: DynamicFilter | PlainMessage<DynamicFilter> | undefined): boolean {
    return proto3.util.equals(DynamicFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.BaseEntityTypeReq
 */
export class BaseEntityTypeReq extends Message<BaseEntityTypeReq> {
  /**
   * @generated from field: librarian.proto.BaseEntityType entity_type = 1;
   */
  entityType = BaseEntityType.UNKNOWN;

  constructor(data?: PartialMessage<BaseEntityTypeReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.BaseEntityTypeReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_type", kind: "enum", T: proto3.getEnumType(BaseEntityType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BaseEntityTypeReq {
    return new BaseEntityTypeReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BaseEntityTypeReq {
    return new BaseEntityTypeReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BaseEntityTypeReq {
    return new BaseEntityTypeReq().fromJsonString(jsonString, options);
  }

  static equals(a: BaseEntityTypeReq | PlainMessage<BaseEntityTypeReq> | undefined, b: BaseEntityTypeReq | PlainMessage<BaseEntityTypeReq> | undefined): boolean {
    return proto3.util.equals(BaseEntityTypeReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.EntityField
 */
export class EntityField extends Message<EntityField> {
  /**
   * @generated from field: google.protobuf.Int64Value entity_field_id = 1;
   */
  entityFieldId?: bigint;

  /**
   * @generated from field: librarian.proto.BaseEntityType entity_type = 2;
   */
  entityType = BaseEntityType.UNKNOWN;

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from oneof librarian.proto.EntityField.field_type
   */
  fieldType: {
    /**
     * @generated from field: librarian.proto.CreatorFieldType creator_field_type = 12;
     */
    value: CreatorFieldType;
    case: "creatorFieldType";
  } | {
    /**
     * @generated from field: librarian.proto.CampaignFieldType campaign_field_type = 13;
     */
    value: CampaignFieldType;
    case: "campaignFieldType";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * If type is FIELD_TYPE_LIST
   *
   * @generated from field: repeated string list_values = 4;
   */
  listValues: string[] = [];

  /**
   * If type is FIELD_TYPE_LIST
   *
   * @generated from field: bool multi_selection = 5;
   */
  multiSelection = false;

  /**
   * @generated from field: bool archived = 6;
   */
  archived = false;

  /**
   * @generated from field: google.protobuf.Timestamp created = 7;
   */
  created?: Timestamp;

  /**
   * @generated from field: string description = 8;
   */
  description = "";

  /**
   * @generated from field: bool show_in_filters = 9;
   */
  showInFilters = false;

  /**
   * @generated from field: bool default = 10;
   */
  default = false;

  /**
   * for campaign fields
   *
   * @generated from field: bool mandatory = 11;
   */
  mandatory = false;

  constructor(data?: PartialMessage<EntityField>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EntityField";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_field_id", kind: "message", T: Int64Value },
    { no: 2, name: "entity_type", kind: "enum", T: proto3.getEnumType(BaseEntityType) },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "creator_field_type", kind: "enum", T: proto3.getEnumType(CreatorFieldType), oneof: "field_type" },
    { no: 13, name: "campaign_field_type", kind: "enum", T: proto3.getEnumType(CampaignFieldType), oneof: "field_type" },
    { no: 4, name: "list_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "multi_selection", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "created", kind: "message", T: Timestamp },
    { no: 8, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "show_in_filters", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "mandatory", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityField {
    return new EntityField().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityField {
    return new EntityField().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityField {
    return new EntityField().fromJsonString(jsonString, options);
  }

  static equals(a: EntityField | PlainMessage<EntityField> | undefined, b: EntityField | PlainMessage<EntityField> | undefined): boolean {
    return proto3.util.equals(EntityField, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorField
 */
export class CreatorField extends Message<CreatorField> {
  /**
   * @generated from field: google.protobuf.Int64Value creator_field_id = 1;
   */
  creatorFieldId?: bigint;

  /**
   * @generated from field: bool default = 10;
   */
  default = false;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: librarian.proto.CreatorFieldType type = 3;
   */
  type = CreatorFieldType.UNKNOWN;

  /**
   * If type is CUSTOM_FIELD_TYPE_LIST
   *
   * @generated from field: repeated string list_values = 4;
   */
  listValues: string[] = [];

  /**
   * If type is CUSTOM_FIELD_TYPE_LIST
   *
   * @generated from field: bool multi_selection = 5;
   */
  multiSelection = false;

  /**
   * @generated from field: bool archived = 6;
   */
  archived = false;

  /**
   * @generated from field: google.protobuf.Timestamp created = 7;
   */
  created?: Timestamp;

  /**
   * @generated from field: string description = 8;
   */
  description = "";

  /**
   * @generated from field: bool show_in_filters = 9;
   */
  showInFilters = false;

  constructor(data?: PartialMessage<CreatorField>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorField";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_field_id", kind: "message", T: Int64Value },
    { no: 10, name: "default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(CreatorFieldType) },
    { no: 4, name: "list_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "multi_selection", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "created", kind: "message", T: Timestamp },
    { no: 8, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "show_in_filters", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorField {
    return new CreatorField().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorField {
    return new CreatorField().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorField {
    return new CreatorField().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorField | PlainMessage<CreatorField> | undefined, b: CreatorField | PlainMessage<CreatorField> | undefined): boolean {
    return proto3.util.equals(CreatorField, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorFieldSort
 */
export class CreatorFieldSort extends Message<CreatorFieldSort> {
  /**
   * @generated from field: librarian.proto.CreatorField creator_field = 1;
   */
  creatorField?: CreatorField;

  /**
   * @generated from field: common.proto.SortDirection direction = 2;
   */
  direction = SortDirection.DESC;

  constructor(data?: PartialMessage<CreatorFieldSort>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorFieldSort";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_field", kind: "message", T: CreatorField },
    { no: 2, name: "direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorFieldSort {
    return new CreatorFieldSort().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorFieldSort {
    return new CreatorFieldSort().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorFieldSort {
    return new CreatorFieldSort().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorFieldSort | PlainMessage<CreatorFieldSort> | undefined, b: CreatorFieldSort | PlainMessage<CreatorFieldSort> | undefined): boolean {
    return proto3.util.equals(CreatorFieldSort, a, b);
  }
}

/**
 * @generated from message librarian.proto.EntityFieldSort
 */
export class EntityFieldSort extends Message<EntityFieldSort> {
  /**
   * @generated from field: librarian.proto.EntityField entity_field = 1;
   */
  entityField?: EntityField;

  /**
   * @generated from field: common.proto.SortDirection direction = 2;
   */
  direction = SortDirection.DESC;

  constructor(data?: PartialMessage<EntityFieldSort>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EntityFieldSort";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_field", kind: "message", T: EntityField },
    { no: 2, name: "direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityFieldSort {
    return new EntityFieldSort().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityFieldSort {
    return new EntityFieldSort().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityFieldSort {
    return new EntityFieldSort().fromJsonString(jsonString, options);
  }

  static equals(a: EntityFieldSort | PlainMessage<EntityFieldSort> | undefined, b: EntityFieldSort | PlainMessage<EntityFieldSort> | undefined): boolean {
    return proto3.util.equals(EntityFieldSort, a, b);
  }
}

/**
 * This messages encodes a search request for creators in one's pool or campaign.
 * It is capable of encoding all the fields in: database/elasticsearch/elasticsearch.pool.index.json
 *
 * It bears many similarities to a generic UserSearchRequest except for the fields that we chose to not index
 * in the pool, and it obviously adds fields that are pool specific (user owner, campaigns, labels).
 *
 * @generated from message librarian.proto.CreatorPoolRequestV2
 */
export class CreatorPoolRequestV2 extends Message<CreatorPoolRequestV2> {
  /**
   * @generated from field: int64 pool_owner_id = 1 [deprecated = true];
   * @deprecated
   */
  poolOwnerId = protoInt64.zero;

  /**
   * @generated from oneof librarian.proto.CreatorPoolRequestV2.entity_id
   */
  entityId: {
    /**
     * @generated from field: int64 team_owner_id = 3;
     */
    value: bigint;
    case: "teamOwnerId";
  } | {
    /**
     * @generated from field: int64 campaign_id = 4;
     */
    value: bigint;
    case: "campaignId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: librarian.proto.DynamicFilter dynamic_filter = 2;
   */
  dynamicFilter?: DynamicFilter;

  /**
   * pagination
   *
   * @generated from field: common.proto.BasicPagination pagination = 100;
   */
  pagination?: BasicPagination;

  /**
   * @generated from field: librarian.proto.UserSort sort = 101;
   */
  sort = UserSort.DEFAULT;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 102;
   */
  sortDirection = SortDirection.DESC;

  /**
   * @generated from field: librarian.proto.CreatorFieldSort creator_sort = 103 [deprecated = true];
   * @deprecated
   */
  creatorSort?: CreatorFieldSort;

  /**
   * @generated from field: librarian.proto.EntityFieldSort creator_field_sort = 104;
   */
  creatorFieldSort?: EntityFieldSort;

  constructor(data?: PartialMessage<CreatorPoolRequestV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorPoolRequestV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pool_owner_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "team_owner_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "entity_id" },
    { no: 4, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "entity_id" },
    { no: 2, name: "dynamic_filter", kind: "message", T: DynamicFilter },
    { no: 100, name: "pagination", kind: "message", T: BasicPagination },
    { no: 101, name: "sort", kind: "enum", T: proto3.getEnumType(UserSort) },
    { no: 102, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 103, name: "creator_sort", kind: "message", T: CreatorFieldSort },
    { no: 104, name: "creator_field_sort", kind: "message", T: EntityFieldSort },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorPoolRequestV2 {
    return new CreatorPoolRequestV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorPoolRequestV2 {
    return new CreatorPoolRequestV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorPoolRequestV2 {
    return new CreatorPoolRequestV2().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorPoolRequestV2 | PlainMessage<CreatorPoolRequestV2> | undefined, b: CreatorPoolRequestV2 | PlainMessage<CreatorPoolRequestV2> | undefined): boolean {
    return proto3.util.equals(CreatorPoolRequestV2, a, b);
  }
}

/**
 * @generated from message librarian.proto.CollaboratorCastingInfluencersFilter
 */
export class CollaboratorCastingInfluencersFilter extends Message<CollaboratorCastingInfluencersFilter> {
  /**
   * @generated from field: google.protobuf.StringValue username = 1;
   */
  username?: string;

  /**
   * @generated from oneof librarian.proto.CollaboratorCastingInfluencersFilter.status_or_all
   */
  statusOrAll: {
    /**
     * @generated from field: librarian.proto.InfluencerCastingStatus influencer_status = 2;
     */
    value: InfluencerCastingStatus;
    case: "influencerStatus";
  } | {
    /**
     * @generated from field: bool all = 3;
     */
    value: boolean;
    case: "all";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: bool with_expected_posts = 4;
   */
  withExpectedPosts = false;

  constructor(data?: PartialMessage<CollaboratorCastingInfluencersFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CollaboratorCastingInfluencersFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "message", T: StringValue },
    { no: 2, name: "influencer_status", kind: "enum", T: proto3.getEnumType(InfluencerCastingStatus), oneof: "status_or_all" },
    { no: 3, name: "all", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "status_or_all" },
    { no: 4, name: "with_expected_posts", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CollaboratorCastingInfluencersFilter {
    return new CollaboratorCastingInfluencersFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CollaboratorCastingInfluencersFilter {
    return new CollaboratorCastingInfluencersFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CollaboratorCastingInfluencersFilter {
    return new CollaboratorCastingInfluencersFilter().fromJsonString(jsonString, options);
  }

  static equals(a: CollaboratorCastingInfluencersFilter | PlainMessage<CollaboratorCastingInfluencersFilter> | undefined, b: CollaboratorCastingInfluencersFilter | PlainMessage<CollaboratorCastingInfluencersFilter> | undefined): boolean {
    return proto3.util.equals(CollaboratorCastingInfluencersFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.InfluencersCastingFilter
 */
export class InfluencersCastingFilter extends Message<InfluencersCastingFilter> {
  /**
   * @generated from field: int64 casting_id = 1;
   */
  castingId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.CollaboratorCastingInfluencersFilter filter = 2;
   */
  filter?: CollaboratorCastingInfluencersFilter;

  /**
   * @generated from field: librarian.proto.DynamicFilter dynamicFilter = 3;
   */
  dynamicFilter?: DynamicFilter;

  constructor(data?: PartialMessage<InfluencersCastingFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InfluencersCastingFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "filter", kind: "message", T: CollaboratorCastingInfluencersFilter },
    { no: 3, name: "dynamicFilter", kind: "message", T: DynamicFilter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencersCastingFilter {
    return new InfluencersCastingFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencersCastingFilter {
    return new InfluencersCastingFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencersCastingFilter {
    return new InfluencersCastingFilter().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencersCastingFilter | PlainMessage<InfluencersCastingFilter> | undefined, b: InfluencersCastingFilter | PlainMessage<InfluencersCastingFilter> | undefined): boolean {
    return proto3.util.equals(InfluencersCastingFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliateFilter
 */
export class AffiliateFilter extends Message<AffiliateFilter> {
  /**
   * @generated from field: string name_prefix = 1;
   */
  namePrefix = "";

  /**
   * @generated from field: repeated int64 programs_ids = 14;
   */
  programsIds: bigint[] = [];

  /**
   * @generated from field: repeated librarian.proto.DestinationUrl destinations_urls = 17;
   */
  destinationsUrls: DestinationUrl[] = [];

  /**
   * @generated from field: librarian.proto.TimeRestriction time_restriction = 11;
   */
  timeRestriction?: TimeRestriction;

  /**
   * @generated from field: repeated int64 influencer_labels_ids = 12;
   */
  influencerLabelsIds: bigint[] = [];

  /**
   * @generated from field: bool archived = 15;
   */
  archived = false;

  /**
   * @generated from field: repeated int64 campaigns_ids = 16;
   */
  campaignsIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 pixels_ids = 18;
   */
  pixelsIds: bigint[] = [];

  constructor(data?: PartialMessage<AffiliateFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliateFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "programs_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 17, name: "destinations_urls", kind: "message", T: DestinationUrl, repeated: true },
    { no: 11, name: "time_restriction", kind: "message", T: TimeRestriction },
    { no: 12, name: "influencer_labels_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 15, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "campaigns_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 18, name: "pixels_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliateFilter {
    return new AffiliateFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliateFilter {
    return new AffiliateFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliateFilter {
    return new AffiliateFilter().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliateFilter | PlainMessage<AffiliateFilter> | undefined, b: AffiliateFilter | PlainMessage<AffiliateFilter> | undefined): boolean {
    return proto3.util.equals(AffiliateFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationPixelProto
 */
export class AffiliationPixelProto extends Message<AffiliationPixelProto> {
  /**
   * @generated from field: google.protobuf.Int64Value pixel_id = 1;
   */
  pixelId?: bigint;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.StringValue short_code = 3;
   */
  shortCode?: string;

  /**
   * @generated from field: google.protobuf.Timestamp created = 4;
   */
  created?: Timestamp;

  /**
   * @generated from field: google.protobuf.BoolValue archived = 5;
   */
  archived?: boolean;

  /**
   * @generated from field: google.protobuf.Int64Value usages_count = 6;
   */
  usagesCount?: bigint;

  /**
   * @generated from field: google.protobuf.BoolValue default = 7;
   */
  default?: boolean;

  constructor(data?: PartialMessage<AffiliationPixelProto>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationPixelProto";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pixel_id", kind: "message", T: Int64Value },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "short_code", kind: "message", T: StringValue },
    { no: 4, name: "created", kind: "message", T: Timestamp },
    { no: 5, name: "archived", kind: "message", T: BoolValue },
    { no: 6, name: "usages_count", kind: "message", T: Int64Value },
    { no: 7, name: "default", kind: "message", T: BoolValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationPixelProto {
    return new AffiliationPixelProto().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationPixelProto {
    return new AffiliationPixelProto().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationPixelProto {
    return new AffiliationPixelProto().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationPixelProto | PlainMessage<AffiliationPixelProto> | undefined, b: AffiliationPixelProto | PlainMessage<AffiliationPixelProto> | undefined): boolean {
    return proto3.util.equals(AffiliationPixelProto, a, b);
  }
}

/**
 * @generated from message librarian.proto.AffiliationPixelProtoList
 */
export class AffiliationPixelProtoList extends Message<AffiliationPixelProtoList> {
  /**
   * @generated from field: repeated librarian.proto.AffiliationPixelProto pixels = 1;
   */
  pixels: AffiliationPixelProto[] = [];

  constructor(data?: PartialMessage<AffiliationPixelProtoList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AffiliationPixelProtoList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pixels", kind: "message", T: AffiliationPixelProto, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AffiliationPixelProtoList {
    return new AffiliationPixelProtoList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AffiliationPixelProtoList {
    return new AffiliationPixelProtoList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AffiliationPixelProtoList {
    return new AffiliationPixelProtoList().fromJsonString(jsonString, options);
  }

  static equals(a: AffiliationPixelProtoList | PlainMessage<AffiliationPixelProtoList> | undefined, b: AffiliationPixelProtoList | PlainMessage<AffiliationPixelProtoList> | undefined): boolean {
    return proto3.util.equals(AffiliationPixelProtoList, a, b);
  }
}

/**
 * @generated from message librarian.proto.DestinationUrl
 */
export class DestinationUrl extends Message<DestinationUrl> {
  /**
   * @generated from field: google.protobuf.Int64Value destination_url_id = 1;
   */
  destinationUrlId?: bigint;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string destination_url = 3;
   */
  destinationUrl = "";

  /**
   * @generated from field: common.proto.UTMParameterList utm_parameters_values = 4;
   */
  utmParametersValues?: UTMParameterList;

  /**
   * Fields only for Read API
   *
   * @generated from field: bool archived = 5;
   */
  archived = false;

  /**
   * Full destination URL, with UTM parameters if any
   *
   * @generated from field: string full_destination_url = 6;
   */
  fullDestinationUrl = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 7;
   */
  created?: Timestamp;

  /**
   * @generated from field: librarian.proto.AffiliationPixelProtoList detected_pixels = 8;
   */
  detectedPixels?: AffiliationPixelProtoList;

  constructor(data?: PartialMessage<DestinationUrl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DestinationUrl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "destination_url_id", kind: "message", T: Int64Value },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "destination_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "utm_parameters_values", kind: "message", T: UTMParameterList },
    { no: 5, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "full_destination_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "created", kind: "message", T: Timestamp },
    { no: 8, name: "detected_pixels", kind: "message", T: AffiliationPixelProtoList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DestinationUrl {
    return new DestinationUrl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DestinationUrl {
    return new DestinationUrl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DestinationUrl {
    return new DestinationUrl().fromJsonString(jsonString, options);
  }

  static equals(a: DestinationUrl | PlainMessage<DestinationUrl> | undefined, b: DestinationUrl | PlainMessage<DestinationUrl> | undefined): boolean {
    return proto3.util.equals(DestinationUrl, a, b);
  }
}

/**
 * @generated from message librarian.proto.LandingPageCandidatesFilter
 */
export class LandingPageCandidatesFilter extends Message<LandingPageCandidatesFilter> {
  /**
   * @generated from field: google.protobuf.StringValue username = 1;
   */
  username?: string;

  /**
   * @generated from field: repeated common.proto.UserStatus user_statuses = 2;
   */
  userStatuses: UserStatus[] = [];

  constructor(data?: PartialMessage<LandingPageCandidatesFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LandingPageCandidatesFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "message", T: StringValue },
    { no: 2, name: "user_statuses", kind: "enum", T: proto3.getEnumType(UserStatus), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LandingPageCandidatesFilter {
    return new LandingPageCandidatesFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LandingPageCandidatesFilter {
    return new LandingPageCandidatesFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LandingPageCandidatesFilter {
    return new LandingPageCandidatesFilter().fromJsonString(jsonString, options);
  }

  static equals(a: LandingPageCandidatesFilter | PlainMessage<LandingPageCandidatesFilter> | undefined, b: LandingPageCandidatesFilter | PlainMessage<LandingPageCandidatesFilter> | undefined): boolean {
    return proto3.util.equals(LandingPageCandidatesFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.LandingPageFilter
 */
export class LandingPageFilter extends Message<LandingPageFilter> {
  /**
   * @generated from field: int64 landing_page_id = 1;
   */
  landingPageId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.LandingPageCandidatesFilter candidates_filter = 2;
   */
  candidatesFilter?: LandingPageCandidatesFilter;

  constructor(data?: PartialMessage<LandingPageFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LandingPageFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "landing_page_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "candidates_filter", kind: "message", T: LandingPageCandidatesFilter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LandingPageFilter {
    return new LandingPageFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LandingPageFilter {
    return new LandingPageFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LandingPageFilter {
    return new LandingPageFilter().fromJsonString(jsonString, options);
  }

  static equals(a: LandingPageFilter | PlainMessage<LandingPageFilter> | undefined, b: LandingPageFilter | PlainMessage<LandingPageFilter> | undefined): boolean {
    return proto3.util.equals(LandingPageFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.GenerikKeywordsFilter
 */
export class GenerikKeywordsFilter extends Message<GenerikKeywordsFilter> {
  /**
   * @generated from field: repeated common.proto.GenerikKeyword keywords = 1;
   */
  keywords: GenerikKeyword[] = [];

  /**
   * @generated from field: common.proto.LogicOperator operator = 2;
   */
  operator = LogicOperator.UNKNOWN_OPERATOR;

  constructor(data?: PartialMessage<GenerikKeywordsFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GenerikKeywordsFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keywords", kind: "message", T: GenerikKeyword, repeated: true },
    { no: 2, name: "operator", kind: "enum", T: proto3.getEnumType(LogicOperator) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikKeywordsFilter {
    return new GenerikKeywordsFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikKeywordsFilter {
    return new GenerikKeywordsFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikKeywordsFilter {
    return new GenerikKeywordsFilter().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikKeywordsFilter | PlainMessage<GenerikKeywordsFilter> | undefined, b: GenerikKeywordsFilter | PlainMessage<GenerikKeywordsFilter> | undefined): boolean {
    return proto3.util.equals(GenerikKeywordsFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWBrandDetailsRequest
 */
export class CWBrandDetailsRequest extends Message<CWBrandDetailsRequest> {
  /**
   * @generated from field: int64 report_id = 1;
   */
  reportId = protoInt64.zero;

  /**
   * @generated from field: int64 brand_market_id = 2 [deprecated = true];
   * @deprecated
   */
  brandMarketId = protoInt64.zero;

  /**
   * @generated from field: int64 brand_id = 12;
   */
  brandId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 market_ids = 10;
   */
  marketIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 industry_ids = 11;
   */
  industryIds: bigint[] = [];

  /**
   * @generated from field: repeated common.proto.Network networks = 8;
   */
  networks: Network[] = [];

  /**
   * @generated from field: librarian.proto.TimeRestriction time_restriction = 3;
   */
  timeRestriction?: TimeRestriction;

  /**
   * This is only used on the emv historical view
   *
   * @generated from field: common.proto.Interval interval = 4;
   */
  interval = Interval.UNKNOWN;

  /**
   * @generated from field: repeated common.proto.CampaignKeyword keywords = 5 [deprecated = true];
   * @deprecated
   */
  keywords: CampaignKeyword[] = [];

  /**
   * @generated from field: librarian.proto.GenerikKeywordsFilter keywords_filter = 9;
   */
  keywordsFilter?: GenerikKeywordsFilter;

  /**
   * @generated from field: repeated common.proto.InfluencerKind influencers_kinds = 6;
   */
  influencersKinds: InfluencerKind[] = [];

  /**
   * true for sponsored, false for organic, else all
   *
   * @generated from field: google.protobuf.BoolValue sponsored_posts = 7;
   */
  sponsoredPosts?: boolean;

  constructor(data?: PartialMessage<CWBrandDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWBrandDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "brand_market_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "brand_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "market_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 11, name: "industry_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 8, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 3, name: "time_restriction", kind: "message", T: TimeRestriction },
    { no: 4, name: "interval", kind: "enum", T: proto3.getEnumType(Interval) },
    { no: 5, name: "keywords", kind: "message", T: CampaignKeyword, repeated: true },
    { no: 9, name: "keywords_filter", kind: "message", T: GenerikKeywordsFilter },
    { no: 6, name: "influencers_kinds", kind: "enum", T: proto3.getEnumType(InfluencerKind), repeated: true },
    { no: 7, name: "sponsored_posts", kind: "message", T: BoolValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWBrandDetailsRequest {
    return new CWBrandDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWBrandDetailsRequest {
    return new CWBrandDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWBrandDetailsRequest {
    return new CWBrandDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWBrandDetailsRequest | PlainMessage<CWBrandDetailsRequest> | undefined, b: CWBrandDetailsRequest | PlainMessage<CWBrandDetailsRequest> | undefined): boolean {
    return proto3.util.equals(CWBrandDetailsRequest, a, b);
  }
}

/**
 * This request will perform an aggregation on ES. Therefore there is no pagination ...
 *
 * @generated from message librarian.proto.CWInfluencersRequest
 */
export class CWInfluencersRequest extends Message<CWInfluencersRequest> {
  /**
   * @generated from field: librarian.proto.CWBrandDetailsRequest request = 1;
   */
  request?: CWBrandDetailsRequest;

  /**
   * @generated from field: librarian.proto.UserSort sort = 2;
   */
  sort = UserSort.DEFAULT;

  /**
   * this is the number of results you want to get on the response, it will be 500 by default if set to 0
   *
   * @generated from field: int32 size = 3;
   */
  size = 0;

  constructor(data?: PartialMessage<CWInfluencersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWInfluencersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request", kind: "message", T: CWBrandDetailsRequest },
    { no: 2, name: "sort", kind: "enum", T: proto3.getEnumType(UserSort) },
    { no: 3, name: "size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWInfluencersRequest {
    return new CWInfluencersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWInfluencersRequest {
    return new CWInfluencersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWInfluencersRequest {
    return new CWInfluencersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWInfluencersRequest | PlainMessage<CWInfluencersRequest> | undefined, b: CWInfluencersRequest | PlainMessage<CWInfluencersRequest> | undefined): boolean {
    return proto3.util.equals(CWInfluencersRequest, a, b);
  }
}

/**
 * if influencer is already ingested, only lefty_id is needed
 * to ingest, need username and lefty_id (to deduce network), or username and network
 *
 * @generated from message librarian.proto.InfluencerHandle
 */
export class InfluencerHandle extends Message<InfluencerHandle> {
  /**
   * @generated from field: string lefty_id = 1;
   */
  leftyId = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * @generated from field: common.proto.Network network = 3;
   */
  network = Network.NETWORK_UNKNOWN;

  constructor(data?: PartialMessage<InfluencerHandle>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InfluencerHandle";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lefty_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerHandle {
    return new InfluencerHandle().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerHandle {
    return new InfluencerHandle().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerHandle {
    return new InfluencerHandle().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerHandle | PlainMessage<InfluencerHandle> | undefined, b: InfluencerHandle | PlainMessage<InfluencerHandle> | undefined): boolean {
    return proto3.util.equals(InfluencerHandle, a, b);
  }
}

/**
 * @generated from message librarian.proto.InfluencerHandles
 */
export class InfluencerHandles extends Message<InfluencerHandles> {
  /**
   * @generated from field: repeated librarian.proto.InfluencerHandle influencer_handles = 1;
   */
  influencerHandles: InfluencerHandle[] = [];

  constructor(data?: PartialMessage<InfluencerHandles>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InfluencerHandles";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_handles", kind: "message", T: InfluencerHandle, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerHandles {
    return new InfluencerHandles().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerHandles {
    return new InfluencerHandles().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerHandles {
    return new InfluencerHandles().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerHandles | PlainMessage<InfluencerHandles> | undefined, b: InfluencerHandles | PlainMessage<InfluencerHandles> | undefined): boolean {
    return proto3.util.equals(InfluencerHandles, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetVisualsRequest
 */
export class GetVisualsRequest extends Message<GetVisualsRequest> {
  /**
   * this fields tells us if we want to have directory visuals only or not
   *
   * @generated from field: bool favorites = 1;
   */
  favorites = false;

  /**
   * Visuals filtering
   *
   * @generated from field: librarian.proto.DynamicFilter dynamic_filter = 5;
   */
  dynamicFilter?: DynamicFilter;

  /**
   * @generated from field: librarian.proto.VisualSort sort = 3;
   */
  sort = VisualSort.NO_SORT;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  /**
   * @generated from field: librarian.proto.GetVisualsRequest.Source source = 6;
   */
  source = GetVisualsRequest_Source.NONE;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 7;
   */
  sortDirection = SortDirection.DESC;

  constructor(data?: PartialMessage<GetVisualsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetVisualsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "favorites", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "dynamic_filter", kind: "message", T: DynamicFilter },
    { no: 3, name: "sort", kind: "enum", T: proto3.getEnumType(VisualSort) },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
    { no: 6, name: "source", kind: "enum", T: proto3.getEnumType(GetVisualsRequest_Source) },
    { no: 7, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetVisualsRequest {
    return new GetVisualsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetVisualsRequest {
    return new GetVisualsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetVisualsRequest {
    return new GetVisualsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetVisualsRequest | PlainMessage<GetVisualsRequest> | undefined, b: GetVisualsRequest | PlainMessage<GetVisualsRequest> | undefined): boolean {
    return proto3.util.equals(GetVisualsRequest, a, b);
  }
}

/**
 * @generated from enum librarian.proto.GetVisualsRequest.Source
 */
export enum GetVisualsRequest_Source {
  /**
   * @generated from enum value: NONE = 0;
   */
  NONE = 0,

  /**
   * @generated from enum value: DIRECTORY = 1;
   */
  DIRECTORY = 1,

  /**
   * @generated from enum value: CAMPAIGN = 2;
   */
  CAMPAIGN = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(GetVisualsRequest_Source)
proto3.util.setEnumType(GetVisualsRequest_Source, "librarian.proto.GetVisualsRequest.Source", [
  { no: 0, name: "NONE" },
  { no: 1, name: "DIRECTORY" },
  { no: 2, name: "CAMPAIGN" },
]);

/**
 * @generated from message librarian.proto.GetMediaRequest
 */
export class GetMediaRequest extends Message<GetMediaRequest> {
  /**
   * @generated from field: librarian.proto.DynamicFilter dynamic_filter = 5;
   */
  dynamicFilter?: DynamicFilter;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<GetMediaRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetMediaRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 5, name: "dynamic_filter", kind: "message", T: DynamicFilter },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMediaRequest {
    return new GetMediaRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMediaRequest {
    return new GetMediaRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMediaRequest {
    return new GetMediaRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMediaRequest | PlainMessage<GetMediaRequest> | undefined, b: GetMediaRequest | PlainMessage<GetMediaRequest> | undefined): boolean {
    return proto3.util.equals(GetMediaRequest, a, b);
  }
}

/**
 * arbitrary selector of a group of influencers. It can be used in any mass request, such as, export the list to CSV,
 * label all in the list, etc...
 *
 * @generated from message librarian.proto.FlexibleCreatorSelector
 */
export class FlexibleCreatorSelector extends Message<FlexibleCreatorSelector> {
  /**
   * @generated from oneof librarian.proto.FlexibleCreatorSelector.selector
   */
  selector: {
    /**
     * retrieves all the influencers whose leftyId is in that list.
     *
     * @generated from field: common.proto.StringList manual_list = 2;
     */
    value: StringList;
    case: "manualList";
  } | {
    /**
     * to use if influencers need to be resolved by username
     *
     * @generated from field: librarian.proto.InfluencerHandles manual_list_to_resolve = 12;
     */
    value: InfluencerHandles;
    case: "manualListToResolve";
  } | {
    /**
     * @generated from field: common.proto.Int64List affiliate_ids = 7;
     */
    value: Int64List;
    case: "affiliateIds";
  } | {
    /**
     * @generated from field: common.proto.Int64List influencer_casting_ids = 8;
     */
    value: Int64List;
    case: "influencerCastingIds";
  } | {
    /**
     * retrieves the creators that match this request.
     *
     * @generated from field: librarian.proto.CreatorPoolRequestV2 pool_request_v2 = 4;
     */
    value: CreatorPoolRequestV2;
    case: "poolRequestV2";
  } | {
    /**
     * @generated from field: librarian.proto.InfluencersCastingFilter casting_filter = 5;
     */
    value: InfluencersCastingFilter;
    case: "castingFilter";
  } | {
    /**
     * @generated from field: librarian.proto.AffiliateFilter affiliation_filter = 6;
     */
    value: AffiliateFilter;
    case: "affiliationFilter";
  } | {
    /**
     * @generated from field: librarian.proto.LandingPageFilter landing_page_filter = 10;
     */
    value: LandingPageFilter;
    case: "landingPageFilter";
  } | {
    /**
     * @generated from field: librarian.proto.CWInfluencersRequest competitive_watch_filter = 11;
     */
    value: CWInfluencersRequest;
    case: "competitiveWatchFilter";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<FlexibleCreatorSelector>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.FlexibleCreatorSelector";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "manual_list", kind: "message", T: StringList, oneof: "selector" },
    { no: 12, name: "manual_list_to_resolve", kind: "message", T: InfluencerHandles, oneof: "selector" },
    { no: 7, name: "affiliate_ids", kind: "message", T: Int64List, oneof: "selector" },
    { no: 8, name: "influencer_casting_ids", kind: "message", T: Int64List, oneof: "selector" },
    { no: 4, name: "pool_request_v2", kind: "message", T: CreatorPoolRequestV2, oneof: "selector" },
    { no: 5, name: "casting_filter", kind: "message", T: InfluencersCastingFilter, oneof: "selector" },
    { no: 6, name: "affiliation_filter", kind: "message", T: AffiliateFilter, oneof: "selector" },
    { no: 10, name: "landing_page_filter", kind: "message", T: LandingPageFilter, oneof: "selector" },
    { no: 11, name: "competitive_watch_filter", kind: "message", T: CWInfluencersRequest, oneof: "selector" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FlexibleCreatorSelector {
    return new FlexibleCreatorSelector().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FlexibleCreatorSelector {
    return new FlexibleCreatorSelector().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FlexibleCreatorSelector {
    return new FlexibleCreatorSelector().fromJsonString(jsonString, options);
  }

  static equals(a: FlexibleCreatorSelector | PlainMessage<FlexibleCreatorSelector> | undefined, b: FlexibleCreatorSelector | PlainMessage<FlexibleCreatorSelector> | undefined): boolean {
    return proto3.util.equals(FlexibleCreatorSelector, a, b);
  }
}

/**
 * @generated from message librarian.proto.CampaignSelector
 */
export class CampaignSelector extends Message<CampaignSelector> {
  /**
   * @generated from field: repeated int64 campaign_ids = 7;
   */
  campaignIds: bigint[] = [];

  constructor(data?: PartialMessage<CampaignSelector>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignSelector";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 7, name: "campaign_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignSelector {
    return new CampaignSelector().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignSelector {
    return new CampaignSelector().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignSelector {
    return new CampaignSelector().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignSelector | PlainMessage<CampaignSelector> | undefined, b: CampaignSelector | PlainMessage<CampaignSelector> | undefined): boolean {
    return proto3.util.equals(CampaignSelector, a, b);
  }
}

/**
 * Campaigns visuals
 *
 * @generated from message librarian.proto.VisualSelector
 */
export class VisualSelector extends Message<VisualSelector> {
  /**
   * @generated from oneof librarian.proto.VisualSelector.selector
   */
  selector: {
    /**
     * @generated from field: common.proto.StringList media_ids = 1;
     */
    value: StringList;
    case: "mediaIds";
  } | {
    /**
     * @generated from field: librarian.proto.GetVisualsRequest visuals_request = 2;
     */
    value: GetVisualsRequest;
    case: "visualsRequest";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<VisualSelector>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.VisualSelector";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media_ids", kind: "message", T: StringList, oneof: "selector" },
    { no: 2, name: "visuals_request", kind: "message", T: GetVisualsRequest, oneof: "selector" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VisualSelector {
    return new VisualSelector().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VisualSelector {
    return new VisualSelector().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VisualSelector {
    return new VisualSelector().fromJsonString(jsonString, options);
  }

  static equals(a: VisualSelector | PlainMessage<VisualSelector> | undefined, b: VisualSelector | PlainMessage<VisualSelector> | undefined): boolean {
    return proto3.util.equals(VisualSelector, a, b);
  }
}

/**
 * @generated from message librarian.proto.PortfolioReqV2
 */
export class PortfolioReqV2 extends Message<PortfolioReqV2> {
  /**
   * @generated from oneof librarian.proto.PortfolioReqV2.the_id
   */
  theId: {
    /**
     * creator whose portfolio is requested, in meta id style.
     *
     * @generated from field: string id = 1;
     */
    value: string;
    case: "id";
  } | {
    /**
     * Campaign id, to get portfolio of all influencers in it
     *
     * @generated from field: int64 campaign_id = 8;
     */
    value: bigint;
    case: "campaignId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * network being requested: it is not necessarily the same as the id, for instance if
   * we request the youtube videos of a youtuber that is merged into an instagrammer, the id
   * would be an insta id and the network will be youtube.
   *
   * @generated from field: common.proto.Network network = 2;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * additional filter if set, request only the types in this list. The types must appropriate
   * regarding the 'network' field.
   *
   * @generated from field: repeated common.proto.PostType post_type = 3;
   */
  postType: PostType[] = [];

  /**
   * @generated from field: librarian.proto.DynamicFilter dynamic_filter = 4;
   */
  dynamicFilter?: DynamicFilter;

  /**
   * ES pagination
   *
   * @generated from field: common.proto.BasicPagination pagination = 5;
   */
  pagination?: BasicPagination;

  /**
   * @generated from field: librarian.proto.SortType sort = 6;
   */
  sort = SortType.RECENT;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 7;
   */
  sortDirection = SortDirection.DESC;

  constructor(data?: PartialMessage<PortfolioReqV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PortfolioReqV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "the_id" },
    { no: 8, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "the_id" },
    { no: 2, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 3, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType), repeated: true },
    { no: 4, name: "dynamic_filter", kind: "message", T: DynamicFilter },
    { no: 5, name: "pagination", kind: "message", T: BasicPagination },
    { no: 6, name: "sort", kind: "enum", T: proto3.getEnumType(SortType) },
    { no: 7, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PortfolioReqV2 {
    return new PortfolioReqV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PortfolioReqV2 {
    return new PortfolioReqV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PortfolioReqV2 {
    return new PortfolioReqV2().fromJsonString(jsonString, options);
  }

  static equals(a: PortfolioReqV2 | PlainMessage<PortfolioReqV2> | undefined, b: PortfolioReqV2 | PlainMessage<PortfolioReqV2> | undefined): boolean {
    return proto3.util.equals(PortfolioReqV2, a, b);
  }
}

/**
 * @generated from message librarian.proto.Limitations
 */
export class Limitations extends Message<Limitations> {
  /**
   * -1 means unlimited.
   *
   * @generated from field: int32 campaigns = 3;
   */
  campaigns = 0;

  /**
   * @generated from field: int32 campaigns_used = 4;
   */
  campaignsUsed = 0;

  /**
   * -1 means unlimited
   * This is only for old clients, for new ones it's unlimited
   *
   * @generated from field: int32 running_campaigns = 15;
   */
  runningCampaigns = 0;

  /**
   * @generated from field: int32 running_campaigns_used = 16;
   */
  runningCampaignsUsed = 0;

  /**
   * @generated from field: int32 influencers_in_campaign = 7;
   */
  influencersInCampaign = 0;

  /**
   * @generated from field: int32 influencers_tracked = 10;
   */
  influencersTracked = 0;

  /**
   * @generated from field: int32 influencers_tracked_used = 12;
   */
  influencersTrackedUsed = 0;

  /**
   * -1 means unlimited.
   *
   * @generated from field: int32 affiliates = 8;
   */
  affiliates = 0;

  /**
   * @generated from field: int32 affiliates_used = 9;
   */
  affiliatesUsed = 0;

  /**
   * Which features user has access to. If empty, all features are allowed
   *
   * @generated from field: repeated librarian.proto.Feature features = 11;
   */
  features: Feature[] = [];

  constructor(data?: PartialMessage<Limitations>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Limitations";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "campaigns", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "campaigns_used", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "running_campaigns", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "running_campaigns_used", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "influencers_in_campaign", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "influencers_tracked", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "influencers_tracked_used", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "affiliates", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "affiliates_used", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "features", kind: "enum", T: proto3.getEnumType(Feature), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Limitations {
    return new Limitations().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Limitations {
    return new Limitations().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Limitations {
    return new Limitations().fromJsonString(jsonString, options);
  }

  static equals(a: Limitations | PlainMessage<Limitations> | undefined, b: Limitations | PlainMessage<Limitations> | undefined): boolean {
    return proto3.util.equals(Limitations, a, b);
  }
}

/**
 * @generated from message librarian.proto.MonthlyGrowthRequest
 */
export class MonthlyGrowthRequest extends Message<MonthlyGrowthRequest> {
  /**
   * support from 1 to 6 months interval
   *
   * @generated from field: int32 interval = 1;
   */
  interval = 0;

  /**
   * percentage value
   *
   * @generated from field: double value = 2;
   */
  value = 0;

  /**
   * @generated from field: librarian.proto.MonthlyGrowthRequest.Operator operator = 3;
   */
  operator = MonthlyGrowthRequest_Operator.UNKNOWN;

  constructor(data?: PartialMessage<MonthlyGrowthRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.MonthlyGrowthRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "interval", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "operator", kind: "enum", T: proto3.getEnumType(MonthlyGrowthRequest_Operator) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MonthlyGrowthRequest {
    return new MonthlyGrowthRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MonthlyGrowthRequest {
    return new MonthlyGrowthRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MonthlyGrowthRequest {
    return new MonthlyGrowthRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MonthlyGrowthRequest | PlainMessage<MonthlyGrowthRequest> | undefined, b: MonthlyGrowthRequest | PlainMessage<MonthlyGrowthRequest> | undefined): boolean {
    return proto3.util.equals(MonthlyGrowthRequest, a, b);
  }
}

/**
 * @generated from enum librarian.proto.MonthlyGrowthRequest.Operator
 */
export enum MonthlyGrowthRequest_Operator {
  /**
   * @generated from enum value: OPERATOR_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: OPERATOR_GTE = 1;
   */
  GTE = 1,

  /**
   * @generated from enum value: OPERATOR_LTE = 2;
   */
  LTE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(MonthlyGrowthRequest_Operator)
proto3.util.setEnumType(MonthlyGrowthRequest_Operator, "librarian.proto.MonthlyGrowthRequest.Operator", [
  { no: 0, name: "OPERATOR_UNKNOWN" },
  { no: 1, name: "OPERATOR_GTE" },
  { no: 2, name: "OPERATOR_LTE" },
]);

