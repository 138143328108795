import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  input,
  NgZone,
  Signal,
  ViewChild,
} from '@angular/core';

import {
  isEmptyArray,
  isNotEmptyArray,
  isNotNil,
  LeftyColors,
  LeftyFormat,
} from '@frontend2/core';
import { generateBarChart } from '../charts/bar/bar.helpers';
import { DataSet } from '../charts/charts.models';
import { LeftyCardComponent } from '../lefty-card/lefty-card.component';
import { LeftyComponent } from '../utils';
import { ChartWithDataSet } from './lefty-age-range.helpers';

@Component({
  selector: 'lefty-age-range',
  templateUrl: 'lefty-age-range.component.html',
  styleUrls: ['lefty-age-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyCardComponent],
})
export class LeftyAgeRangeComponent
  extends LeftyComponent
  implements AfterViewChecked
{
  constructor(private zone: NgZone) {
    super();
  }
  DEFAULT_BAR_WIDTH = 10;
  readonly displayChartName = input(true);
  readonly barWidth = input(this.DEFAULT_BAR_WIDTH);
  readonly femalePercentage = input(0);
  readonly withBorder = input(false);
  readonly card = input<ChartWithDataSet>({
    type: 'age_range',
    title: $localize`Age Range`,
    data: [],
  });

  @ViewChild('bar')
  barElRef?: ElementRef<HTMLDivElement>;

  categories: Signal<string[]> = computed(() => {
    return this.card().data.map((d) => d.name);
  });

  chartDataSet: Signal<DataSet[]> = computed(() => {
    if (this.card().data.length > 0) {
      return [
        {
          name: $localize`Female ${this.femalePercentage() !== 0 ? ': ' + LeftyFormat.percent(this.femalePercentage() / 100) : ''}`,
          values: this.card().data.map((d) => d.values[0]),
          color: LeftyColors.teal300,
        },
        {
          name: $localize`Male ${this.femalePercentage() !== 0 ? ': ' + LeftyFormat.percent((100 - this.femalePercentage()) / 100, { showZero: false }) : ''}`,
          values: this.card().data.map((d) => d.values[1]),
          color: LeftyColors.teal800,
        },
      ];
    }
    return [];
  });

  readonly isEmptyState = computed(() => isEmptyArray(this.chartDataSet()));

  generateBar(): void {
    this.zone.runOutsideAngular(() => {
      if (isNotNil(this.barElRef) && isNotEmptyArray(this.chartDataSet())) {
        generateBarChart(this.barElRef.nativeElement, this.chartDataSet(), {
          size: { height: 200 },
          withGrid: false,
          barPadding: 10,
          yFormatter: this.formatter,
          categories: this.categories(),
          barWidth: this.barWidth(),
          withLabels: true,
          config: {
            radius: 2,
          },
        });
      }
    });
  }

  formatter(value: number | Date | null): string {
    if (value === null || value instanceof Date) {
      return '';
    }
    return LeftyFormat.percent(value, {
      compact: true,
      maximumFractionDigits: 1,
    });
  }

  get chartText(): string {
    return $localize`Age of followers`;
  }

  ngAfterViewChecked(): void {
    this.generateBar();
  }
}
