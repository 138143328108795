import { CastingInfluencerCollaboratorSort } from '@frontend2/proto/librarian/proto/casting_pb';
import { InfluencerCastingStatus } from '@frontend2/proto/librarian/proto/common_pb';

export type FrontendCastingStatus = InfluencerCastingStatus | -1;
export type CastingTabId = 'Influencers' | 'Forecast';

export abstract class MessagesCasting {
  static sort(value: CastingInfluencerCollaboratorSort): string {
    switch (value) {
      case CastingInfluencerCollaboratorSort.DATE_ADDED_INFLUENCER_CASTING:
        return $localize`Date`;
      case CastingInfluencerCollaboratorSort.NAME_INFLUENCER_CASTING:
        return $localize`Name`;
    }
  }

  static status(value?: FrontendCastingStatus): string {
    switch (value) {
      case InfluencerCastingStatus.INFLUENCER_CASTING_PENDING:
        return $localize`Pending`;
      case InfluencerCastingStatus.INFLUENCER_CASTING_APPROVED:
        return $localize`Approved`;
      case InfluencerCastingStatus.INFLUENCER_CASTING_REJECTED:
        return $localize`Rejected`;
      default:
        return $localize`All Status`;
    }
  }

  static tab(value?: CastingTabId): string {
    switch (value) {
      case 'Forecast':
        return $localize`Forecast`;
      case 'Influencers':
        return $localize`Influencers`;
      default:
        return ``;
    }
  }
}
