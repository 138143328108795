<div
  [class.compact]="compact"
  [class.ghost]="ghost"
>
  <div
    class="value"
    [class.primary]="primary"
    [class.good]="isGood"
    [class.bad]="isBad"
  >
    {{ value }}
  </div>
  <div class="label">
    {{ label }}

    @if (hasHelpMessage) {
      <help-icon [leftyTooltip]="helpMessage"> </help-icon>
    }
    <ng-content select="[action]"></ng-content>
  </div>
</div>
