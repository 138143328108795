<lefty-card [withBorder]="true">
  <div class="header">
    <h3 i18n>Estimated Performances</h3>
  </div>
  @if (!isEmptyState()) {
    <div class="content">
      <lefty-data-table
        [loading]="isLoading()"
        [columns]="columns()"
        [rows]="rows().tableRows"
        [firstCellBold]="true"
        horizontalScroll
        class="no-padding no-border"
        [componentFactories]="componentFactories"
      >
      </lefty-data-table>
    </div>
  } @else if (isLoading()) {
    <div class="spinner-container">
      <lefty-spinner></lefty-spinner>
    </div>
  } @else {
    <div
      class="empty-state"
      i18n
    >
      No data available
    </div>
  }
</lefty-card>
