// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/frontend_misc.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { BoolValue, Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { CurrencyEnum, CustomizableFloat, DemoRequestSource, DemoRequestUtmSource, MediaValueCalculatorMethod, Network, PostType } from "../../common/proto/common_pb";
import { ChargebeePlan_PlanCurrency, SubscriptionType } from "./payments_pb";
import { Limitations, UserSettingKind } from "./common_pb";
import { InfluencersTier } from "./competitive_watch_pb";
import { Permission } from "../../common/proto/authentication_pb";
import { Billing } from "../../common/proto/chargebee_pb";

/**
 * @generated from enum librarian.proto.DemoRequestType
 */
export enum DemoRequestType {
  /**
   * @generated from enum value: DEMO_REQUEST_UNKNOWN = 0;
   */
  DEMO_REQUEST_UNKNOWN = 0,

  /**
   * @generated from enum value: REGULAR_DEMO_REQUEST = 1;
   */
  REGULAR_DEMO_REQUEST = 1,

  /**
   * anything requested from lefty.io that is linked to a download url and article url
   *
   * @generated from enum value: MATERIAL_REQUEST = 16;
   */
  MATERIAL_REQUEST = 16,
}
// Retrieve enum metadata with: proto3.getEnumType(DemoRequestType)
proto3.util.setEnumType(DemoRequestType, "librarian.proto.DemoRequestType", [
  { no: 0, name: "DEMO_REQUEST_UNKNOWN" },
  { no: 1, name: "REGULAR_DEMO_REQUEST" },
  { no: 16, name: "MATERIAL_REQUEST" },
]);

/**
 * @generated from enum librarian.proto.CompanyType
 */
export enum CompanyType {
  /**
   * @generated from enum value: COMPANY_TYPE_UNDEFINED = 0;
   */
  COMPANY_TYPE_UNDEFINED = 0,

  /**
   * @generated from enum value: BRAND = 1;
   */
  BRAND = 1,

  /**
   * @generated from enum value: AGENCY = 2;
   */
  AGENCY = 2,

  /**
   * @generated from enum value: OTHER_TYPE = 3;
   */
  OTHER_TYPE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(CompanyType)
proto3.util.setEnumType(CompanyType, "librarian.proto.CompanyType", [
  { no: 0, name: "COMPANY_TYPE_UNDEFINED" },
  { no: 1, name: "BRAND" },
  { no: 2, name: "AGENCY" },
  { no: 3, name: "OTHER_TYPE" },
]);

/**
 * @generated from enum librarian.proto.CompanySize
 */
export enum CompanySize {
  /**
   * @generated from enum value: COMPANY_SIZE_UNDEFINED = 0;
   */
  COMPANY_SIZE_UNDEFINED = 0,

  /**
   * @generated from enum value: LESS_THAN_10 = 1;
   */
  LESS_THAN_10 = 1,

  /**
   * @generated from enum value: BETWEEN_10_AND_50 = 2;
   */
  BETWEEN_10_AND_50 = 2,

  /**
   * @generated from enum value: BETWEEN_50_AND_200 = 3;
   */
  BETWEEN_50_AND_200 = 3,

  /**
   * @generated from enum value: BETWEEN_200_AND_1000 = 4;
   */
  BETWEEN_200_AND_1000 = 4,

  /**
   * @generated from enum value: MORE_THAN_1000 = 5;
   */
  MORE_THAN_1000 = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(CompanySize)
proto3.util.setEnumType(CompanySize, "librarian.proto.CompanySize", [
  { no: 0, name: "COMPANY_SIZE_UNDEFINED" },
  { no: 1, name: "LESS_THAN_10" },
  { no: 2, name: "BETWEEN_10_AND_50" },
  { no: 3, name: "BETWEEN_50_AND_200" },
  { no: 4, name: "BETWEEN_200_AND_1000" },
  { no: 5, name: "MORE_THAN_1000" },
]);

/**
 * @generated from enum librarian.proto.ScreenName
 */
export enum ScreenName {
  /**
   * @generated from enum value: INFLUENCER_AFFILIATION = 0;
   */
  INFLUENCER_AFFILIATION = 0,

  /**
   * @generated from enum value: AFFILIATION_AFFILIATES = 1;
   */
  AFFILIATION_AFFILIATES = 1,

  /**
   * @generated from enum value: AFFILIATION_LINKS = 2;
   */
  AFFILIATION_LINKS = 2,

  /**
   * @generated from enum value: AFFILIATION_CONVERSIONS = 3;
   */
  AFFILIATION_CONVERSIONS = 3,

  /**
   * @generated from enum value: CAMPAIGN_INFLUENCERS = 4;
   */
  CAMPAIGN_INFLUENCERS = 4,

  /**
   * @generated from enum value: CAMPAIGN_REPORT_DETAILS = 5;
   */
  CAMPAIGN_REPORT_DETAILS = 5,

  /**
   * @generated from enum value: DIRECTORY_INFLUENCERS = 6;
   */
  DIRECTORY_INFLUENCERS = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(ScreenName)
proto3.util.setEnumType(ScreenName, "librarian.proto.ScreenName", [
  { no: 0, name: "INFLUENCER_AFFILIATION" },
  { no: 1, name: "AFFILIATION_AFFILIATES" },
  { no: 2, name: "AFFILIATION_LINKS" },
  { no: 3, name: "AFFILIATION_CONVERSIONS" },
  { no: 4, name: "CAMPAIGN_INFLUENCERS" },
  { no: 5, name: "CAMPAIGN_REPORT_DETAILS" },
  { no: 6, name: "DIRECTORY_INFLUENCERS" },
]);

/**
 * @generated from enum librarian.proto.SessionCookieType
 */
export enum SessionCookieType {
  /**
   * @generated from enum value: SESSION_COOKIE_UNDEFINED = 0;
   */
  SESSION_COOKIE_UNDEFINED = 0,

  /**
   * @generated from enum value: SESSION_COOKIE_ALFRED = 1;
   */
  SESSION_COOKIE_ALFRED = 1,

  /**
   * @generated from enum value: SESSION_COOKIE_IMPERSONATE = 2;
   */
  SESSION_COOKIE_IMPERSONATE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SessionCookieType)
proto3.util.setEnumType(SessionCookieType, "librarian.proto.SessionCookieType", [
  { no: 0, name: "SESSION_COOKIE_UNDEFINED" },
  { no: 1, name: "SESSION_COOKIE_ALFRED" },
  { no: 2, name: "SESSION_COOKIE_IMPERSONATE" },
]);

/**
 * @generated from message librarian.proto.DemoRequest
 */
export class DemoRequest extends Message<DemoRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string company = 2;
   */
  company = "";

  /**
   * we keep name for now until the frontend is completly release
   *
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.StringValue country = 4;
   */
  country?: string;

  /**
   * @generated from field: google.protobuf.StringValue message = 5;
   */
  message?: string;

  /**
   * @generated from field: string first_name = 6;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 7;
   */
  lastName = "";

  /**
   * required
   *
   * @generated from field: string phone = 8;
   */
  phone = "";

  /**
   * demo request type for our affiliation programs
   *
   * @generated from field: librarian.proto.DemoRequestType type = 9;
   */
  type = DemoRequestType.DEMO_REQUEST_UNKNOWN;

  /**
   * @generated from field: string lang = 10;
   */
  lang = "";

  /**
   * The clientID from Google Analytics will allow us to link some other stats to this client in the future.
   * Such as client qualified or not, this will allow us to measure the performance of different acquisition channels.
   *
   * @generated from field: string google_analytics_cliend_id = 11;
   */
  googleAnalyticsCliendId = "";

  /**
   * @generated from field: librarian.proto.CompanyType company_type = 12;
   */
  companyType = CompanyType.COMPANY_TYPE_UNDEFINED;

  /**
   * @generated from field: bool doing_influence = 13;
   */
  doingInfluence = false;

  /**
   * @generated from field: librarian.proto.CompanySize company_size = 14;
   */
  companySize = CompanySize.COMPANY_SIZE_UNDEFINED;

  /**
   * @generated from field: string industry = 15;
   */
  industry = "";

  /**
   * filled by user
   *
   * @generated from field: common.proto.DemoRequestSource source = 16;
   */
  source = DemoRequestSource.DEMO_SOURCE_UNDEFINED;

  /**
   * automatically filled when coming from Google/Linkedin Ads
   *
   * @generated from field: common.proto.DemoRequestUtmSource utm_source = 17;
   */
  utmSource = DemoRequestUtmSource.DEMO_UTM_SOURCE_UNDEFINED;

  constructor(data?: PartialMessage<DemoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DemoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "country", kind: "message", T: StringValue },
    { no: 5, name: "message", kind: "message", T: StringValue },
    { no: 6, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "type", kind: "enum", T: proto3.getEnumType(DemoRequestType) },
    { no: 10, name: "lang", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "google_analytics_cliend_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "company_type", kind: "enum", T: proto3.getEnumType(CompanyType) },
    { no: 13, name: "doing_influence", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "company_size", kind: "enum", T: proto3.getEnumType(CompanySize) },
    { no: 15, name: "industry", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "source", kind: "enum", T: proto3.getEnumType(DemoRequestSource) },
    { no: 17, name: "utm_source", kind: "enum", T: proto3.getEnumType(DemoRequestUtmSource) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DemoRequest {
    return new DemoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DemoRequest {
    return new DemoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DemoRequest {
    return new DemoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DemoRequest | PlainMessage<DemoRequest> | undefined, b: DemoRequest | PlainMessage<DemoRequest> | undefined): boolean {
    return proto3.util.equals(DemoRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.MaterialRequest
 */
export class MaterialRequest extends Message<MaterialRequest> {
  /**
   * required - path on webflow to retrive the file download url
   * DEPRECATED: we are migrating to prismic
   *
   * @generated from field: string webflow_slug = 7;
   */
  webflowSlug = "";

  /**
   * required - path on PRISMIC API to retrieve the file download url
   * so we can send it in email
   *
   * @generated from field: string prismic_document_url = 10;
   */
  prismicDocumentUrl = "";

  /**
   * required
   *
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * required
   *
   * @generated from field: string first_name = 3;
   */
  firstName = "";

  /**
   * required
   *
   * @generated from field: string last_name = 4;
   */
  lastName = "";

  /**
   * @generated from field: string company = 8;
   */
  company = "";

  /**
   * @generated from field: string country = 9;
   */
  country = "";

  /**
   * optional - If empty "en" will be assumed.
   *
   * @generated from field: string lang = 5;
   */
  lang = "";

  /**
   * optional
   * The clientID from Google Analytics will allow us to link some other stats to this client in the future.
   * Such as client qualified or not, this will allow us to measure the performance of different acquisition channels.
   *
   * @generated from field: string google_analytics_cliend_id = 6;
   */
  googleAnalyticsCliendId = "";

  /**
   * filled by user
   *
   * @generated from field: common.proto.DemoRequestSource source = 11;
   */
  source = DemoRequestSource.DEMO_SOURCE_UNDEFINED;

  /**
   * automatically filled when coming from Google/Linkedin Ads
   *
   * @generated from field: common.proto.DemoRequestUtmSource utm_source = 12;
   */
  utmSource = DemoRequestUtmSource.DEMO_UTM_SOURCE_UNDEFINED;

  constructor(data?: PartialMessage<MaterialRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.MaterialRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 7, name: "webflow_slug", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "prismic_document_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "lang", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "google_analytics_cliend_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "source", kind: "enum", T: proto3.getEnumType(DemoRequestSource) },
    { no: 12, name: "utm_source", kind: "enum", T: proto3.getEnumType(DemoRequestUtmSource) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MaterialRequest {
    return new MaterialRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MaterialRequest {
    return new MaterialRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MaterialRequest {
    return new MaterialRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MaterialRequest | PlainMessage<MaterialRequest> | undefined, b: MaterialRequest | PlainMessage<MaterialRequest> | undefined): boolean {
    return proto3.util.equals(MaterialRequest, a, b);
  }
}

/**
 * Represents a concrete user (customer) space that a customer can login to, in the event that their login
 * controls multiple users.
 *
 * @generated from message librarian.proto.CustomerSpace
 */
export class CustomerSpace extends Message<CustomerSpace> {
  /**
   * Index into the list of io.lefty.backend.library.model.User that the UserAccount manages.
   * Indexes are not necessarily contiguous, since they could skip deleted users.
   *
   * They are meant to be passed through lefty URLs in order to select the correct user for an account/browser tab.
   * eg: app.lefty.io/u/1/campaigns
   * 1 would be the idx_hint.
   *
   * @generated from field: int32 idx_hint = 1;
   */
  idxHint = 0;

  /**
   * Space user id (not owner id)
   *
   * @generated from field: int64 user_id = 3;
   */
  userId = protoInt64.zero;

  /**
   * Readable description, eg: "Guerlain Japan"
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.Int64Value team_id = 5;
   */
  teamId?: bigint;

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 4;
   */
  currency = CurrencyEnum.NA;

  constructor(data?: PartialMessage<CustomerSpace>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CustomerSpace";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "idx_hint", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "team_id", kind: "message", T: Int64Value },
    { no: 4, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CustomerSpace {
    return new CustomerSpace().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CustomerSpace {
    return new CustomerSpace().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CustomerSpace {
    return new CustomerSpace().fromJsonString(jsonString, options);
  }

  static equals(a: CustomerSpace | PlainMessage<CustomerSpace> | undefined, b: CustomerSpace | PlainMessage<CustomerSpace> | undefined): boolean {
    return proto3.util.equals(CustomerSpace, a, b);
  }
}

/**
 * @generated from message librarian.proto.CustomerSpaces
 */
export class CustomerSpaces extends Message<CustomerSpaces> {
  /**
   * @generated from field: repeated librarian.proto.CustomerSpace customer_spaces = 1;
   */
  customerSpaces: CustomerSpace[] = [];

  constructor(data?: PartialMessage<CustomerSpaces>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CustomerSpaces";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customer_spaces", kind: "message", T: CustomerSpace, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CustomerSpaces {
    return new CustomerSpaces().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CustomerSpaces {
    return new CustomerSpaces().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CustomerSpaces {
    return new CustomerSpaces().fromJsonString(jsonString, options);
  }

  static equals(a: CustomerSpaces | PlainMessage<CustomerSpaces> | undefined, b: CustomerSpaces | PlainMessage<CustomerSpaces> | undefined): boolean {
    return proto3.util.equals(CustomerSpaces, a, b);
  }
}

/**
 * This is returned on the is logged in call and provides more info about the user
 * being logged (this is basically bootstrapped user v2).
 *
 * @generated from message librarian.proto.LoggedBootstrapping
 */
export class LoggedBootstrapping extends Message<LoggedBootstrapping> {
  /**
   * @generated from field: bool is_logged = 1;
   */
  isLogged = false;

  /**
   * @generated from field: bool is_admin = 2;
   */
  isAdmin = false;

  /**
   * @generated from field: bool has_sso_access = 34;
   */
  hasSsoAccess = false;

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string team_owner_email = 16;
   */
  teamOwnerEmail = "";

  /**
   * @generated from field: librarian.proto.SubscriptionType subscription_type = 4;
   */
  subscriptionType = SubscriptionType.SUBSCRIPTION_TYPE_UNKNOWN;

  /**
   * @generated from field: bool has_valid_chargebee = 10;
   */
  hasValidChargebee = false;

  /**
   * @generated from field: librarian.proto.ChargebeePlan.PlanCurrency currency = 12;
   */
  currency = ChargebeePlan_PlanCurrency.PLAN_CURRENCY_UNKNOWN;

  /**
   * @generated from field: bool is_yearly = 13;
   */
  isYearly = false;

  /**
   * Those might be empty (if legacy user).
   *
   * @generated from field: string first_name = 5;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 6;
   */
  lastName = "";

  /**
   * @generated from field: librarian.proto.Limitations limitations = 8;
   */
  limitations?: Limitations;

  /**
   * @generated from field: repeated librarian.proto.Setting settings = 9;
   */
  settings: Setting[] = [];

  /**
   * @generated from field: bool is_kicked = 11;
   */
  isKicked = false;

  /**
   * @generated from field: bool is_team_owner = 14;
   */
  isTeamOwner = false;

  /**
   * This can be used to login to firebase as user.teamOwner.
   *
   * @generated from field: string firebase_token = 15;
   */
  firebaseToken = "";

  /**
   * @generated from field: bool dashboard_access = 24;
   */
  dashboardAccess = false;

  /**
   * @generated from field: bool can_toggle_influencer_tracking = 38;
   */
  canToggleInfluencerTracking = false;

  /**
   * @generated from field: common.proto.CurrencyEnum dashboard_currency = 31;
   */
  dashboardCurrency = CurrencyEnum.NA;

  /**
   * @generated from field: bool manual_classification_access = 26;
   */
  manualClassificationAccess = false;

  /**
   * @generated from field: bool api_access = 27;
   */
  apiAccess = false;

  /**
   * @generated from field: repeated common.proto.Network allowed_networks = 35;
   */
  allowedNetworks: Network[] = [];

  /**
   * This is only ever populated if there are more than 1 users available.
   * Hence, it will always have at least 2 entries.
   *
   * @generated from field: repeated librarian.proto.CustomerSpace customer_spaces = 20;
   */
  customerSpaces: CustomerSpace[] = [];

  /**
   * Not to be confused with ChargebeePlan.PlanCurrency.
   *
   * @generated from field: common.proto.CurrencyEnum user_currency = 29;
   */
  userCurrency = CurrencyEnum.NA;

  /**
   * @generated from field: common.proto.MediaValueCalculatorMethod media_value_calculator_method = 30;
   */
  mediaValueCalculatorMethod = MediaValueCalculatorMethod.DEFAULT;

  /**
   * Hint for the user that is currently selected. It only has meaning if 'customer_spaces' is populated.
   *
   * @generated from field: int32 space_idx_hint = 21;
   */
  spaceIdxHint = 0;

  /**
   * @generated from field: bool is_impersonating = 23;
   */
  isImpersonating = false;

  /**
   * @generated from field: repeated librarian.proto.InfluencersTier influencers_tiers = 32;
   */
  influencersTiers: InfluencersTier[] = [];

  /**
   * @generated from field: repeated librarian.proto.UserSettingKind user_settings = 36;
   */
  userSettings: UserSettingKind[] = [];

  /**
   * The intercom hash of the user Id to password protect our helpdesk as specified here :
   * https://www.intercom.com/help/en/articles/183-enable-identity-verification-for-web-and-mobile
   *
   * @generated from field: string intercom_hash = 33;
   */
  intercomHash = "";

  /**
   * @generated from field: repeated Permission permissions = 37;
   */
  permissions: Permission[] = [];

  /**
   * seek and destroy!!
   *
   * @generated from field: bool is_lefty_canary = 100;
   */
  isLeftyCanary = false;

  constructor(data?: PartialMessage<LoggedBootstrapping>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LoggedBootstrapping";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_logged", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "is_admin", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 34, name: "has_sso_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "team_owner_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subscription_type", kind: "enum", T: proto3.getEnumType(SubscriptionType) },
    { no: 10, name: "has_valid_chargebee", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "currency", kind: "enum", T: proto3.getEnumType(ChargebeePlan_PlanCurrency) },
    { no: 13, name: "is_yearly", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "limitations", kind: "message", T: Limitations },
    { no: 9, name: "settings", kind: "message", T: Setting, repeated: true },
    { no: 11, name: "is_kicked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "is_team_owner", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "firebase_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "dashboard_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 38, name: "can_toggle_influencer_tracking", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 31, name: "dashboard_currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 26, name: "manual_classification_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 27, name: "api_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 35, name: "allowed_networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 20, name: "customer_spaces", kind: "message", T: CustomerSpace, repeated: true },
    { no: 29, name: "user_currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 30, name: "media_value_calculator_method", kind: "enum", T: proto3.getEnumType(MediaValueCalculatorMethod) },
    { no: 21, name: "space_idx_hint", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 23, name: "is_impersonating", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 32, name: "influencers_tiers", kind: "message", T: InfluencersTier, repeated: true },
    { no: 36, name: "user_settings", kind: "enum", T: proto3.getEnumType(UserSettingKind), repeated: true },
    { no: 33, name: "intercom_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 37, name: "permissions", kind: "enum", T: proto3.getEnumType(Permission), repeated: true },
    { no: 100, name: "is_lefty_canary", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoggedBootstrapping {
    return new LoggedBootstrapping().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoggedBootstrapping {
    return new LoggedBootstrapping().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoggedBootstrapping {
    return new LoggedBootstrapping().fromJsonString(jsonString, options);
  }

  static equals(a: LoggedBootstrapping | PlainMessage<LoggedBootstrapping> | undefined, b: LoggedBootstrapping | PlainMessage<LoggedBootstrapping> | undefined): boolean {
    return proto3.util.equals(LoggedBootstrapping, a, b);
  }
}

/**
 * This bootstraps info about a campaign sharing token.
 *
 * @generated from message librarian.proto.SharingTokenBootstrapping
 */
export class SharingTokenBootstrapping extends Message<SharingTokenBootstrapping> {
  /**
   * True if this points to a valid token, false otherwise.
   *
   * @generated from field: bool is_valid = 1;
   */
  isValid = false;

  /**
   * If the plan that shared the campaign is expired (the token could still be valid if the campaign
   * was shared when the plan expired). This allows us to adapt the error message displayed.
   *
   * @generated from field: bool is_expired = 2;
   */
  isExpired = false;

  /**
   * True if this browser also has a valid login to Lefty.
   *
   * If we want to, this will allow us to display a little disclaimer that oh, you also have an account, here's a link
   * to go to the app.
   *
   * @generated from field: bool is_also_logged = 3;
   */
  isAlsoLogged = false;

  /**
   * Whether the plan of the author of the campaign allows to apply custom filters
   * (we don't want to expose directly the plan of the user because after all they may not want their
   * client to see that they are cheap assholes :) ).
   *
   * @generated from field: bool can_filter_reports = 4;
   */
  canFilterReports = false;

  constructor(data?: PartialMessage<SharingTokenBootstrapping>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SharingTokenBootstrapping";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "is_expired", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "is_also_logged", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "can_filter_reports", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SharingTokenBootstrapping {
    return new SharingTokenBootstrapping().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SharingTokenBootstrapping {
    return new SharingTokenBootstrapping().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SharingTokenBootstrapping {
    return new SharingTokenBootstrapping().fromJsonString(jsonString, options);
  }

  static equals(a: SharingTokenBootstrapping | PlainMessage<SharingTokenBootstrapping> | undefined, b: SharingTokenBootstrapping | PlainMessage<SharingTokenBootstrapping> | undefined): boolean {
    return proto3.util.equals(SharingTokenBootstrapping, a, b);
  }
}

/**
 * @generated from message librarian.proto.Setting
 */
export class Setting extends Message<Setting> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<Setting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Setting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Setting {
    return new Setting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Setting {
    return new Setting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Setting {
    return new Setting().fromJsonString(jsonString, options);
  }

  static equals(a: Setting | PlainMessage<Setting> | undefined, b: Setting | PlainMessage<Setting> | undefined): boolean {
    return proto3.util.equals(Setting, a, b);
  }
}

/**
 * @generated from message librarian.proto.LoginForm
 */
export class LoginForm extends Message<LoginForm> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  constructor(data?: PartialMessage<LoginForm>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LoginForm";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginForm {
    return new LoginForm().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginForm {
    return new LoginForm().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginForm {
    return new LoginForm().fromJsonString(jsonString, options);
  }

  static equals(a: LoginForm | PlainMessage<LoginForm> | undefined, b: LoginForm | PlainMessage<LoginForm> | undefined): boolean {
    return proto3.util.equals(LoginForm, a, b);
  }
}

/**
 * @generated from message librarian.proto.ChangePassword
 */
export class ChangePassword extends Message<ChangePassword> {
  /**
   * @generated from field: string old_password = 1;
   */
  oldPassword = "";

  /**
   * @generated from field: string new_password = 2;
   */
  newPassword = "";

  constructor(data?: PartialMessage<ChangePassword>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ChangePassword";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "old_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "new_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChangePassword {
    return new ChangePassword().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChangePassword {
    return new ChangePassword().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChangePassword {
    return new ChangePassword().fromJsonString(jsonString, options);
  }

  static equals(a: ChangePassword | PlainMessage<ChangePassword> | undefined, b: ChangePassword | PlainMessage<ChangePassword> | undefined): boolean {
    return proto3.util.equals(ChangePassword, a, b);
  }
}

/**
 * @generated from message librarian.proto.SignupRequest
 */
export class SignupRequest extends Message<SignupRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  /**
   * @generated from field: string first_name = 3;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 4;
   */
  lastName = "";

  /**
   * @generated from field: string company = 5;
   */
  company = "";

  /**
   * @generated from field: string phone = 6;
   */
  phone = "";

  /**
   * @generated from field: string country = 7;
   */
  country = "";

  /**
   * this field contains the sso identity provider config file name of the client
   *
   * @generated from field: string sso_config_file_name = 8;
   */
  ssoConfigFileName = "";

  /**
   * @generated from field: repeated common.proto.Network allowed_networks = 12;
   */
  allowedNetworks: Network[] = [];

  /**
   * @generated from field: bool dashboard_access = 10;
   */
  dashboardAccess = false;

  /**
   * @generated from field: google.protobuf.BoolValue admin_access = 11;
   */
  adminAccess?: boolean;

  /**
   * @generated from field: bool is_lefty_canary = 100;
   */
  isLeftyCanary = false;

  constructor(data?: PartialMessage<SignupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SignupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "sso_config_file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "allowed_networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 10, name: "dashboard_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "admin_access", kind: "message", T: BoolValue },
    { no: 100, name: "is_lefty_canary", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignupRequest {
    return new SignupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignupRequest {
    return new SignupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignupRequest {
    return new SignupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SignupRequest | PlainMessage<SignupRequest> | undefined, b: SignupRequest | PlainMessage<SignupRequest> | undefined): boolean {
    return proto3.util.equals(SignupRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateUserRequest
 */
export class UpdateUserRequest extends Message<UpdateUserRequest> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 2;
   */
  lastName = "";

  /**
   * @generated from field: google.protobuf.StringValue company = 3;
   */
  company?: string;

  /**
   * @generated from field: google.protobuf.StringValue phone = 4;
   */
  phone?: string;

  /**
   * @generated from field: common.proto.Billing billing = 5;
   */
  billing?: Billing;

  /**
   * admin usage
   *
   * @generated from field: int64 user_id = 6;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.SubscriptionType subscription_type = 7;
   */
  subscriptionType = SubscriptionType.SUBSCRIPTION_TYPE_UNKNOWN;

  /**
   * //
   *
   * @generated from field: google.protobuf.Timestamp trial_end = 8;
   */
  trialEnd?: Timestamp;

  /**
   * @generated from field: librarian.proto.UpdateUserRequest.UpdateNotificationSettings notification_settings = 9;
   */
  notificationSettings?: UpdateUserRequest_UpdateNotificationSettings;

  constructor(data?: PartialMessage<UpdateUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "company", kind: "message", T: StringValue },
    { no: 4, name: "phone", kind: "message", T: StringValue },
    { no: 5, name: "billing", kind: "message", T: Billing },
    { no: 6, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "subscription_type", kind: "enum", T: proto3.getEnumType(SubscriptionType) },
    { no: 8, name: "trial_end", kind: "message", T: Timestamp },
    { no: 9, name: "notification_settings", kind: "message", T: UpdateUserRequest_UpdateNotificationSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUserRequest {
    return new UpdateUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUserRequest {
    return new UpdateUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUserRequest {
    return new UpdateUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUserRequest | PlainMessage<UpdateUserRequest> | undefined, b: UpdateUserRequest | PlainMessage<UpdateUserRequest> | undefined): boolean {
    return proto3.util.equals(UpdateUserRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateUserRequest.UpdateNotificationSettings
 */
export class UpdateUserRequest_UpdateNotificationSettings extends Message<UpdateUserRequest_UpdateNotificationSettings> {
  /**
   * @generated from field: google.protobuf.BoolValue campaign_ended = 1;
   */
  campaignEnded?: boolean;

  /**
   * @generated from field: google.protobuf.BoolValue campaign_freezed = 2;
   */
  campaignFreezed?: boolean;

  /**
   * @generated from field: google.protobuf.BoolValue post_marked = 3;
   */
  postMarked?: boolean;

  /**
   * @generated from field: google.protobuf.BoolValue audience_report = 4;
   */
  audienceReport?: boolean;

  /**
   * @generated from field: google.protobuf.BoolValue campaign_report_ppt = 5;
   */
  campaignReportPpt?: boolean;

  /**
   * @generated from field: google.protobuf.BoolValue directory_ppt = 6;
   */
  directoryPpt?: boolean;

  /**
   * @generated from field: google.protobuf.BoolValue casting_notifications = 9;
   */
  castingNotifications?: boolean;

  constructor(data?: PartialMessage<UpdateUserRequest_UpdateNotificationSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateUserRequest.UpdateNotificationSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_ended", kind: "message", T: BoolValue },
    { no: 2, name: "campaign_freezed", kind: "message", T: BoolValue },
    { no: 3, name: "post_marked", kind: "message", T: BoolValue },
    { no: 4, name: "audience_report", kind: "message", T: BoolValue },
    { no: 5, name: "campaign_report_ppt", kind: "message", T: BoolValue },
    { no: 6, name: "directory_ppt", kind: "message", T: BoolValue },
    { no: 9, name: "casting_notifications", kind: "message", T: BoolValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUserRequest_UpdateNotificationSettings {
    return new UpdateUserRequest_UpdateNotificationSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUserRequest_UpdateNotificationSettings {
    return new UpdateUserRequest_UpdateNotificationSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUserRequest_UpdateNotificationSettings {
    return new UpdateUserRequest_UpdateNotificationSettings().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUserRequest_UpdateNotificationSettings | PlainMessage<UpdateUserRequest_UpdateNotificationSettings> | undefined, b: UpdateUserRequest_UpdateNotificationSettings | PlainMessage<UpdateUserRequest_UpdateNotificationSettings> | undefined): boolean {
    return proto3.util.equals(UpdateUserRequest_UpdateNotificationSettings, a, b);
  }
}

/**
 * @generated from message librarian.proto.SeedingForm
 */
export class SeedingForm extends Message<SeedingForm> {
  /**
   * @generated from field: string client_name = 1;
   */
  clientName = "";

  /**
   * @generated from field: string first_name = 2;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 3;
   */
  lastName = "";

  /**
   * @generated from field: string email = 4;
   */
  email = "";

  /**
   * @generated from field: string instagram_name = 5;
   */
  instagramName = "";

  /**
   * @generated from field: string youtube = 6;
   */
  youtube = "";

  /**
   * @generated from field: string blog = 7;
   */
  blog = "";

  /**
   * @generated from field: string address = 8;
   */
  address = "";

  /**
   * @generated from field: string postal_code = 9;
   */
  postalCode = "";

  /**
   * @generated from field: string city = 10;
   */
  city = "";

  /**
   * @generated from field: string country = 11;
   */
  country = "";

  /**
   * @generated from field: string phone = 12;
   */
  phone = "";

  /**
   * This field allows to save custom data.
   * For example in our David Daper campaign, the reference of the watch the influencer wants.
   *
   * @generated from field: string custom = 13;
   */
  custom = "";

  constructor(data?: PartialMessage<SeedingForm>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SeedingForm";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "instagram_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "youtube", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "blog", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "custom", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SeedingForm {
    return new SeedingForm().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SeedingForm {
    return new SeedingForm().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SeedingForm {
    return new SeedingForm().fromJsonString(jsonString, options);
  }

  static equals(a: SeedingForm | PlainMessage<SeedingForm> | undefined, b: SeedingForm | PlainMessage<SeedingForm> | undefined): boolean {
    return proto3.util.equals(SeedingForm, a, b);
  }
}

/**
 * @generated from message librarian.proto.ImpersonationLink
 */
export class ImpersonationLink extends Message<ImpersonationLink> {
  /**
   * @generated from oneof librarian.proto.ImpersonationLink.impersonate_target
   */
  impersonateTarget: {
    /**
     * @generated from field: string customer_email = 1;
     */
    value: string;
    case: "customerEmail";
  } | {
    /**
     * For convenience we support: the author's email, the author instagram id, the author instagram user name.
     * It's SLACK's tasks to be smart about which one to send.
     *
     * @generated from field: string author_email = 3;
     */
    value: string;
    case: "authorEmail";
  } | {
    /**
     * @generated from field: string author_lefty_id = 4;
     */
    value: string;
    case: "authorLeftyId";
  } | {
    /**
     * @generated from field: string author_username = 5;
     */
    value: string;
    case: "authorUsername";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string host = 2;
   */
  host = "";

  constructor(data?: PartialMessage<ImpersonationLink>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ImpersonationLink";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customer_email", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "impersonate_target" },
    { no: 3, name: "author_email", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "impersonate_target" },
    { no: 4, name: "author_lefty_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "impersonate_target" },
    { no: 5, name: "author_username", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "impersonate_target" },
    { no: 2, name: "host", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImpersonationLink {
    return new ImpersonationLink().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImpersonationLink {
    return new ImpersonationLink().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImpersonationLink {
    return new ImpersonationLink().fromJsonString(jsonString, options);
  }

  static equals(a: ImpersonationLink | PlainMessage<ImpersonationLink> | undefined, b: ImpersonationLink | PlainMessage<ImpersonationLink> | undefined): boolean {
    return proto3.util.equals(ImpersonationLink, a, b);
  }
}

/**
 * @generated from message librarian.proto.ImpersonationRequest
 */
export class ImpersonationRequest extends Message<ImpersonationRequest> {
  /**
   * @generated from oneof librarian.proto.ImpersonationRequest.impersonate_target
   */
  impersonateTarget: {
    /**
     * @generated from field: int64 user_account_id = 1;
     */
    value: bigint;
    case: "userAccountId";
  } | {
    /**
     * @generated from field: int64 author_id = 4;
     */
    value: bigint;
    case: "authorId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: google.protobuf.Timestamp created = 2;
   */
  created?: Timestamp;

  /**
   * where the impersonating user shall be redirected after.
   *
   * @generated from field: string redirect_host = 3;
   */
  redirectHost = "";

  constructor(data?: PartialMessage<ImpersonationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ImpersonationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "impersonate_target" },
    { no: 4, name: "author_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "impersonate_target" },
    { no: 2, name: "created", kind: "message", T: Timestamp },
    { no: 3, name: "redirect_host", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImpersonationRequest {
    return new ImpersonationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImpersonationRequest {
    return new ImpersonationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImpersonationRequest {
    return new ImpersonationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ImpersonationRequest | PlainMessage<ImpersonationRequest> | undefined, b: ImpersonationRequest | PlainMessage<ImpersonationRequest> | undefined): boolean {
    return proto3.util.equals(ImpersonationRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ImpersonateResult
 */
export class ImpersonateResult extends Message<ImpersonateResult> {
  /**
   * Cookie to write.
   *
   * @generated from field: string cookie = 1;
   */
  cookie = "";

  /**
   * Where to redirect the user.
   *
   * @generated from field: string redirect_host = 2;
   */
  redirectHost = "";

  /**
   * @generated from field: bool is_creator = 3;
   */
  isCreator = false;

  constructor(data?: PartialMessage<ImpersonateResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ImpersonateResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cookie", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "redirect_host", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_creator", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImpersonateResult {
    return new ImpersonateResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImpersonateResult {
    return new ImpersonateResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImpersonateResult {
    return new ImpersonateResult().fromJsonString(jsonString, options);
  }

  static equals(a: ImpersonateResult | PlainMessage<ImpersonateResult> | undefined, b: ImpersonateResult | PlainMessage<ImpersonateResult> | undefined): boolean {
    return proto3.util.equals(ImpersonateResult, a, b);
  }
}

/**
 * @generated from message librarian.proto.ReconnectSessionRequest
 */
export class ReconnectSessionRequest extends Message<ReconnectSessionRequest> {
  /**
   * @generated from field: bool force = 1;
   */
  force = false;

  constructor(data?: PartialMessage<ReconnectSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ReconnectSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "force", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReconnectSessionRequest {
    return new ReconnectSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReconnectSessionRequest {
    return new ReconnectSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReconnectSessionRequest {
    return new ReconnectSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ReconnectSessionRequest | PlainMessage<ReconnectSessionRequest> | undefined, b: ReconnectSessionRequest | PlainMessage<ReconnectSessionRequest> | undefined): boolean {
    return proto3.util.equals(ReconnectSessionRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UserProfile
 */
export class UserProfile extends Message<UserProfile> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 2;
   */
  lastName = "";

  /**
   * @generated from field: google.protobuf.StringValue phone = 3;
   */
  phone?: string;

  /**
   * @generated from field: google.protobuf.StringValue company = 4;
   */
  company?: string;

  /**
   * @generated from field: common.proto.Billing billing = 5;
   */
  billing?: Billing;

  /**
   * admin usage
   *
   * @generated from field: int64 id = 6;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: int64 user_account_id = 15;
   */
  userAccountId = protoInt64.zero;

  /**
   * @generated from field: string email = 7;
   */
  email = "";

  /**
   * @generated from field: librarian.proto.UserProfile team_owner = 8;
   */
  teamOwner?: UserProfile;

  /**
   * @generated from field: librarian.proto.SubscriptionType subscription_type = 9;
   */
  subscriptionType = SubscriptionType.SUBSCRIPTION_TYPE_UNKNOWN;

  /**
   * Not set meaning it never expires.
   *
   * @generated from field: google.protobuf.Timestamp subscription_expires = 14;
   */
  subscriptionExpires?: Timestamp;

  /**
   * @generated from field: bool has_valid_chargebee = 10;
   */
  hasValidChargebee = false;

  /**
   * @generated from field: google.protobuf.Timestamp created = 11;
   */
  created?: Timestamp;

  /**
   * //
   *
   * @generated from field: librarian.proto.Limitations limitations = 12;
   */
  limitations?: Limitations;

  /**
   * @generated from field: librarian.proto.UserProfile.NotificationSettings notification_settings = 13;
   */
  notificationSettings?: UserProfile_NotificationSettings;

  constructor(data?: PartialMessage<UserProfile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UserProfile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone", kind: "message", T: StringValue },
    { no: 4, name: "company", kind: "message", T: StringValue },
    { no: 5, name: "billing", kind: "message", T: Billing },
    { no: 6, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 15, name: "user_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "team_owner", kind: "message", T: UserProfile },
    { no: 9, name: "subscription_type", kind: "enum", T: proto3.getEnumType(SubscriptionType) },
    { no: 14, name: "subscription_expires", kind: "message", T: Timestamp },
    { no: 10, name: "has_valid_chargebee", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "created", kind: "message", T: Timestamp },
    { no: 12, name: "limitations", kind: "message", T: Limitations },
    { no: 13, name: "notification_settings", kind: "message", T: UserProfile_NotificationSettings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserProfile {
    return new UserProfile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserProfile {
    return new UserProfile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserProfile {
    return new UserProfile().fromJsonString(jsonString, options);
  }

  static equals(a: UserProfile | PlainMessage<UserProfile> | undefined, b: UserProfile | PlainMessage<UserProfile> | undefined): boolean {
    return proto3.util.equals(UserProfile, a, b);
  }
}

/**
 * @generated from message librarian.proto.UserProfile.NotificationSettings
 */
export class UserProfile_NotificationSettings extends Message<UserProfile_NotificationSettings> {
  /**
   * @generated from field: bool campaign_ended = 1;
   */
  campaignEnded = false;

  /**
   * @generated from field: bool campaign_freezed = 2;
   */
  campaignFreezed = false;

  /**
   * @generated from field: bool post_marked = 3;
   */
  postMarked = false;

  /**
   * @generated from field: bool audience_report = 4;
   */
  audienceReport = false;

  /**
   * @generated from field: bool campaign_report_ppt = 5;
   */
  campaignReportPpt = false;

  /**
   * @generated from field: bool directory_ppt = 6;
   */
  directoryPpt = false;

  /**
   * @generated from field: bool casting_notifications = 9;
   */
  castingNotifications = false;

  constructor(data?: PartialMessage<UserProfile_NotificationSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UserProfile.NotificationSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_ended", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "campaign_freezed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "post_marked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "audience_report", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "campaign_report_ppt", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "directory_ppt", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "casting_notifications", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserProfile_NotificationSettings {
    return new UserProfile_NotificationSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserProfile_NotificationSettings {
    return new UserProfile_NotificationSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserProfile_NotificationSettings {
    return new UserProfile_NotificationSettings().fromJsonString(jsonString, options);
  }

  static equals(a: UserProfile_NotificationSettings | PlainMessage<UserProfile_NotificationSettings> | undefined, b: UserProfile_NotificationSettings | PlainMessage<UserProfile_NotificationSettings> | undefined): boolean {
    return proto3.util.equals(UserProfile_NotificationSettings, a, b);
  }
}

/**
 * @generated from message librarian.proto.ResetPasswordRequest
 */
export class ResetPasswordRequest extends Message<ResetPasswordRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  constructor(data?: PartialMessage<ResetPasswordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ResetPasswordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetPasswordRequest {
    return new ResetPasswordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetPasswordRequest {
    return new ResetPasswordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetPasswordRequest {
    return new ResetPasswordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResetPasswordRequest | PlainMessage<ResetPasswordRequest> | undefined, b: ResetPasswordRequest | PlainMessage<ResetPasswordRequest> | undefined): boolean {
    return proto3.util.equals(ResetPasswordRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.PostValueSettingsResp
 */
export class PostValueSettingsResp extends Message<PostValueSettingsResp> {
  /**
   * @generated from field: repeated librarian.proto.PostValueSettingsResp.PostValueSetting cpms = 8;
   */
  cpms: PostValueSettingsResp_PostValueSetting[] = [];

  constructor(data?: PartialMessage<PostValueSettingsResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PostValueSettingsResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 8, name: "cpms", kind: "message", T: PostValueSettingsResp_PostValueSetting, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostValueSettingsResp {
    return new PostValueSettingsResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostValueSettingsResp {
    return new PostValueSettingsResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostValueSettingsResp {
    return new PostValueSettingsResp().fromJsonString(jsonString, options);
  }

  static equals(a: PostValueSettingsResp | PlainMessage<PostValueSettingsResp> | undefined, b: PostValueSettingsResp | PlainMessage<PostValueSettingsResp> | undefined): boolean {
    return proto3.util.equals(PostValueSettingsResp, a, b);
  }
}

/**
 * @generated from message librarian.proto.PostValueSettingsResp.PostValueSetting
 */
export class PostValueSettingsResp_PostValueSetting extends Message<PostValueSettingsResp_PostValueSetting> {
  /**
   * Either network or post type depending if there is unique CPM for all post types of the network or not.
   * Note(mateo) for now, in the end, we always use post type
   *
   * @generated from oneof librarian.proto.PostValueSettingsResp.PostValueSetting.the_target
   */
  theTarget: {
    /**
     * if network is folded
     *
     * @generated from field: common.proto.Network network = 1;
     */
    value: Network;
    case: "network";
  } | {
    /**
     * if CPM per post type in network
     *
     * @generated from field: common.proto.PostType post_type = 2;
     */
    value: PostType;
    case: "postType";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: common.proto.CustomizableFloat cpm = 3;
   */
  cpm?: CustomizableFloat;

  constructor(data?: PartialMessage<PostValueSettingsResp_PostValueSetting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PostValueSettingsResp.PostValueSetting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network), oneof: "the_target" },
    { no: 2, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType), oneof: "the_target" },
    { no: 3, name: "cpm", kind: "message", T: CustomizableFloat },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostValueSettingsResp_PostValueSetting {
    return new PostValueSettingsResp_PostValueSetting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostValueSettingsResp_PostValueSetting {
    return new PostValueSettingsResp_PostValueSetting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostValueSettingsResp_PostValueSetting {
    return new PostValueSettingsResp_PostValueSetting().fromJsonString(jsonString, options);
  }

  static equals(a: PostValueSettingsResp_PostValueSetting | PlainMessage<PostValueSettingsResp_PostValueSetting> | undefined, b: PostValueSettingsResp_PostValueSetting | PlainMessage<PostValueSettingsResp_PostValueSetting> | undefined): boolean {
    return proto3.util.equals(PostValueSettingsResp_PostValueSetting, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdatePostValueSetting
 */
export class UpdatePostValueSetting extends Message<UpdatePostValueSetting> {
  /**
   * Either network or post type depending if there is unique CPM for all post types of the network or not.
   *
   * @generated from oneof librarian.proto.UpdatePostValueSetting.the_target
   */
  theTarget: {
    /**
     * if network is folded
     *
     * @generated from field: common.proto.Network network = 3;
     */
    value: Network;
    case: "network";
  } | {
    /**
     * if CPM per post type in network
     *
     * @generated from field: common.proto.PostType post_type = 4;
     */
    value: PostType;
    case: "postType";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from oneof librarian.proto.UpdatePostValueSetting.the_update
   */
  theUpdate: {
    /**
     * @generated from field: float new_value = 1;
     */
    value: number;
    case: "newValue";
  } | {
    /**
     * This is just a marker to set the oneof case, the value is actually ignored (meaning: we will reset to default
     * even if the bool is false :)).
     *
     * @generated from field: bool reset_to_default = 2;
     */
    value: boolean;
    case: "resetToDefault";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UpdatePostValueSetting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdatePostValueSetting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "network", kind: "enum", T: proto3.getEnumType(Network), oneof: "the_target" },
    { no: 4, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType), oneof: "the_target" },
    { no: 1, name: "new_value", kind: "scalar", T: 2 /* ScalarType.FLOAT */, oneof: "the_update" },
    { no: 2, name: "reset_to_default", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "the_update" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePostValueSetting {
    return new UpdatePostValueSetting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePostValueSetting {
    return new UpdatePostValueSetting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePostValueSetting {
    return new UpdatePostValueSetting().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePostValueSetting | PlainMessage<UpdatePostValueSetting> | undefined, b: UpdatePostValueSetting | PlainMessage<UpdatePostValueSetting> | undefined): boolean {
    return proto3.util.equals(UpdatePostValueSetting, a, b);
  }
}

/**
 * Anything not present will be untouched, everything else will be persisted.
 * The frontend shall not send values that were default as persisted.
 *
 * @generated from message librarian.proto.UpdatePostValueSettingsReq
 */
export class UpdatePostValueSettingsReq extends Message<UpdatePostValueSettingsReq> {
  /**
   * If not set, nothing happens for network/post tpe cpm. Else it is either an update to the new given value, or a reset to default.
   * Note that resetting to default is not the same as setting the default value manually (the latter wouldn't reflect
   * if we ever change our default values).
   *
   * @generated from field: repeated librarian.proto.UpdatePostValueSetting cpms = 8;
   */
  cpms: UpdatePostValueSetting[] = [];

  constructor(data?: PartialMessage<UpdatePostValueSettingsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdatePostValueSettingsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 8, name: "cpms", kind: "message", T: UpdatePostValueSetting, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePostValueSettingsReq {
    return new UpdatePostValueSettingsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePostValueSettingsReq {
    return new UpdatePostValueSettingsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePostValueSettingsReq {
    return new UpdatePostValueSettingsReq().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePostValueSettingsReq | PlainMessage<UpdatePostValueSettingsReq> | undefined, b: UpdatePostValueSettingsReq | PlainMessage<UpdatePostValueSettingsReq> | undefined): boolean {
    return proto3.util.equals(UpdatePostValueSettingsReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateCurrencyReq
 */
export class UpdateCurrencyReq extends Message<UpdateCurrencyReq> {
  /**
   * @generated from field: common.proto.CurrencyEnum currency = 1;
   */
  currency = CurrencyEnum.NA;

  constructor(data?: PartialMessage<UpdateCurrencyReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateCurrencyReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCurrencyReq {
    return new UpdateCurrencyReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCurrencyReq {
    return new UpdateCurrencyReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCurrencyReq {
    return new UpdateCurrencyReq().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCurrencyReq | PlainMessage<UpdateCurrencyReq> | undefined, b: UpdateCurrencyReq | PlainMessage<UpdateCurrencyReq> | undefined): boolean {
    return proto3.util.equals(UpdateCurrencyReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.APIParamsResp
 */
export class APIParamsResp extends Message<APIParamsResp> {
  /**
   * @generated from field: string bearer_token = 1;
   */
  bearerToken = "";

  /**
   * @generated from field: string content_webhook_url = 2;
   */
  contentWebhookUrl = "";

  /**
   * @generated from field: bool send_content_notifications = 3;
   */
  sendContentNotifications = false;

  constructor(data?: PartialMessage<APIParamsResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.APIParamsResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bearer_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content_webhook_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "send_content_notifications", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): APIParamsResp {
    return new APIParamsResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): APIParamsResp {
    return new APIParamsResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): APIParamsResp {
    return new APIParamsResp().fromJsonString(jsonString, options);
  }

  static equals(a: APIParamsResp | PlainMessage<APIParamsResp> | undefined, b: APIParamsResp | PlainMessage<APIParamsResp> | undefined): boolean {
    return proto3.util.equals(APIParamsResp, a, b);
  }
}

/**
 * @generated from message librarian.proto.APIParamsReq
 */
export class APIParamsReq extends Message<APIParamsReq> {
  /**
   * @generated from field: google.protobuf.StringValue content_webhook_url = 1;
   */
  contentWebhookUrl?: string;

  /**
   * @generated from field: google.protobuf.BoolValue send_content_notifications = 2;
   */
  sendContentNotifications?: boolean;

  constructor(data?: PartialMessage<APIParamsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.APIParamsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content_webhook_url", kind: "message", T: StringValue },
    { no: 2, name: "send_content_notifications", kind: "message", T: BoolValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): APIParamsReq {
    return new APIParamsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): APIParamsReq {
    return new APIParamsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): APIParamsReq {
    return new APIParamsReq().fromJsonString(jsonString, options);
  }

  static equals(a: APIParamsReq | PlainMessage<APIParamsReq> | undefined, b: APIParamsReq | PlainMessage<APIParamsReq> | undefined): boolean {
    return proto3.util.equals(APIParamsReq, a, b);
  }
}

/**
 * *
 * a user's table configuration
 *
 * @generated from message librarian.proto.ScreenConfig
 */
export class ScreenConfig extends Message<ScreenConfig> {
  /**
   * @generated from field: librarian.proto.ScreenName screen_name = 1;
   */
  screenName = ScreenName.INFLUENCER_AFFILIATION;

  /**
   * @generated from field: repeated string hidden_column = 3;
   */
  hiddenColumn: string[] = [];

  constructor(data?: PartialMessage<ScreenConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ScreenConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "screen_name", kind: "enum", T: proto3.getEnumType(ScreenName) },
    { no: 3, name: "hidden_column", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScreenConfig {
    return new ScreenConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScreenConfig {
    return new ScreenConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScreenConfig {
    return new ScreenConfig().fromJsonString(jsonString, options);
  }

  static equals(a: ScreenConfig | PlainMessage<ScreenConfig> | undefined, b: ScreenConfig | PlainMessage<ScreenConfig> | undefined): boolean {
    return proto3.util.equals(ScreenConfig, a, b);
  }
}

/**
 * *
 * A map of config names with their config
 *
 * @generated from message librarian.proto.ScreenConfigs
 */
export class ScreenConfigs extends Message<ScreenConfigs> {
  /**
   * @generated from field: repeated librarian.proto.ScreenConfig configs = 1;
   */
  configs: ScreenConfig[] = [];

  constructor(data?: PartialMessage<ScreenConfigs>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ScreenConfigs";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "configs", kind: "message", T: ScreenConfig, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScreenConfigs {
    return new ScreenConfigs().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScreenConfigs {
    return new ScreenConfigs().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScreenConfigs {
    return new ScreenConfigs().fromJsonString(jsonString, options);
  }

  static equals(a: ScreenConfigs | PlainMessage<ScreenConfigs> | undefined, b: ScreenConfigs | PlainMessage<ScreenConfigs> | undefined): boolean {
    return proto3.util.equals(ScreenConfigs, a, b);
  }
}

/**
 * @generated from message librarian.proto.ScreenConfigRequest
 */
export class ScreenConfigRequest extends Message<ScreenConfigRequest> {
  /**
   * @generated from field: librarian.proto.ScreenName screen_name = 1;
   */
  screenName = ScreenName.INFLUENCER_AFFILIATION;

  constructor(data?: PartialMessage<ScreenConfigRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ScreenConfigRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "screen_name", kind: "enum", T: proto3.getEnumType(ScreenName) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScreenConfigRequest {
    return new ScreenConfigRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScreenConfigRequest {
    return new ScreenConfigRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScreenConfigRequest {
    return new ScreenConfigRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ScreenConfigRequest | PlainMessage<ScreenConfigRequest> | undefined, b: ScreenConfigRequest | PlainMessage<ScreenConfigRequest> | undefined): boolean {
    return proto3.util.equals(ScreenConfigRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.SsoLoginResponse
 */
export class SsoLoginResponse extends Message<SsoLoginResponse> {
  /**
   * the url where we will sending the post request
   *
   * @generated from field: string redirection_url = 1;
   */
  redirectionUrl = "";

  /**
   * the values that we will send to the identity provider
   *
   * @generated from field: repeated librarian.proto.KeyValue formValues = 2;
   */
  formValues: KeyValue[] = [];

  constructor(data?: PartialMessage<SsoLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SsoLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "redirection_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "formValues", kind: "message", T: KeyValue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SsoLoginResponse {
    return new SsoLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SsoLoginResponse {
    return new SsoLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SsoLoginResponse {
    return new SsoLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SsoLoginResponse | PlainMessage<SsoLoginResponse> | undefined, b: SsoLoginResponse | PlainMessage<SsoLoginResponse> | undefined): boolean {
    return proto3.util.equals(SsoLoginResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.KeyValue
 */
export class KeyValue extends Message<KeyValue> {
  /**
   * @generated from field: string key = 1;
   */
  key = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<KeyValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.KeyValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KeyValue {
    return new KeyValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KeyValue {
    return new KeyValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KeyValue {
    return new KeyValue().fromJsonString(jsonString, options);
  }

  static equals(a: KeyValue | PlainMessage<KeyValue> | undefined, b: KeyValue | PlainMessage<KeyValue> | undefined): boolean {
    return proto3.util.equals(KeyValue, a, b);
  }
}

/**
 * the object that identity provider return to us after user authentication
 *
 * @generated from message librarian.proto.SsoAssertion
 */
export class SsoAssertion extends Message<SsoAssertion> {
  /**
   * @generated from oneof librarian.proto.SsoAssertion.value
   */
  value: {
    /**
     * we might use openId tokens for other identity providers later
     *
     * @generated from field: string saml_response = 1;
     */
    value: string;
    case: "samlResponse";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SsoAssertion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SsoAssertion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_response", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SsoAssertion {
    return new SsoAssertion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SsoAssertion {
    return new SsoAssertion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SsoAssertion {
    return new SsoAssertion().fromJsonString(jsonString, options);
  }

  static equals(a: SsoAssertion | PlainMessage<SsoAssertion> | undefined, b: SsoAssertion | PlainMessage<SsoAssertion> | undefined): boolean {
    return proto3.util.equals(SsoAssertion, a, b);
  }
}

/**
 * @generated from message librarian.proto.SsoLogoutRequest
 */
export class SsoLogoutRequest extends Message<SsoLogoutRequest> {
  /**
   * @generated from oneof librarian.proto.SsoLogoutRequest.value
   */
  value: {
    /**
     * we might use other formats as logout request later
     *
     * @generated from field: librarian.proto.SsoLogoutRequest.SAMLRequest saml_request = 1;
     */
    value: SsoLogoutRequest_SAMLRequest;
    case: "samlRequest";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SsoLogoutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SsoLogoutRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_request", kind: "message", T: SsoLogoutRequest_SAMLRequest, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SsoLogoutRequest {
    return new SsoLogoutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SsoLogoutRequest {
    return new SsoLogoutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SsoLogoutRequest {
    return new SsoLogoutRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SsoLogoutRequest | PlainMessage<SsoLogoutRequest> | undefined, b: SsoLogoutRequest | PlainMessage<SsoLogoutRequest> | undefined): boolean {
    return proto3.util.equals(SsoLogoutRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.SsoLogoutRequest.SAMLRequest
 */
export class SsoLogoutRequest_SAMLRequest extends Message<SsoLogoutRequest_SAMLRequest> {
  /**
   * @generated from field: string request = 1;
   */
  request = "";

  /**
   * we pass the idp config file name throw the idp logout request to be able to get the
   * necessary credentials to decrypt the request, since we don't have any other decrypted data
   *
   * @generated from field: string idp_config_file_name = 2;
   */
  idpConfigFileName = "";

  constructor(data?: PartialMessage<SsoLogoutRequest_SAMLRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SsoLogoutRequest.SAMLRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "idp_config_file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SsoLogoutRequest_SAMLRequest {
    return new SsoLogoutRequest_SAMLRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SsoLogoutRequest_SAMLRequest {
    return new SsoLogoutRequest_SAMLRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SsoLogoutRequest_SAMLRequest {
    return new SsoLogoutRequest_SAMLRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SsoLogoutRequest_SAMLRequest | PlainMessage<SsoLogoutRequest_SAMLRequest> | undefined, b: SsoLogoutRequest_SAMLRequest | PlainMessage<SsoLogoutRequest_SAMLRequest> | undefined): boolean {
    return proto3.util.equals(SsoLogoutRequest_SAMLRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.SsoLogoutResponse
 */
export class SsoLogoutResponse extends Message<SsoLogoutResponse> {
  /**
   * @generated from oneof librarian.proto.SsoLogoutResponse.value
   */
  value: {
    /**
     * @generated from field: librarian.proto.SsoLogoutResponse.SAMLResponse saml_response = 1;
     */
    value: SsoLogoutResponse_SAMLResponse;
    case: "samlResponse";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SsoLogoutResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SsoLogoutResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "saml_response", kind: "message", T: SsoLogoutResponse_SAMLResponse, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SsoLogoutResponse {
    return new SsoLogoutResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SsoLogoutResponse {
    return new SsoLogoutResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SsoLogoutResponse {
    return new SsoLogoutResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SsoLogoutResponse | PlainMessage<SsoLogoutResponse> | undefined, b: SsoLogoutResponse | PlainMessage<SsoLogoutResponse> | undefined): boolean {
    return proto3.util.equals(SsoLogoutResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.SsoLogoutResponse.SAMLResponse
 */
export class SsoLogoutResponse_SAMLResponse extends Message<SsoLogoutResponse_SAMLResponse> {
  /**
   * @generated from field: librarian.proto.KeyValue response_param = 1;
   */
  responseParam?: KeyValue;

  /**
   * @generated from field: string redirection_url = 2;
   */
  redirectionUrl = "";

  constructor(data?: PartialMessage<SsoLogoutResponse_SAMLResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SsoLogoutResponse.SAMLResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "response_param", kind: "message", T: KeyValue },
    { no: 2, name: "redirection_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SsoLogoutResponse_SAMLResponse {
    return new SsoLogoutResponse_SAMLResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SsoLogoutResponse_SAMLResponse {
    return new SsoLogoutResponse_SAMLResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SsoLogoutResponse_SAMLResponse {
    return new SsoLogoutResponse_SAMLResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SsoLogoutResponse_SAMLResponse | PlainMessage<SsoLogoutResponse_SAMLResponse> | undefined, b: SsoLogoutResponse_SAMLResponse | PlainMessage<SsoLogoutResponse_SAMLResponse> | undefined): boolean {
    return proto3.util.equals(SsoLogoutResponse_SAMLResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.SsoProvidersList
 */
export class SsoProvidersList extends Message<SsoProvidersList> {
  /**
   * @generated from field: repeated librarian.proto.SsoProvidersList.SsoProvider values = 1;
   */
  values: SsoProvidersList_SsoProvider[] = [];

  constructor(data?: PartialMessage<SsoProvidersList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SsoProvidersList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "message", T: SsoProvidersList_SsoProvider, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SsoProvidersList {
    return new SsoProvidersList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SsoProvidersList {
    return new SsoProvidersList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SsoProvidersList {
    return new SsoProvidersList().fromJsonString(jsonString, options);
  }

  static equals(a: SsoProvidersList | PlainMessage<SsoProvidersList> | undefined, b: SsoProvidersList | PlainMessage<SsoProvidersList> | undefined): boolean {
    return proto3.util.equals(SsoProvidersList, a, b);
  }
}

/**
 * @generated from message librarian.proto.SsoProvidersList.SsoProvider
 */
export class SsoProvidersList_SsoProvider extends Message<SsoProvidersList_SsoProvider> {
  /**
   * @generated from field: string config_file_name = 1;
   */
  configFileName = "";

  /**
   * @generated from field: string provider_name = 2;
   */
  providerName = "";

  constructor(data?: PartialMessage<SsoProvidersList_SsoProvider>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SsoProvidersList.SsoProvider";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "config_file_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "provider_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SsoProvidersList_SsoProvider {
    return new SsoProvidersList_SsoProvider().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SsoProvidersList_SsoProvider {
    return new SsoProvidersList_SsoProvider().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SsoProvidersList_SsoProvider {
    return new SsoProvidersList_SsoProvider().fromJsonString(jsonString, options);
  }

  static equals(a: SsoProvidersList_SsoProvider | PlainMessage<SsoProvidersList_SsoProvider> | undefined, b: SsoProvidersList_SsoProvider | PlainMessage<SsoProvidersList_SsoProvider> | undefined): boolean {
    return proto3.util.equals(SsoProvidersList_SsoProvider, a, b);
  }
}

/**
 * @generated from message librarian.proto.ImpersonateReq
 */
export class ImpersonateReq extends Message<ImpersonateReq> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: google.protobuf.Int64Value originUserAcountId = 2;
   */
  originUserAcountId?: bigint;

  constructor(data?: PartialMessage<ImpersonateReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ImpersonateReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "originUserAcountId", kind: "message", T: Int64Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImpersonateReq {
    return new ImpersonateReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImpersonateReq {
    return new ImpersonateReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImpersonateReq {
    return new ImpersonateReq().fromJsonString(jsonString, options);
  }

  static equals(a: ImpersonateReq | PlainMessage<ImpersonateReq> | undefined, b: ImpersonateReq | PlainMessage<ImpersonateReq> | undefined): boolean {
    return proto3.util.equals(ImpersonateReq, a, b);
  }
}

