<lefty-card
  [withBorder]="withBorder()"
  [withPadding]="true"
  class="audience-card"
>
  <h4 class="card-heading">{{ card().title }}</h4>
  @if (!isEmptyState()) {
    <div class="chart-container">
      <div class="legends flex">
        @for (d of chartDataSet(); track $index) {
          <div class="body-1 legend">
            <div
              class="dot"
              [style.background-color]="d.color"
            ></div>
            {{ d.name }}
          </div>
        }
      </div>

      <div
        class="bar"
        #bar
      ></div>
      @if (displayChartName()) {
        <div class="body-1 chart-text">{{ chartText }}</div>
      }
    </div>
  } @else {
    <span
      class="empty-state body-1"
      i18n
    >
      No data available
    </span>
  }
</lefty-card>
