import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { NetworkWithNameComponent } from '../../network-with-name/network-with-name.component';
import { NetworkCellRenderer } from './netwok-cell-helpers';

@Component({
  selector: 'network-cell',
  templateUrl: './network-cell.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NetworkWithNameComponent],
})
export class NetworkCellComponent extends NetworkCellRenderer {
  override render(newValue: Network): void {
    this.network = newValue;
  }

  get isTotal(): boolean {
    return this.network === Network.NETWORK_UNKNOWN;
  }
}
