import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Messages } from '@frontend2/core';
import { LeftyLayoutComponent } from '@frontend2/ui';

@Component({
  selector: 'no-access-route',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['no-access.route.component.scss'],
  template: `
    <lefty-layout [leftSectionVisible]="false">
      <div class="top-bar">
        <div class="lefty-layout responsive no-padding-bottom top-bar-content">
          <a
            href="https://lefty.io"
            rel="noopener"
            target="_blank"
            class="lefty-logo"
          >
            <img src="lefty-images/lefty-logo-black.png" />
          </a>
        </div>
      </div>

      <div class="lefty-layout responsive no-padding-bottom">
        <div class="empty-state-container">
          <div class="empty-state al-card with-border">
            <img src="lefty-images/illus-noaccess.png" />
            <h3
              class="title"
              data-test="title"
            >
              {{ Messages.accessDenied }}
            </h3>
            <p
              class="content"
              i18n
              data-test="details"
            >
              You are not allowed to see this casting
            </p>
          </div>
        </div>
      </div>
    </lefty-layout>
  `,
  standalone: true,
  imports: [LeftyLayoutComponent],
})
export class NoAccessRouteComponent {
  constructor(private route: ActivatedRoute) {}

  public readonly Messages: typeof Messages = Messages;
}
