/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LeftyEventsModule } from '@frontend2/ui';
import { NoAccessRouteComponent } from './no-access/no-access.route.component';
import { HitchcockEventsBus } from './shared/events/events';

@NgModule({
  imports: [
    LeftyEventsModule.forRoot(HitchcockEventsBus),
    RouterModule.forRoot([
      {
        path: `casting`,
        loadChildren: () =>
          import('./casting/casting.route.module').then(
            (lib) => lib.CastingRouteModule,
          ),
      },
      { path: 'access-denied', component: NoAccessRouteComponent },
      { path: '**', redirectTo: 'access-denied' },
    ]),
  ],
  exports: [RouterModule],
})
export class AppRouterModule {}
